// import { logger, config, security, globals, utils, remoteDL, localDL } from '@/services';
import config from './config';
import globals from './globals';
import utils from './utils';
import logger from './logger';
import security from './security';
import remoteDL from './remoteDatalayer';
import localDL from './localDatalayer';
import { shallowRef, computed, watch } from 'vue';
//import $ from '../assets/scripts/jquery-3.6.0.min';
import moment from '../assets/scripts/moment.min';
import bus from '@/main';

const $ = window.$;
const dataLayer = window.dataLayer;
var TScMessenger = window.TScMessenger;
var OPERATION = config.OPERATION;
//constants
var
    operationTypeGet = 'GET',
    operationTypePost = 'POST',
    // operationTypeDelete = 'DELETE',
    dataTypeJson = 'json';
    // dataTypePdf = 'pdf';

//variables
var
    // userNameProvided,
    // passwordProvided,
    securityTokenProvided,
    cultureCodeProvided;




var STORE = localDL.STORE;
var PROPERTY = localDL.PROPERTY;
var isOnlineLastCheckTimeStamp = 0;
var onlineCheckDfd;
var onlineCheckUnderway = false;
var immediateDebugLogPostRequired = false;
var lastDebugLogUploadTimeStamp = 0;
var lastUsageLogUploadTimeStamp = 0;

var recentlyCached = shallowRef({Type: '', Id: 0, Value: 0});

//var tempCounter = 0;

var PRIORITY = {
    LOW: (config.isOnlineLowPriorityValidityPeriod*1000),
    MEDIUM: (config.isOnlineMediumPriorityValidityPeriod*1000),
    HIGH: (config.isOnlineHighPriorityValidityPeriod*1000)
};

function updatePriorityFromConfigValues() {
    PRIORITY = {
        LOW: (config.isOnlineLowPriorityValidityPeriod * 1000),
        MEDIUM: (config.isOnlineMediumPriorityValidityPeriod * 1000),
        HIGH: (config.isOnlineHighPriorityValidityPeriod * 1000)
    };
    dataManager.PRIORITY = PRIORITY;
}

function getOnlineStatePriority() {
    return globals.isOnline.value ? PRIORITY.HIGH : PRIORITY.LOW;
}

var onlineStatePriorityComputed = computed(function () {
    return globals.isOnline.value ? PRIORITY.HIGH : PRIORITY.LOW;
});

var doingBackgroundOfferRetrieval = shallowRef(false);
var doingBackgroundOverviewRetrieval = shallowRef(false);
var doingBackgroundSelectionRetrieval = shallowRef(false);
var doingBackgroundSharedOfferStubsRetrieval = shallowRef(false);
var doingBackgroundBodiesRetrieval = shallowRef(false);
var doingBackgroundAccessoriesRetrieval = shallowRef(false);
var doingBackgroundTrailersRetrieval = shallowRef(false);
var doingBackgroundPayloadsRetrieval = shallowRef(false);

var backgroundOfferRetrievalFailed = shallowRef(false);
var standardHandleVersionsTasksComplete = shallowRef(false);
var slowHandleVersionsTasksComplete = shallowRef(false);

var preventCaching = false;

var backgroudOfferRetrievalRetryComputedSubscriptionRef = null;
var backgroudOfferRetrievalRetryComputed = computed(function () {
    return backgroundOfferRetrievalFailed.value === true && standardHandleVersionsTasksComplete.value === true && slowHandleVersionsTasksComplete.value === true;
});

var preloadOffersRetryCount = 0;
function setPreloadOffersRetryCount(newValue) {
    preloadOffersRetryCount = newValue;
}
var doingBackgroundOfferCaching = shallowRef(false);
watch(doingBackgroundOfferCaching, function (newValue) {
    if (newValue === false) {
        customersResult = null;
    }
});
var doingBackgroundSelectionCaching = shallowRef(false);
watch(doingBackgroundSelectionCaching, function (newValue) {
    if (newValue === false) {
        selectionListResult = null;
    }
});

var doingBackgroundSharedOfferStubsCaching = shallowRef(false);
watch(doingBackgroundSharedOfferStubsCaching, function (newValue) {
    if (newValue === false) {
        sharedOfferStubsResult = null;
    }
});

var doingBackgroundBodyStubsCaching = shallowRef(false);
watch(doingBackgroundBodyStubsCaching, function (newValue) {
    if (newValue === false) {
        bodiesResult = null;
    }
});
var doingBackgroundAccessoriesStubsCaching = shallowRef(false);
watch(doingBackgroundAccessoriesStubsCaching, function (newValue) {
    if (newValue === false) {
        accessoriesResult = null;
    }
});

var doingBackgroundTrailerStubsCaching = shallowRef(false);
watch(doingBackgroundTrailerStubsCaching, function (newValue) {
    if (newValue === false) {
        trailersResult = null;
    }
});

var doingBackgroundPayloadStubsCaching = shallowRef(false);
watch(doingBackgroundPayloadStubsCaching, function (newValue) {
    if (newValue === false) {
        payloadsResult = null;
    }
});

var allOriginalAccessoriesRetrievedObv = shallowRef(false);
//var allOriginalEquipmentCached = ko.computed(function () {
//    var numNotCached = 0;
//    ko.utils.arrayForEach(originalEquipmentRetrievedObvArr(), function (originalEquipmentObv) {
//        if (originalEquipmentObv() === false) {
//            numNotCached++;
//        }
//    });
//    if (numNotCached === 0) {
//        return true;
//    } else {
//        return false;
//    }
//});
//var allOriginalEquipmentCachedSubscriptionRef;

var allNewOfferImagesRetrieved = shallowRef(false);
var allNewSpecImagesRetrieved = shallowRef(false);
var newOfferDataCached = shallowRef(false);
var newSpecDataCached = shallowRef(false);
var allNewOfferAndSpecImagesAndDataCached = computed(function () {
    TScMessenger.writeDebugMessage('In allBewOfferAndSpecImagesAndDataCached: allNewOfferImagesRetrieved.value=' + allNewOfferImagesRetrieved.value + ', allNewSpecImagesRetrieved.value=' + allNewSpecImagesRetrieved.value + ', newOfferDataCached.value=' + newOfferDataCached.value + ', newSpecDataCached.value=' + newSpecDataCached.value);
    return (allNewOfferImagesRetrieved.value === true && allNewSpecImagesRetrieved.value === true && newOfferDataCached.value === true && newSpecDataCached.value === true);
});
var newOfferDataAndImagesCachedSubscriptionRef;
var selectedVehicleId = 0;

var allExistingOfferImagesRetrieved = shallowRef(false);
var allExistingSpecImagesRetrieved = shallowRef(false);
var existingOfferDataCached = shallowRef(false);
var existingSpecDataCached = shallowRef(false);
var allExistingOfferAndSpecImagesAndDataCached = computed(function () {
    return (allExistingOfferImagesRetrieved.value === true && allExistingSpecImagesRetrieved.value === true && existingOfferDataCached.value === true && existingSpecDataCached.value === true && allOriginalAccessoriesRetrievedObv.value === true);
});
var existingOfferDataAndImagesCachedSubscriptionRef;
var selectedOfferId = 0;

// var curExistingOfferIdKey;
var alreadyLoadedImages = [];
var requestedImages = [];
var latestReportGraphicsFromLogin;
var selectionListResult;
var sharedOfferStubsResult;
var bodiesResult;
var accessoriesResult;
var trailersResult;
var payloadsResult;
var customersResult;
var dataOpListenerCallback;
// var curOpTracker;
//var numRecordsToCache = 0;
// var numRecordsCached = 0;
var tempLoginDataRef = null;

var networkRequestsUnderway = 0;

//usage 1 use logging control booleans
var performanceUseLogged = false,
    costingUseLogged = false,
    specificationUseLogged = false,
    summaryUseLogged = false,
    companyOverviewUseLogged = false,
    offersUseLogged = false,
    brochureUseLogged = false,
    trainingUseLogged = false,
    configurationUseLogged = false;

var syncOpSuccessResponseHandlerCallback = null, syncOpFailureResponseHandlerCallback = null, sharedOfferSyncHandlerCallback = null;

var teamSyncHandlerCallback = null;

// Setting up callback on globals for connectivity update for Intercom
globals.setSendEventInfoToIntercomCallback(sendInfoToIntercom);
security.registerDataManagerLogCallback(log);

var dataManager = {
    getSelectionList: getSelectionList,
    getNewOfferDetails: getNewOfferDetails,
    getOfferSpecificationDetails: getOfferSpecificationDetails,
    getVehicleSpecificationAdvantages: getVehicleSpecificationAdvantages,
    getLoginPageInformation: getLoginPageInformation,
    postSimulationResults: postSimulationResults,
    //getCustomers: getCustomers,
    saveCustomerDetailsOnOffers: saveCustomerDetailsOnOffers,
    saveCustomerNote: saveCustomerNote,
    saveCustomerAction: saveCustomerAction,
    deleteCustomer: deleteCustomer,
    deleteCustomerNote: deleteCustomerNote,
    deleteCustomerAction: deleteCustomerAction,
    deleteOffer: deleteOffer,
    updateOfferStatus: updateOfferStatus,
    getSalesPeople: getSalesPeople,
    saveOffer: saveOffer,
    getExistingOfferDetails: getExistingOfferDetails,
    // emailOffer: emailOffer,
    loadLocallyOrRetrieveAndStoreAppLogos: loadLocallyOrRetrieveAndStoreAppLogos,
    persist: persist,
    retrieve: retrieve,
    remove: remove,
    replaceReportImageUrls: replaceReportImageUrls,
    checkLicenceDays: checkLicenceDays,
    handleVersions: handleVersions,
    STORE: STORE,
    PROPERTY: PROPERTY,
    monitorOnlineState: monitorOnlineState,
    PRIORITY: PRIORITY,
    checkOnlineState: checkOnlineState,
    onlineStatePriorityComputed: onlineStatePriorityComputed,
    getOnlineStatePriority: getOnlineStatePriority,
    preloadOffers: preloadOffers,
    setPreloadOffersRetryCount: setPreloadOffersRetryCount,
    // getOnRoadCosts: getOnRoadCosts,
    getTranslations: getTranslations,
    getCommonPackDetails: getCommonPackDetails,
    //getLegislationList: getLegislationList,
    getLegislationDetails: getLegislationDetails,
    getUserSettings: getUserSettings,
    saveUserSettings: saveUserSettings,
    login: login,
    logout: logout,
    clearCachedData: clearCachedData,
    securityTokenIsValid: securityTokenIsValid,
    securityTokenIsValidAsTextFlag: securityTokenIsValidAsTextFlag,
    doSyncIfNeeded: doSyncIfNeeded,
    setupIndexedDB: setupIndexedDB,
    setOfflineValidUntilIfNeeded: setOfflineValidUntilIfNeeded,
    loadProperties: loadProperties,
    log: log,
    doDebugLogMaintenanceIfRequired: doDebugLogMaintenanceIfRequired,
    recentlyCached: recentlyCached,
    logAppUsage: logAppUsage,
    replaceImageUrl: replaceImageUrl,
    registerDataOperationListener: registerDataOperationListener,
    getBodyStubs: getBodyStubs,
    getBody: getBody,
    postDataRequest: postDataRequest,
    getAccessoryStubs: getAccessoryStubs,
    saveCustomAccessory: saveCustomAccessory,
    deleteCustomAccessory: deleteCustomAccessory,
    getAccessory: getAccessory,
    getNumVehicleOpensPropertyVal: getNumVehicleOpensPropertyVal,
    incrementNumVehicleOpensProperty: incrementNumVehicleOpensProperty,
    checkEmailVerificationStatusOnline: checkEmailVerificationStatusOnline,
    postVerificationCode: postVerificationCode,
    getCountries: getCountries,
    signUp: signUp,
    resendVerificationEmail: resendVerificationEmail,
    sendFeedback: sendFeedback,
    requestTrialExtension: requestTrialExtension,
    requestSalesTool: requestSalesTool,
    requestUpgrade: requestUpgrade,
    registerBuyNowClick: registerBuyNowClick,
    buyNow: buyNow,
    getPortalURL: getPortalURL,
    invalidateSecurityToken: invalidateSecurityToken,
    adjustLoggedInTrialUserAfterSuccessfulSubscribe: adjustLoggedInTrialUserAfterSuccessfulSubscribe,
    getUpdatePaymentMethodURL: getUpdatePaymentMethodURL,
    postEmailOffer: postEmailOffer,
    sendLoginDebugInfo: sendLoginDebugInfo,
    sendScreenSizeInfo: sendScreenSizeInfo,
    dismissAppOptionItem: dismissAppOptionItem,
    checkIsOnline: checkIsOnline,
    // sendDataToGoogleAnalytics: sendDataToGoogleAnalytics,
    //sendUserInfoToIntercom: sendUserInfoToIntercom,
    //sendEventInfoToIntercom: sendEventInfoToIntercom,
    sendInfoToIntercom: sendInfoToIntercom,
    triggerEventInGoogleAnalytics: triggerEventInGoogleAnalytics,
    getOriginalsForItemsAddedToRig: getOriginalsForItemsAddedToRig,
    runDataIntegrityTests: runDataIntegrityTests,
    registerSyncOpSuccessResponseHandler: registerSyncOpSuccessResponseHandler,
    registerSyncOpFailureResponseHandler: registerSyncOpFailureResponseHandler,
    changePassword: changePassword,
    getTrailer: getTrailer,
    getTrailerStubs: getTrailerStubs,
    postDebugLogs: postDebugLogs,
    postUsage: postUsage,
    getPayloadStubs: getPayloadStubs,
    getPayload: getPayload,
    sendReferral: sendReferral,
    sendReferralPromptNotification: sendReferralPromptNotification,
    postCSVFileData: postCSVFileData,
    postDXFFileData: postDXFFileData,
    postTransSolveFileData: postTransSolveFileData,
    updatePendingVehicle: updatePendingVehicle,
    shareOffer: shareOffer,
    getUserAssociations: getUserAssociations,
    getShareeUserOfferDetails: getShareeUserOfferDetails,
    deleteUserOfferShareObject: deleteUserOfferShareObject,
    getSharedOfferStubs: getSharedOfferStubs,
    getOfferShareStatus: getOfferShareStatus,
    getSharedOfferStub: getSharedOfferStub,
    downloadVehicleCsv: downloadVehicleCsv,
    updateShareeUserDetails: updateShareeUserDetails,
    doDataActionsAfterLoginOrReAuth: doDataActionsAfterLoginOrReAuth,
    updateOfferShareStatus: updateOfferShareStatus,
    registerSharedOfferSyncCallback: registerSharedOfferSyncCallback,
    validateNTEAUser: validateNTEAUser,
    doSync: doSync,
    sendToRouteRequestBingMapsRestApi: sendToRouteRequestBingMapsRestApi,
    registerTeamSyncHandlerCallback: registerTeamSyncHandlerCallback,
    getSignalRConnectionInfo: getSignalRConnectionInfo,
    syncSharedOffers: syncSharedOffers,
    syncTeamItems: syncTeamItems,
    getBodyMasses: getBodyMasses,
    getLicenceCategories: getLicenceCategories,
    checkIsOnlineHeadVersion: checkIsOnlineHeadVersion,
    getFolderContents: getFolderContents,
    getSearchResults: getSearchResults,
    getFolderTreeStructure: getFolderTreeStructure,
    createFolder: createFolder,
    moveTo: moveTo,
    getSharers: getSharers,
    rename: rename,
    removeItems: removeItems,
    getSavedOfferStubs: getSavedOfferStubs,
    getResources: getResources,
    postTransferLicenceVerificationCode: postTransferLicenceVerificationCode,
    resendTransferLicenceVerificationEmail: resendTransferLicenceVerificationEmail,
    updateUserAfterCheckout: updateUserAfterCheckout,
    validateAccessToken: validateAccessToken
};

export default dataManager;

/**
 * Checks if there are any sync items stored in indexed db and if so calls doSync or else immediately calls back to caller.
 * @method doSyncIfNeeded
 * @param {Function} callbackSpinnerOn A callback to shell to turn spinner on if syncitems found.
 * @param {Function} callback A callback to turn off spinner for when doSync has finished syncing all item or if a problem is encountered.
 * @return {Boolean} Returns true or false to indicate if a screen refresh is required depending on whether or not any sync took place.
 */
function doSyncIfNeeded(callbackSpinnerOn, callback) {
    
    if (globals.isSyncing.value === true) {
        return;
    }
    localDL.checkIfSyncItems()
        .then(function (syncItems) {
            if (syncItems.length > 0) {
                if (callbackSpinnerOn !== undefined) {
                    callbackSpinnerOn();
                }
                
                globals.isSyncing.value = true;
                //globals.displayOnlineMessage(globals.user);
                log("Performing sync of offline activity", null, 'dataManager', config.LOG_MESSAGE_TYPE.INFO);
                
                doSync(syncItems, callback);
            } else {
                if (callback !== undefined) {
                    callback(false);
                }
                
            }
        });
    
    
    
    
    

    
}

function handleCustomerIdChange(curSyncItem, data, keyToUse, custData, syncItemsArr) {
    var custIdDfd = $.Deferred();

    for (var i = 0; i < syncItemsArr.length; i++) {
        if (syncItemsArr[i].ParentId === keyToUse) {
            syncItemsArr[i].ParentId = data.Customer.Id;
            syncItemsArr[i].Params.customerId = data.Customer.Id;
            if (syncItemsArr[i].Operation.Code === OPERATION.SaveOffer.Code) {
                syncItemsArr[i].Details.Customer.Id = data.Customer.Id;
            } else if (syncItemsArr[i].Operation.Code === OPERATION.SaveCustomerNote.Code || syncItemsArr[i].Operation.Code === OPERATION.SaveCustomerAction.Code) {
                syncItemsArr[i].Details.CustomerId = data.Customer.Id;
            }
        }
    }
    // for each offer relating to the customer update the customer id and update counter
    localDL.getAllCustomersOffers(keyToUse)
        .then(function (custsOffers) {
            var offerSavePromises = [];
            delete custData.Offers;
            delete custData.Notes;
            delete custData.Actions;
            for (var i = 0; i < custsOffers.length; i++) {
                custsOffers[i].Customer = custData;
                custsOffers[i].CustomerId = custData.Id;
                offerSavePromises.push(localDL.addToCache(OPERATION.SaveOffer.Code, custsOffers[i].OfferId, custsOffers[i]));
            }
            if (offerSavePromises.length > 0) {
                $.when.apply($, offerSavePromises)
                    .done(function () {
                        doCustDelete();
                    });
            } else {
                doCustDelete();
            }
            function doCustDelete() {
                localDL.deleteRecord(STORE.Customers, keyToUse)
                    .then(function () {
                        custIdDfd.resolve();
                    })
                    .fail(function () {
                        custIdDfd.resolve();
                    });
            }
        });
    return custIdDfd.promise();
}

function doSync(syncItemsArr, callback) {
    var curSyncItem;
    if (syncItemsArr.length === 0) {
        log("Finished sync of offline activity", null, 'dataManager', config.LOG_MESSAGE_TYPE.INFO);

        globals.isSyncing.value = false;
        if (callback !== undefined) {
            sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.CHANGED_CONNECTIVITY, globals.getIntercomMetadataForIsOnline(globals.isOnline.value)));
            callback(true);
        }
    } else {
        if (security.securityTokenIsValid().IsValid === true) {
            curSyncItem = syncItemsArr.shift();
            if (curSyncItem !== undefined && curSyncItem !== null) {
                remoteDL.handleSync(security, curSyncItem)
                    .then(handleRemoteSyncResponse)
                    .fail(handleRemoteSyncFailure);
            }
            
        } else {
            globals.isSyncing.value = false;
            if (callback !== undefined) {
                callback(true);
            }
        }
    }
    function deleteSyncItemIfNotAllowedOffline() {
        if (curSyncItem.Operation.AllowOffline !== undefined && curSyncItem.Operation.AllowOffline === false) {
            localDL.deleteRecord(STORE.SyncQueue, curSyncItem.TimeStamp);
        }
    }
    function moveNext() {
        localDL.deleteRecord(STORE.SyncQueue, curSyncItem.TimeStamp);
        doSync(syncItemsArr, callback);
    }

    function updateTrailerSourceDatabaseIdIfNecessary(savedOfferSyncItem, keyToUse, data) {
        savedOfferSyncItem.Details.Offer.Trailers.forEach(function (trailer) {
            if (trailer.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
                if (trailer.SourceDatabaseId === keyToUse) {
                    trailer.SourceDatabaseId = data.AccessoryId;
                }
            }
        });
    }

    function handleRemoteSyncResponse(data) {

        var keyToUse;
        var deleteTempCustRecRequired = false;
        var deleteOfferRecRequired = false;
        var idOfOfferToDelete = '';
        var itemDescriptionText;
    
        switch (curSyncItem.Operation.Code) {
            case OPERATION.SaveOffer.Code:
                if (curSyncItem.ExistingId === 0) {
                    keyToUse = "offerId_" + curSyncItem.TimeStamp;
                } else {
                    keyToUse = curSyncItem.ExistingId;
                }
                // var offersCustId = curSyncItem.ParentId;
                itemDescriptionText = curSyncItem.Details.Offer.Description;
                if (curSyncItem.Details.Offer.AdditionalDescription && curSyncItem.Details.Offer.AdditionalDescription !== '') {
                    itemDescriptionText = itemDescriptionText + ' ' + curSyncItem.Details.Offer.AdditionalDescription;
                }
                if (data.Result.ReturnCode === 1 || data.Result.ReturnCode === 2) {
                    //itemDescriptionText = data.Offer.Description;
                    var updatedOffer = null;
    
                    for (var i = 0; i < syncItemsArr.length; i++) {
                        if (syncItemsArr[i].ParentId === curSyncItem.ParentId || syncItemsArr[i].ExistingId === curSyncItem.ParentId) {
                            if (syncItemsArr[i].Operation.Code === config.OPERATION.UpdateOfferStatus.Code) {
                                syncItemsArr[i].Details.UpdateCounter = data.Offer.UpdateCounter;
                            } else if (syncItemsArr[i].Operation.Code === config.OPERATION.SaveCustomer.Code) {
                                syncItemsArr[i].Details.UpdateCounter = data.Customer.UpdateCounter;
                                syncItemsArr[i].Details.OverallUpdateCounter = data.Customer.OverallUpdateCounter;
                            }
                            //else {
                            //   
                            //    syncItemsArr[i].Details.Customer.UpdateCounter = data.Customer.UpdateCounter;
                            //    syncItemsArr[i].Details.Customer.OverallUpdateCounter = data.Customer.OverallUpdateCounter;
                            //    syncItemsArr[i].Details.Offer.UpdateCounter = data.Offer.UpdateCounter;
                            //}
    
                        }
                    }
                    //syncOpSuccessResponseHandlerCallback(curSyncItem.Operation.Code, itemDescriptionText, { savedOffer: data }, keyToUse);
                    //moveNext();
                    updateOffer(keyToUse)
                        .then(function () {
                            //updateCustomer(offersCustId)
                            //    .then(function (custData) {
                                    if (deleteOfferRecRequired === true) {
    
    
                                        localDL.deleteRecord(STORE.SavedOffers, idOfOfferToDelete)
                                            .then(function () {
                                                doRestOfCode();
                                            });
                                    } else {
                                        doRestOfCode();
                                    }
                                    function doRestOfCode() {
                                        if (deleteTempCustRecRequired === true) {
    
                                            handleCustomerIdChange(curSyncItem, data, curSyncItem.ParentId, /*custData*/undefined, syncItemsArr)
                                                .then(function () {
                                                    if (updatedOffer !== null) {
                                                        localDL.addToCache(OPERATION.SaveOffer.Code, updatedOffer.OfferId, updatedOffer);
                                                    }
                                                    syncOpSuccessResponseHandlerCallback(curSyncItem.Operation.Code, itemDescriptionText, { savedOffer: data }, keyToUse);
                                                    moveNext();
                                                });
    
                                        } else {
                                            if (updatedOffer !== null) {
                                                localDL.addToCache(OPERATION.SaveOffer.Code, updatedOffer.OfferId, updatedOffer);
                                            }
                                            syncOpSuccessResponseHandlerCallback(curSyncItem.Operation.Code, itemDescriptionText, { savedOffer: data }, keyToUse);
                                            moveNext();
                                        }
                                    }
                                //});
                        })
    
                    
                } else if (data.Result.ReturnCode === -3 || data.Result.ReturnCode === -4) {
                    handleSyncItemFailureAdvanced(curSyncItem.Operation.Code, itemDescriptionText, data, data.Result.ReturnCode, data.Result.Message)
                } else {
                    handleSyncItemFailure(curSyncItem.Operation.Code, itemDescriptionText, data.Result.ReturnCode, data.Result.Message);
                }
    
                break;
            case OPERATION.UpdateOfferStatus.Code:
                if (curSyncItem.ExistingId === 0) {
                    keyToUse = "offerId_" + curSyncItem.TimeStamp;
                } else {
                    keyToUse = curSyncItem.ExistingId;
                }
                itemDescriptionText = curSyncItem.Details.Description;
                if (data.Result.ReturnCode === 1) {
                    updateSyncQueueOffersAndCustomersIfNecessary();
                    localDL.getFromCache(OPERATION.GetCustomer, curSyncItem.ParentId)
                            .then(function (custData) {
                                for (var i = 0; custData.Offers.length; i++) {
                                    if (custData.Offers[i].Id === keyToUse) {
                                        custData.Offers[i].UpdateCounter += 1;
                                        break;
                                    }
                                }
                                custData.OverallUpdateCounter = data.OverallCustomerUpdateCounter;
                                data.CustomerId = curSyncItem.ParentId;
                                localDL.getFromCache(OPERATION.GetExistingOfferDetails, keyToUse)
                                    .then(function (offerData) {
                                        localDL.addToCache(OPERATION.SaveCustomer.Code, curSyncItem.ParentId, custData)
                                            .then(function () {
                                                if (offerData !== null) {
                                                    offerData.UpdateCounter = data.UpdateCounter;
                                                    localDL.addToCache(OPERATION.SaveOffer.Code, keyToUse, offerData).then(function () {
                                                        syncOpSuccessResponseHandlerCallback(curSyncItem.Operation.Code, itemDescriptionText, { offerStatusResponse: data }, keyToUse);
                                                        moveNext();
                                                    });
                                                } else {
                                                    syncOpSuccessResponseHandlerCallback(curSyncItem.Operation.Code, itemDescriptionText, { offerStatusResponse: data }, keyToUse);
                                                    moveNext();
                                                }
                                            });
                                    });
                            });
                } else {
                    handleSyncItemFailure(curSyncItem.Operation.Code, itemDescriptionText, data.Result.ReturnCode, data.Result.Message);
                }
                
                break;
    
            case OPERATION.DeleteCustomerAction.Code:
            case OPERATION.DeleteCustomerNote.Code:
                itemDescriptionText = curSyncItem.Operation.Code === config.OPERATION.DeleteCustomerNote.Code ? config.getTranslationText('645') : config.getTranslationText('646');
                if (data.ReturnCode === 1) {
                    syncOpSuccessResponseHandlerCallback(curSyncItem.Operation.Code, itemDescriptionText);
                    moveNext();
                } else {
                    handleSyncItemFailure(curSyncItem.Operation.Code, itemDescriptionText, data.ReturnCode, data.Message);
                }
                break;
            case OPERATION.DeleteOffer.Code:
            case OPERATION.DeleteCustomer.Code:
                itemDescriptionText = curSyncItem.Details.Description;
                if (data.ReturnCode === 1) {
                    syncOpSuccessResponseHandlerCallback(curSyncItem.Operation.Code, itemDescriptionText);
                    moveNext();
                } else {
                    handleSyncItemFailure(curSyncItem.Operation.Code, itemDescriptionText, data.ReturnCode, data.Message);
                }
                break;
            case OPERATION.DeleteCustomAccessory.Code:
                if (curSyncItem.ExistingId === 0) {
                    keyToUse = "accessoryId_" + curSyncItem.TimeStamp;
                } else {
                    keyToUse = curSyncItem.ExistingId;
                }
                itemDescriptionText = curSyncItem.Details.Description;
                if (data.ReturnCode === 1) {
                    syncOpSuccessResponseHandlerCallback(curSyncItem.Operation.Code, itemDescriptionText);
                    moveNext();
                } else if (data.ReturnCode === -2 || data.ReturnCode === -3) {
                    data.stubId = keyToUse;
                    handleSyncItemFailureAdvanced(curSyncItem.Operation.Code, itemDescriptionText, data, data.ReturnCode, data.Message);
                } else {
                    handleSyncItemFailure(curSyncItem.Operation.Code, itemDescriptionText, data.ReturnCode, data.Message);
                }
                break;
            case OPERATION.SaveCustomer.Code:
                if (curSyncItem.ExistingId === 0) {
                    keyToUse = "custId_" + curSyncItem.TimeStamp;
                } else {
                    keyToUse = curSyncItem.ExistingId;
                }
                itemDescriptionText = curSyncItem.Details.ContactName;
                if (data.Result.ReturnCode === 1) {
                    updateSyncQueueItemsAfterSaveCustomerIfNecessary();
                    localDL.getFromCache(OPERATION.GetCustomer, keyToUse)
                        .then(function (custData) {
                            custData.UpdateCounter = data.Customer.UpdateCounter;
                            custData.OverallUpdateCounter = data.Customer.OverallUpdateCounter;
                            if (custData.Id !== data.Customer.Id) {
    
                                deleteTempCustRecRequired = true;
                                custData.Id = data.Customer.Id;
    
                            }
                            localDL.addToCache(OPERATION.SaveCustomer.Code, custData.Id, custData)
                                .then(function () {
                                    if (deleteTempCustRecRequired === true) {
                                        handleCustomerIdChange(curSyncItem, data, keyToUse, custData, syncItemsArr)
                                            .then(function () {
                                                syncOpSuccessResponseHandlerCallback(curSyncItem.Operation.Code, itemDescriptionText, { savedCustomer: data }, keyToUse);
                                                moveNext();
                                                //localDL.deleteRecord(STORE.SyncQueue, curSyncItem.TimeStamp);
                                                //doSync(syncItemsArr);
                                            });
                                    } else {
                                        syncOpSuccessResponseHandlerCallback(curSyncItem.Operation.Code, itemDescriptionText, { savedCustomer: data }, keyToUse);
                                        moveNext();
                                        //localDL.deleteRecord(STORE.SyncQueue, curSyncItem.TimeStamp);
                                        //doSync(syncItemsArr);
                                    }
    
                                });
                        });
                } else {
                    handleSyncItemFailure(curSyncItem.Operation.Code, itemDescriptionText, data.Result.ReturnCode, data.Result.Message);
                }
                
                break;
            case OPERATION.SaveCustomerNote.Code:
                if (curSyncItem.ExistingId === 0) {
                    keyToUse = "noteId_" + curSyncItem.TimeStamp;
                } else {
                    keyToUse = curSyncItem.ExistingId;
                }
                itemDescriptionText = curSyncItem.Details.Description;
                if (data.Result.ReturnCode === 1) {
                    updateSyncQueueItemsAfterSaveCustomerNoteIfNecessary();
                    localDL.getFromCache(OPERATION.GetCustomer, curSyncItem.ParentId)
                        .then(function (custData) {
                            for (var i = 0; custData.Notes.length; i++) {
                                if (custData.Notes[i].Id === keyToUse) {
                                    custData.Notes[i].UpdateCounter = data.UpdateCounter;
                                    custData.Notes[i].CreationDate = moment(data.CreationDate, "M/D/YYYY").format("DD/MM/YYYY");
                                    if (custData.Notes[i].Id !== data.NoteId) {
                                        custData.Notes[i].Id = data.NoteId;
                                    }
                                }
                                break;
                            }
                            custData.OverallUpdateCounter = data.OverallCustomerUpdateCounter;
                            localDL.addToCache(OPERATION.SaveCustomer.Code, custData.Id, custData)
                                .then(function () {
                                    syncOpSuccessResponseHandlerCallback(curSyncItem.Operation.Code, itemDescriptionText, { savedNote: data }, keyToUse);
                                    moveNext();
                                });
                        });
                } else {
                    handleSyncItemFailure(curSyncItem.Operation.Code, itemDescriptionText, data.Result.ReturnCode, data.Result.Message);
                }
                
                break;
            case OPERATION.SaveCustomerAction.Code:
                if (curSyncItem.ExistingId === 0) {
                    keyToUse = "actionId_" + curSyncItem.TimeStamp;
                } else {
                    keyToUse = curSyncItem.ExistingId;
                }
                itemDescriptionText = curSyncItem.Details.Description;
                if (data.Result.ReturnCode === 1) {
                    updateSyncQueueItemsAfterSaveCustomerActionIfNecessary();
                    localDL.getFromCache(OPERATION.GetCustomer, curSyncItem.ParentId)
                        .then(function (custData) {
    
                            for (var i = 0; custData.Actions.length; i++) {
                                if (custData.Actions[i].Id === keyToUse) {
                                    custData.Actions[i].UpdateCounter = data.UpdateCounter;
                                    custData.Actions[i].CompletionDate = moment(data.CompletionDate, "M/D/YYYY").format("DD/MM/YYYY");
                                    if (custData.Actions[i].Id !== data.ActionId) {
                                        custData.Actions[i].Id = data.ActionId;
                                    }
                                }
                                break;
                            }
                            custData.OverallUpdateCounter = data.OverallCustomerUpdateCounter;
                            localDL.addToCache(OPERATION.SaveCustomer.Code, custData.Id, custData)
                                .then(function () {
                                    syncOpSuccessResponseHandlerCallback(curSyncItem.Operation.Code, itemDescriptionText, { savedAction: data }, keyToUse);
                                    moveNext();
                                });
                        });
                } else {
                    handleSyncItemFailure(curSyncItem.Operation.Code, itemDescriptionText, data.Result.ReturnCode, data.Result.Message);
                }
                
                break;
            case OPERATION.SaveCustomAccessory.Code:
                var cleanUpTempIds = false;
                if (curSyncItem.ExistingId === 0) {
                    keyToUse = "accessoryId_" + curSyncItem.TimeStamp;
                    cleanUpTempIds = true;
                } else {
                    keyToUse = curSyncItem.ExistingId;
                }
                itemDescriptionText = JSON.parse(curSyncItem.Details.AccessoryDetail).Description;
                if (data.Result.ReturnCode === 1 || data.Result.ReturnCode === 2 || data.Result.ReturnCode === 3) {
                    if (cleanUpTempIds === true) {
                        // need to update the sourceDatabaseId field on any offers that might be in the sync queue with the just synced piece of custom equipment added
                        for (i = 0; i < syncItemsArr.length; i++) {
                            if (syncItemsArr[i].Operation.Code === config.OPERATION.SaveOffer.Code) {
                                if (curSyncItem.Details.AccessoryType === config.ACCESSORY_TYPES.TRAILER) {
                                    updateTrailerSourceDatabaseIdIfNecessary(syncItemsArr[i], keyToUse, data);
                                } else if (curSyncItem.Details.AccessoryType === config.ACCESSORY_TYPES.PAYLOAD) {
                                    updatePayloadSourceDatabaseIdIfNecessary(syncItemsArr[i]);
                                } else {
                                    updateAccessorySourceDatabaseIdIfNecessary(syncItemsArr[i]);
                                }
    
                            }
                        }
                        //if (curSyncItem.Details.AccessoryType === config.ACCESSORY_TYPES.TRAILER) {
                        //    updateSavedOffersWithThisCustomTrailerSourceDatabaseIdIfNecessary();
                        //} else if (curSyncItem.Details.AccessoryType === config.ACCESSORY_TYPES.PAYLOAD) {
                        //    updateSavedOffersWithThisCustomPayloadSourceDatabaseIdIfNecessary();
                        //} else {
                        //    updateSavedOffersWithThisCustomAccessorySourceDatabaseIdIfNecessary();
                        //}
    
                    }
                    var idToUse;
                    if (curSyncItem.Details.AccessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
                        idToUse = data.AccessoryId;
                    } else {
                        idToUse = keyToUse;
                    }
                    if (curSyncItem.Details.AccessoryType === config.ACCESSORY_TYPES.BODY) {
                        getBody(idToUse, config.VEHICLE_SOURCE_TYPES.CUSTOM, curSyncItem.Details.AccessLevel)
                            .then(function (theBody) {
                                updateLocalObjectProperties(config.OPERATION.SaveBody, theBody);
                            });
                    } else if (curSyncItem.Details.AccessoryType === config.ACCESSORY_TYPES.TRAILER) {
                        
                        getTrailer(idToUse, config.VEHICLE_SOURCE_TYPES.CUSTOM, curSyncItem.Details.AccessLevel)
                            .then(function (theTrailer) {
                                updateLocalObjectProperties(config.OPERATION.SaveTrailer, theTrailer);
                            });
                    } else if (curSyncItem.Details.AccessoryType === config.ACCESSORY_TYPES.PAYLOAD) {
                        getPayload(idToUse, config.VEHICLE_SOURCE_TYPES.CUSTOM, curSyncItem.Details.AccessLevel)
                            .then(function (thePayload) {
                                updateLocalObjectProperties(config.OPERATION.SavePayload, thePayload);
                            });
                    } else {
                        getAccessory(idToUse, curSyncItem.Details.AccessoryType, config.VEHICLE_SOURCE_TYPES.CUSTOM, curSyncItem.Details.AccessLevel)
                            .then(function (theAccessory) {
                                updateLocalObjectProperties(config.OPERATION.SaveAccessory, theAccessory);
                            });
                    }
                    
                    //localDL.deleteRecord(STORE.SyncQueue, curSyncItem.TimeStamp);
    
                    //doSync(syncItemsArr);
    
    
                } else if (data.Result.ReturnCode === -3 || data.Result.ReturnCode === -4 || data.Result.ReturnCode === -5 || data.Result.ReturnCode === -6) {
                    handleSyncItemFailureAdvanced(curSyncItem.Operation.Code, itemDescriptionText, data, data.Result.ReturnCode, data.Result.Message);
                } else {
                    handleSyncItemFailure(curSyncItem.Operation.Code, itemDescriptionText, data.Result.ReturnCode, data.Result.Message);
                }
                
                //function updateSavedOffersWithThisCustomAccessorySourceDatabaseIdIfNecessary() {
                //    getCustomers()
                //        .then(function (success) {
                //            var savedOffers = [];
                //            success.data.Customers.forEach(function (customer) {
                //                localDL.getAllCustomersOffers(customer.Id)
                //                    .then(function (offers) {
                //                        offers.forEach(function (offer) {
                //                            var offerChanged = false;
                //                            Object.keys(offer.Changes.Accessories).forEach(function (key) {
                //                                offer.Changes.Accessories[key].forEach(function (accessory) {
                //                                    if (accessory.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
                //                                        if (accessory.SourceDatabaseId === keyToUse) {
                //                                            accessory.SourceDatabaseId = data.AccessoryId;
                //                                            offerChanged = true;
                //                                        }
                //                                    }
                //                                })
                //                            });
                //                            if (offerChanged === true) {
                //                                localDL.addToCache(OPERATION.SaveOffer.Code, offer.Changes.Id, offer);
                //                            }
                //                        });
    
                //                    });
                //            });
                //        });
                //}
                //function updateSavedOffersWithThisCustomTrailerSourceDatabaseIdIfNecessary() {
                //    getCustomers()
                //        .then(function (success) {
                //            var savedOffers = [];
                //            success.data.Customers.forEach(function (customer) {
                //                localDL.getAllCustomersOffers(customer.Id)
                //                    .then(function (offers) {
                //                        offers.forEach(function (offer) {
                //                            var offerChanged = false;
                //                            offer.Changes.Trailers.forEach(function (trailer) {
                //                                if (trailer.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
                //                                    if (trailer.SourceDatabaseId === keyToUse) {
                //                                        trailer.SourceDatabaseId = data.AccessoryId;
                //                                        offerChanged = true;
                //                                    }
                //                                }
                //                            });
                //                            if (offerChanged === true) {
                //                                localDL.addToCache(OPERATION.SaveOffer.Code, offer.Changes.Id, offer);
                //                            }
                //                        });
    
                //                    });
                //            });
                //        });
                //}
                //function updateSavedOffersWithThisCustomPayloadSourceDatabaseIdIfNecessary() {
                //    getCustomers()
                //        .then(function (success) {
                //            var savedOffers = [];
                //            success.data.Customers.forEach(function (customer) {
                //                localDL.getAllCustomersOffers(customer.Id)
                //                    .then(function (offers) {
                //                        offers.forEach(function (offer) {
                //                            var offerChanged = false;
                //                            offer.Changes.Payloads.forEach(function (payload) {
                //                                if (payload.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
                //                                    if (payload.SourceDatabaseId === keyToUse) {
                //                                        payload.SourceDatabaseId = data.AccessoryId;
                //                                        offerChanged = true;
                //                                    }
                //                                }
                //                            });
                //                            if (offerChanged === true) {
                //                                localDL.addToCache(OPERATION.SaveOffer.Code, offer.Changes.Id, offer);
                //                            }
                //                        });
    
                //                    });
                //            });
                //        });
                //}
                break;
            case OPERATION.SaveUserSettings.Code:
                itemDescriptionText = config.getTranslationText('644');
                if (data.Result.ReturnCode === 1) {
                    syncOpSuccessResponseHandlerCallback(curSyncItem.Operation.Code, itemDescriptionText, { data: data }, keyToUse);
                    moveNext();
                } else {
                    handleSyncItemFailure(curSyncItem.Operation.Code, itemDescriptionText, data.ReturnCode, data.Message);
                }
                //if (data.ReturnCode === 1) {
                //    syncOpSuccessResponseHandlerCallback(curSyncItem.Operation.Code, itemDescriptionText, { data: data }, keyToUse);
                //    moveNext();
                //} else {
                //    handleSyncItemFailure(curSyncItem.Operation.Code, itemDescriptionText, data.ReturnCode, data.Message);
                //}
                break;
            // case OPERATION.ChangePassword.Code:
            //     itemDescriptionText = config.getTranslationText('652');
            //     if (data.ReturnCode === 1) {
            //         globals.user.updateUser({ password: curSyncItem.Details.NewPassword });
            //         syncOpSuccessResponseHandlerCallback(curSyncItem.Operation.Code, itemDescriptionText, { data: data }, keyToUse);
            //         moveNext();
            //     } else {
            //         handleSyncItemFailure(curSyncItem.Operation.Code, itemDescriptionText, data.ReturnCode, data.Message);
            //     }
            //     break;
            case OPERATION.PostAppOptionItem.Code:
                if (data.ReturnCode === 1) {
                    moveNext();
                } else {
                    handleSyncItemFailure(curSyncItem.Operation.Code, {}, data.ReturnCode, data.Message);
                }
                break;
            case OPERATION.SendIntercomEventData.Code:
            case OPERATION.SendIntercomUserData.Code:
                if (syncItemsArr.length > 0 && (syncItemsArr[0].Operation.Code === config.OPERATION.SendIntercomEventData.Code || syncItemsArr[0].Operation.Code === config.OPERATION.SendIntercomUserData.Code)) {
                    setTimeout(function () {
                        moveNext();
                    }, 500);
                } else {
                    moveNext();
                }
    
                //if (data.ReturnCode === 1) {
                //    moveNext();
                //} else {
                //    handleSyncItemFailure(data.ReturnCode);
                //}
                break;
            default:
                log("doSync, remoteDL.handleSync, then, default", null, 'dataManager', config.LOG_MESSAGE_TYPE.ERROR);
                globals.isSyncing.value = false;
                callback(true);
                return;
        }
        function handleSyncItemFailure(curOp, itemDescriptionText, returnCode, cloudServicesMessage) {
            var errorDescription = "doSync, handleSyncItemFailure, remoteDL.handleSync, Op=" + curSyncItem.Operation.Code + ", ReturnCode=" + returnCode;
            var obj = {};
            obj[config.INTERCOM_METADATA_ITEM.FAILED_OPERATION] = curSyncItem.Operation.Code;
            obj[config.INTERCOM_METADATA_ITEM.CS_TRANSACTION_MESSAGE] = cloudServicesMessage;
            log(errorDescription, cloudServicesMessage, 'dataManager', config.LOG_MESSAGE_TYPE.ERROR, obj);
            //var obj = {};
            //obj[config.INTERCOM_METADATA_ITEM.FAILED_OPERATION] = curSyncItem.Operation.Code;
            //obj[config.INTERCOM_METADATA_ITEM.ORIGIN] = system.getModuleId(dataManager);
            //obj[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = errorDescription;
            //obj[config.INTERCOM_METADATA_ITEM.CS_TRANSACTION_MESSAGE] = cloudServicesMessage;
            //sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.SYNC_FAILED, obj));
            if (curSyncItem && curSyncItem !== null) {
                localDL.deleteRecord(STORE.SyncQueue, curSyncItem.TimeStamp);
                doSync(syncItemsArr, callback);
            }
            syncOpFailureResponseHandlerCallback(curOp, itemDescriptionText);
        }
        function handleSyncItemFailureAdvanced(curOp, itemDescriptionText, data, returnCode, message) {
            var errorDescription = "doSync, handleSyncItemFailure, remoteDL.handleSync, Op=" + curSyncItem.Operation.Code + ", ReturnCode=" + returnCode;
            var obj = {};
            obj[config.INTERCOM_METADATA_ITEM.FAILED_OPERATION] = curSyncItem.Operation.Code;
            obj[config.INTERCOM_METADATA_ITEM.CS_TRANSACTION_MESSAGE] = message;
            log(errorDescription, message, 'dataManager', config.LOG_MESSAGE_TYPE.ERROR, obj);
            //var obj = {};
            //obj[config.INTERCOM_METADATA_ITEM.FAILED_OPERATION] = curSyncItem.Operation.Code;
            //obj[config.INTERCOM_METADATA_ITEM.ORIGIN] = system.getModuleId(dataManager);
            //obj[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = errorDescription;
            //obj[config.INTERCOM_METADATA_ITEM.CS_TRANSACTION_MESSAGE] = message;
            //sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.SYNC_FAILED, obj));
            if (curSyncItem && curSyncItem !== null) {
                localDL.deleteRecord(STORE.SyncQueue, curSyncItem.TimeStamp);
                data.syncItem = curSyncItem;
                doSync(syncItemsArr, callback);
            }
            syncOpFailureResponseHandlerCallback(curOp, itemDescriptionText, data);
        }
        function updateOffer(keyToUse) {
            var updateOfferDfd = $.Deferred();

            localDL.getFromCache(OPERATION.GetExistingOfferDetails, keyToUse)
            .then(function (offerData) {
                if (offerData !== null) {
                    if (offerData.OfferId !== data.Offer.Id) {
                        deleteOfferRecRequired = true;
                        if (typeof keyToUse === 'string' && keyToUse.indexOf('_') !== -1) {
                            idOfOfferToDelete = keyToUse;
                        } else {
                            idOfOfferToDelete = offerData.OfferId;
                        }
                        offerData.OfferId = data.Offer.Id;
                        offerData.Changes.Id = data.Offer.Id;
                    }

                    offerData.Changes.Description = data.Offer.Description;
                    //offerData.Changes.AdditionalDescription = '';

                    //offerData.UpdateCounter = data.Offer.UpdateCounter;
                    //offerData.Changes.UpdateCounter = data.Offer.UpdateCounter;

                    offerData.CustomerId = data.Customer.Id;
                    offerData.Customer.Id = data.Customer.Id;
                    //offerData.Customer.UpdateCounter = data.Customer.UpdateCounter;
                    //offerData.Customer.OverallUpdateCounter = data.Customer.OverallUpdateCounter;
                    offerData.OfferDate = moment(data.Offer.OfferDate, ["M/D/YYYY", "DD-MM-YYYY", "MM-DD-YYYY", "DD/MM/YYYY", "YYYY-MM-DD", "YYYY/MM/DD"]).format('YYYY-MM-DDTHH:mm:ss:SSS');

                    updatedOffer = offerData;

                }
                updateOfferDfd.resolve();
            });
            return updateOfferDfd.promise();
        }
        // function updateCustomer(custId) {
        //     var updateCustomerDfd = $.Deferred();
        //     localDL.getFromCache(OPERATION.GetCustomer, custId)
        //         .then(function (custData) {

        //             if (custData.Id !== data.Customer.Id) {
        //                 deleteTempCustRecRequired = true;
        //                 custData.Id = data.Customer.Id
        //             }
        //             //custData.UpdateCounter = data.Customer.UpdateCounter;
        //             //custData.OverallUpdateCounter = data.Customer.OverallUpdateCounter;
        //             for (var i = 0; i < custData.Offers.length; i++) {
        //                 if (custData.Offers[i].Id === keyToUse) {
        //                     custData.Offers[i].Id = data.Offer.Id;
        //                     custData.Offers[i].Description = data.Offer.Description;
        //                     data.Offer.AdditionalDescription = custData.Offers[i].AdditionalDescription;
        //                     //custData.Offers[i].AdditionalDescription = '';
        //                     //custData.Offers[i].UpdateCounter = data.Offer.UpdateCounter;
        //                     custData.Offers[i].OfferDate = moment(data.Offer.OfferDate, ["M/D/YYYY", "DD-MM-YYYY", "MM-DD-YYYY", "DD/MM/YYYY", "YYYY-MM-DD", "YYYY/MM/DD"]).format('YYYY-MM-DDTHH:mm:ss:SSS');
        //                     break;
        //                 }
        //             }
        //             localDL.addToCache(OPERATION.SaveCustomer.Code, custData.Id, custData)
        //                 .then(function () {
        //                     localDL.getAllCustomersOffers(custData.Id)
        //                         .then(function (custOffersData) {

        //                             if (custOffersData.length === 0) {
        //                                 updateCustomerDfd.resolve(custData);
        //                             } else {
        //                                 var copyCustData = JSON.parse(JSON.stringify(custData));
        //                                 delete copyCustData.Notes;
        //                                 delete copyCustData.Offers;
        //                                 delete copyCustData.Actions;
        //                                 var savedOfferCustUpdateOps = [];
        //                                 for (var i = 0; i < custOffersData.length; i++) {
        //                                     custOffersData[i].CustomerId = custData.Id;

        //                                     custOffersData[i].Customer = copyCustData;
        //                                     savedOfferCustUpdateOps.push(localDL.addToCache(OPERATION.SaveOffer.Code, custOffersData[i].OfferId, custOffersData[i]));
        //                                 }
        //                                 $.when.apply($, savedOfferCustUpdateOps)
        //                                     .done(function () {
        //                                         updateCustomerDfd.resolve(custData);
        //                                     });
        //                             }
        //                         });

        //                 });
        //         });
        //     return updateCustomerDfd.promise();
        // }
        function updateSyncQueueOffersAndCustomersIfNecessary() {
            for (var i = 0; i < syncItemsArr.length; i++) {
                if (syncItemsArr[i].ParentId === curSyncItem.ParentId) {
                    //syncItemsArr[i].Details.Customer.UpdateCounter = data.Customer.UpdateCounter;
                    syncItemsArr[i].Details.Customer.OverallUpdateCounter = data.OverallCustomerUpdateCounter;
                    syncItemsArr[i].Details.Offer.UpdateCounter = data.UpdateCounter;
                } else if (syncItemsArr[i].ParentId === 0) {
                    syncItemsArr[i].Details.OverallUpdateCounter = data.OverallCustomerUpdateCounter;
                }

            }
        }
        function updateSyncQueueItemsAfterSaveCustomerIfNecessary() {
            for (var i = 0; i < syncItemsArr.length; i++) {
                if (syncItemsArr[i].ParentId === curSyncItem.ParentId || syncItemsArr[i].ParentId === curSyncItem.ExistingId) {
                    //syncItemsArr[i].Details.Customer.UpdateCounter = data.Customer.UpdateCounter;
                    if (syncItemsArr[i].Operation.Code === config.OPERATION.SaveOffer.Code) {
                        syncItemsArr[i].Details.Customer.OverallUpdateCounter = data.Customer.OverallUpdateCounter;
                        syncItemsArr[i].Details.Customer.UpdateCounter = data.Customer.UpdateCounter;
                    }
                    //else if (syncItemsArr[i].Operation.Code === config.OPERATION.SaveCustomerNote.Code || syncItemsArr[i].Operation.Code === config.OPERATION.SaveCustomerAction.Code) {

                    //}

                }

            }
        }
        function updateSyncQueueItemsAfterSaveCustomerNoteIfNecessary() {
            for (var i = 0; i < syncItemsArr.length; i++) {
                if (syncItemsArr[i].ParentId === curSyncItem.ParentId || syncItemsArr[i].ParentId === curSyncItem.ExistingId) {
                    if (syncItemsArr[i].Operation.Code === config.OPERATION.SaveCustomer.Code) {
                        syncItemsArr[i].Details.OverallUpdateCounter = data.OverallCustomerUpdateCounter;
                    } else if (syncItemsArr[i].Operation.Code === config.OPERATION.SaveOffer.Code) {
                        syncItemsArr[i].Details.Customer.OverallUpdateCounter = data.OverallCustomerUpdateCounter;
                    }
                }
            }
        }
        function updateSyncQueueItemsAfterSaveCustomerActionIfNecessary() {
            for (var i = 0; i < syncItemsArr.length; i++) {
                if (syncItemsArr[i].ParentId === curSyncItem.ParentId || syncItemsArr[i].ParentId === curSyncItem.ExistingId) {
                    if (syncItemsArr[i].Operation.Code === config.OPERATION.SaveCustomer.Code) {
                        syncItemsArr[i].Details.OverallUpdateCounter = data.OverallCustomerUpdateCounter;
                    } else if (syncItemsArr[i].Operation.Code === config.OPERATION.SaveOffer.Code) {
                        syncItemsArr[i].Details.Customer.OverallUpdateCounter = data.OverallCustomerUpdateCounter;
                    }
                }
            }
        }
        function updateLocalObjectProperties(saveOpToUse, localObject) {
            var tempAccessoryType, tempSource, tempDescription, tempType, tempObjectToSave;
            if (localObject.Body !== undefined) {
                localObject.Body.Id = data.AccessoryId;
                localObject.Body.UpdateCounter = data.UpdateCounter;
                localObject.Body.Source = config.VEHICLE_SOURCE_TYPES.CUSTOM;
                localObject.Body.AccessLevel = data.AccessLevel;
                tempAccessoryType = localObject.Body.AccessoryType;
                tempSource = localObject.Body.Source;
                tempDescription = localObject.Body.Description;
                tempType = localObject.Body.Type;
                tempObjectToSave = localObject.Body;
            } else if (localObject.Trailer !== undefined) {
                localObject.Trailer.Id = data.AccessoryId;
                localObject.Trailer.UpdateCounter = data.UpdateCounter;
                localObject.Trailer.Source = config.VEHICLE_SOURCE_TYPES.CUSTOM;
                localObject.Trailer.AccessLevel = data.AccessLevel;
                tempAccessoryType = config.ACCESSORY_TYPES.TRAILER;//localObject.Trailer.AccessoryType;
                tempSource = localObject.Trailer.Source;
                tempDescription = localObject.Trailer.Description;
                tempType = localObject.Trailer.Type;
                tempObjectToSave = localObject.Trailer;
            } else if (localObject.Payload !== undefined) {
                localObject.Payload.Id = data.AccessoryId;
                localObject.Payload.UpdateCounter = data.UpdateCounter;
                localObject.Payload.Source = config.VEHICLE_SOURCE_TYPES.CUSTOM;
                localObject.Payload.AccessLevel = data.AccessLevel;
                tempAccessoryType = localObject.Payload.AccessoryType;
                tempSource = localObject.Payload.Source;
                tempDescription = localObject.Payload.Description;
                tempObjectToSave = localObject.Payload;
            } else {
                localObject.Accessory.Id = data.AccessoryId;
                localObject.Accessory.UpdateCounter = data.UpdateCounter;
                localObject.Accessory.Source = config.VEHICLE_SOURCE_TYPES.CUSTOM;
                localObject.Accessory.AccessLevel = data.AccessLevel;
                tempAccessoryType = localObject.Accessory.AccessoryType;
                tempSource = localObject.Accessory.Source;
                tempDescription = localObject.Accessory.Description;
                tempObjectToSave = localObject.Accessory;

            }

            data.AccessoryType = tempAccessoryType;
            data.Source = tempSource;
            data.Description = tempDescription;
            data.Type = tempType;
            data.ParentType = curSyncItem.Details.ParentType;
            data.AccessLevel = tempObjectToSave.AccessLevel;

            localDL.addToCache(saveOpToUse, data.AccessoryId + '_' + tempAccessoryType + '_' + tempSource, tempObjectToSave)
                .then(function () {
                    if (cleanUpTempIds === true) {
                        var storeToUse;
                        if (localObject.Body) {
                            storeToUse = STORE.Bodies;
                        } else if (localObject.Trailer) {
                            storeToUse = STORE.Trailers;
                        } else if (localObject.Payload) {
                            storeToUse = STORE.Payloads;
                        } else {
                            storeToUse = STORE.Accessories;
                        }
                        localDL.deleteRecord(storeToUse, keyToUse + '_' + tempAccessoryType + '_' + tempSource)
                                .then(function () {
                                    syncOpSuccessResponseHandlerCallback(curSyncItem.Operation.Code, itemDescriptionText, { savedAccessory: data }, keyToUse);
                                    moveNext();
                                });
                    } else {
                        syncOpSuccessResponseHandlerCallback(curSyncItem.Operation.Code, itemDescriptionText, { savedAccessory: data }, keyToUse);
                        moveNext();
                    }
                });
        }
        function updateAccessorySourceDatabaseIdIfNecessary(savedOfferSyncItem) {
            Object.keys(savedOfferSyncItem.Details.Offer.Accessories).forEach(function (key) {
                savedOfferSyncItem.Details.Offer.Accessories[key].forEach(function (accessory) {
                    if (accessory.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
                        if (accessory.SourceDatabaseId === keyToUse) {
                            accessory.SourceDatabaseId = data.AccessoryId;
                        }
                    }
                });
            });
        }
        
        function updatePayloadSourceDatabaseIdIfNecessary(savedOfferSyncItem) {
            savedOfferSyncItem.Details.Offer.Payloads.forEach(function (payload) {
                if (payload.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
                    if (payload.SourceDatabaseId === keyToUse) {
                        payload.SourceDatabaseId = data.AccessoryId;
                    }
                }
            });
        }
    }
    
    function handleRemoteSyncFailure(errorMessage) {
        log("doSync, remoteDL.handleSync, fail", errorMessage, 'dataManager', config.LOG_MESSAGE_TYPE.ERROR);
        if (errorMessage.errorMessage === "timeout") {
            if (curSyncItem && curSyncItem !== null) {
                if (curSyncItem.timeout === undefined) {
                    curSyncItem.timeout = (config.postRequestTimeout * 1000) + 10000;// add 10 seconds to timeout
                    syncItemsArr.unshift(curSyncItem);
                    doSync(syncItemsArr, callback);
                } else {
                    globals.isSyncing.value = false;
                    deleteSyncItemIfNotAllowedOffline();
                    if (callback !== undefined) {
                        callback(true);
                    }
                }
            }
        } else {
            globals.isSyncing.value = false;
            deleteSyncItemIfNotAllowedOffline();
            if (callback !== undefined) {
                callback(true);
            }
        }
    }
}



/**
* Checks if there are any related operations already in the sync queue and acts accordingly by updating or removing depending on the circumstance. The idea is to only have one occurance
* for each object in the sync queue or remove sub objects that are now defunct as their parent object has been deleted.
* @method manageSyncQueue
* @param {Number/String} existingId An id for the object on which the operation is being carried out. If the id is a temporary id it will be a String, otherwise a Number.
* @param {Number/String} parentId An id for a customer, should be 0 if operation realtes to a customer as only actions, notes and offers will have a parent customer. In the instance 
* of a temporary id the value will be a String.
* @param {String} saveOrDelete A flag to denote the type of the new operation.
*/
function manageSyncQueue(existingId, parentId, saveOrDelete, newOp) {
    var dfd = $.Deferred();

    var abortAdd = false;

    localDL.checkIfSyncItems()
        .then(function (syncItems) {
            var i;
            if (saveOrDelete === "DELETE") {
                if (parentId === 0) {
                    for (i = 0; i < syncItems.length; i++) {
                        if (syncItems[i].ParentId === existingId) {
                            localDL.deleteRecord(STORE.SyncQueue, syncItems[i].TimeStamp);
                        } else if (syncItems[i].ExistingId === 0) {
                            if (typeof existingId === 'string' && existingId.indexOf(syncItems[i].TimeStamp) !== -1) {
                                localDL.deleteRecord(STORE.SyncQueue, syncItems[i].TimeStamp);
                                abortAdd = true;
                                
                            }
                        } else if (syncItems[i].ExistingId === existingId) {
                            localDL.deleteRecord(STORE.SyncQueue, syncItems[i].TimeStamp);
                        }
                    }
                } else {
                    for (i = 0; i < syncItems.length; i++) {
                        if (existingId !== 0) {
                            if (syncItems[i].ExistingId === 0) {
                                if (typeof existingId === 'string' && existingId.indexOf(syncItems[i].TimeStamp) !== -1) {
                                    localDL.deleteRecord(STORE.SyncQueue, syncItems[i].TimeStamp);
                                    abortAdd = true;
                                    break;
                                }
                            } else {
                                if (syncItems[i].ExistingId === existingId) {
                                    if (typeof syncItems[i].ExistingId === 'string' && syncItems[i].ExistingId.indexOf("_") !== -1) {
                                        abortAdd = true;
                                    }
                                    localDL.deleteRecord(STORE.SyncQueue, syncItems[i].TimeStamp);
                                    break;
                                }
                            }
                        }
                    }
                }
            } else {
                for (i = 0; i < syncItems.length; i++) {
                    //syncItems[i].ExistingId
                    if (existingId !== 0) {
                        if (syncItems[i].ExistingId === 0) {
                            if (typeof existingId === 'string' && existingId.indexOf(syncItems[i].TimeStamp) !== -1 && (syncItems[i].Operation.Code === newOp.Code)) {
                                localDL.deleteRecord(STORE.SyncQueue, syncItems[i].TimeStamp);
                                break;
                            }
                        } else {
                            if (syncItems[i].ExistingId === existingId && (syncItems[i].Operation.Code === newOp.Code)) {
                                localDL.deleteRecord(STORE.SyncQueue, syncItems[i].TimeStamp);
                                break;
                            }
                        }
                    }
                }
            }
            dfd.resolve(abortAdd);
        });

    return dfd.promise();
}

/**
* Adds an item to the SyncQueue object store making sure the correct ids are set on the way through.
* @method queueSyncJob
* @param {Object} op The operation that is to be carried out. This op object also encapsulates the associated request url.
* @param {Number} timeStamp A timeStamp for when the operation was attempted. It acts as a uinique id for the sync item and also is utilised in creating the temporary id for the object if necessary.
* @param {Object} params An object whose proprties are the url params required to complete the request url. Note these must be named correctly as the key is used to build the request.
* @param {Object} details An optional argument that specifies any payload relating to the operation that needs to be POST-ed to the server.
*/
function queueSyncJob(op, timeStamp, params, details) {
    var queueSyncJobDfd = $.Deferred();
    var existingId = 0;
    var parentId = params["customerId"] || 0;
    switch (op) {
        case OPERATION.SaveOffer:
            existingId = params["offerId"] || 0;
            break;
        case OPERATION.SaveCustomer:
            existingId = params["id"] || 0;
            break;
        case OPERATION.SaveCustomerNote:
        case OPERATION.SaveCustomerAction:
            existingId = params["id"] || 0;
            delete params["customerId"];
            break;
        case OPERATION.SaveUserSettings:
            existingId = params["id"]|| 0;
            //delete params["customerId"];
            break;
        // These cases may not be needed
        case OPERATION.SendIntercomEventData.Code:
            //params = null;
            break;
        default:
            existingId = params["id"] || 0;
            break;
    }
    var syncItem = {
        TimeStamp: timeStamp,
        Details: details || null,
        Operation: op,
        ExistingId: existingId,
        ParentId: parentId,
        Params: params
    };
    localDL.addToCache(OPERATION.SyncQueue, timeStamp, syncItem)
        .then(function () {
            queueSyncJobDfd.resolve();
        });
    return queueSyncJobDfd.promise();
}
    
function registerSyncOpSuccessResponseHandler(syncOpSuccessHandler) {
    syncOpSuccessResponseHandlerCallback = syncOpSuccessHandler;
}
function registerSyncOpFailureResponseHandler(syncOpFailureHandler) {
    syncOpFailureResponseHandlerCallback = syncOpFailureHandler;
}

function registerSharedOfferSyncCallback(sharedOfferSyncHandler) {
    sharedOfferSyncHandlerCallback = sharedOfferSyncHandler;
}

function registerTeamSyncHandlerCallback(teamSyncHandler) {
    teamSyncHandlerCallback = teamSyncHandler;
}


function getSelectionList(application, vehicleType, vehicleTypeCode, axleLayout, make, range) {

    
    // var dfd = $.Deferred();
    if(globals.selectionListDfd) {
        return globals.selectionListDfd.promise();
    } else {
        globals.selectionListDfd = $.Deferred();
    }

    if (doingBackgroundSelectionRetrieval.value === true || globals.isSyncing.value === true) {
        if (canUseRawSelectionListData()) {
            handleRawSelectionListDataResolve();
        } else {
            var doingBackgroundSelectionRetrievalSubscriptionRef = watch(doingBackgroundSelectionRetrieval, function (newValue) {
                doingBackgroundSelectionRetrievalSubscriptionRef();
                if (canUseRawSelectionListData()) {
                    handleRawSelectionListDataResolve();
                } else {
                    if (newValue === false && globals.isSyncing.value === false) {
                        doGetSelectionList(application, vehicleType, vehicleTypeCode, axleLayout, make, range, globals.selectionListDfd);
                    }
                }
            });
            var globalsIsSyncingSubscriptionRef = watch(globals.isSyncing, function (newVal) {
                globalsIsSyncingSubscriptionRef();
                if (canUseRawSelectionListData()) {
                    handleRawSelectionListDataResolve();
                } else {
                    if (newVal === false && doingBackgroundSelectionRetrieval.value === false) {
                        doGetSelectionList(application, vehicleType, vehicleTypeCode, axleLayout, make, range, globals.selectionListDfd);
                    }
                }
                
            });
        }
        
    } else {
        if (canUseRawSelectionListData(true)) {
            handleRawSelectionListDataResolve();
        } else {
            doGetSelectionList(application, vehicleType, vehicleTypeCode, axleLayout, make, range, globals.selectionListDfd);
        }
        
    }

    function canUseRawSelectionListData(doWizardCheck) {
        if (doWizardCheck !== undefined && doWizardCheck === true) {
            if (selectionListResult !== undefined && selectionListResult !== null) {
                return true;
            }else {
                return false;
            }
        } else {
            if (selectionListResult !== undefined && selectionListResult !== null) {
                return true;
            } else {
                return false;
            }
        }
    }
    function handleRawSelectionListDataResolve() {
        doSelectionResolve(globals.selectionListDfd, JSON.parse(JSON.stringify(selectionListResult)));
        //selectionListResult = null;
    }


    return globals.selectionListDfd.promise();
}

/**
* Retrieves the vehicles to be displayed in the selection list. Will atempt to reteieve locally first and then online if no records found.
* @method getSelectionList
* @param {String} application The applcation of the vehicle(s) to be retrieved. 
* @param {String} vehicleType The vehicle type of the vehicle(s) to be retrieved.
* @return {Object} Returns data for selection.
*/
function doGetSelectionList(application, vehicleType, vehicleTypeCode, axleLayout, make, range, dfd, forceOnline) {
    //var dfd = $.Deferred();
    // var results;
        



    if (application === undefined) {
        application = '';
    }
    if (vehicleType === undefined) {
        vehicleType = '';
    }
    if (vehicleTypeCode === undefined) {
        vehicleTypeCode = '';
    }
    if (axleLayout === undefined) {
        axleLayout = '';
    }
    if (make === undefined) {
        make = '';
    }
    if (range === undefined) {
        range = '';
    }
    if (typeof forceOnline !== 'boolean') {
        forceOnline = false;
    }

    //var request = 'offers/newoffers?application=' + application + '&vehicleType=' + vehicleType + '&pageNumber=1&pageSize=30';
    var request = 'offers/newoffers?application=' + application +
                    '&vehicleType=' + vehicleType +
                    '&vehicleTypeCode=' + vehicleTypeCode +
                    '&axleLayout=' + axleLayout +
                    '&make=' + make +
                    '&range=' + range +
                    '&pageNumber=1&pageSize=30';


    handleDataRequest(function () { return localDL.getSelectionListLocal(application, vehicleType, vehicleTypeCode, axleLayout, make, range); }, request, OPERATION.GetSelectionList, forceOnline)
        .then(function (data) {
            //if (globals.user.getNumberOfOffersFromServer() === 0 || data.Offers.length === globals.user.getNumberOfOffersFromServer()) {
            if (data.Offers.length === globals.user.getNumberOfOffersFromServer()) {
                doSelectionResolve(dfd, data);
            } else {
                doGetSelectionList(application, vehicleType, vehicleTypeCode, axleLayout, make, range, dfd, true);
            }
    })
    .fail(function (errorMessage) {
        dfd.reject(errorMessage);
    });
}

function doSelectionResolve(dfd, theData) {
    dfd.resolve({ selection: theData });
}

// function mapDataFromJS(cachedData) {
//     var mapDataDfd = $.Deferred();

//     function getData() {
//         var temp = ko.mapping.fromJS(cachedData);
//         return mapDataDfd.resolve(temp);
//     }
//     //Timeout required to show spinner on selection page when data has been cached
//     window.setTimeout(getData, 50);

//     return mapDataDfd.promise();
// }

/**
* Retrieves the default offer details for any vehicle displayed in selection list.
* @method getUserSettings        
* @return {Object} Returns data containing user settings from user licence
*/
function getUserSettings() {
    // var dfd = $.Deferred();
    if(globals.userSettingsDfd) {
        return globals.userSettingsDfd.promise();
    } else {
        globals.userSettingsDfd = $.Deferred();
    }

    var uniqueRequest = 'usersettings/get';
    var operation = OPERATION.GetUserSettings;
    
    doRequest(globals.isOnline.value);

    function doRequest(forceOnline) {
        handleDataRequest(function () { return localDL.getFromCache(operation, PROPERTY.UserSettings); }, uniqueRequest, operation, forceOnline)
        .then(function (data) {
            //any custom pre-processing of data should be handled here
            if (globals.user.isTrialUser()) {
                data.UserSettings.ReportLogo = null;
            }
            globals.user.setDashboardConfiguration(null);
            globals.user.updateUser({
                "activeLegislationId": data.UserSettings.DefaultLegislationId,
                "activeMeasurementSystemId": data.UserSettings.DefaultMeasurementSystemId,
                "lengthIncrement": data.UserSettings.DefaultLengthIncrement,
                "massIncrement": data.UserSettings.DefaultMassIncrement,
                "percentageIncrement": data.UserSettings.DefaultPercentageIncrement,
                "colourDrawings": data.UserSettings.ColourDrawings,
                "showComplianceScorecard": data.UserSettings.ShowComplianceScorecard,
                "specifyWheelbaseAs": data.UserSettings.DefaultSpecifyWheelbaseAs,
                "specifyCabDimensionsAs": data.UserSettings.DefaultSpecifyCabDimensionsAs,
                "specifyChassisLengthAs": data.UserSettings.DefaultSpecifyChassisLengthAs,
                "specifyFuelConsumptionAs": data.UserSettings.DefaultSpecifyFuelConsumptionAs,
                "specifyLicencingRegionAs": data.UserSettings.DefaultSpecifyLicencingRegionAs,
                "specifyAxleRatingAs": data.UserSettings.DefaultSpecifyAxleRatingAs,
                "defaultReportPdfPageSize": data.UserSettings.DefaultReportPdfPageSize,
                "reportLogo": data.UserSettings.ReportLogo,
                "dashboardConfiguration": data.UserSettings.DashboardConfiguration,
                "showTips": data.UserSettings.ShowTips,
                "reportViewLayout": data.UserSettings.ReportViewLayout,
                "reportViews": data.UserSettings.ReportViews,
                "nteaUserValidationType": data.UserSettings.NteaUserValidationType
            });
            globals.userSettingsDfd.resolve({ userSettings: data.UserSettings });
        })
        .fail(function (errorMessage) {
            globals.userSettingsDfd.reject(errorMessage);
            
        });
    }

    return globals.userSettingsDfd.promise();
}

/**
* Retrieves the default offer details for any vehicle displayed in selection list.
* @method getNewOfferDetails
* @param {Number} vehicleId The id of the vehicle the offer relates to. 
* @param {Number} vehicleDistributionOptionId The id of the vehicle options distribution associated with the offer.
* @param {Number} vehicleDistributionGroupId The id of the distribution group the vehicle relating to the offer is associated with.
* @return {Object} Returns data for a new offer except specification and advantages.
*/
function getNewOfferDetails(vehicleId, vehicleDistributionOptionId, vehicleDistributionGroupId) {
    var dfd = $.Deferred();
    selectedVehicleId = vehicleId;
    var uniqueRequest = 'offers/newoffer?vehicleId=' + vehicleId + '&vehicleDistributionOptionId=' + vehicleDistributionOptionId + '&vehicleDistributionGroupId=' + vehicleDistributionGroupId;
    var operation = OPERATION.GetNewOfferDetails;
    log("New Offer Opened", null, 'dataManager', config.LOG_MESSAGE_TYPE.INFO);
    handleDataRequest(function () { return localDL.getFromCache(operation, vehicleId); }, uniqueRequest, operation, false)
        .then(function (data, doReplace) {
            //any custom pre-processing of data should be handled here

            //decompress any graphic files
            if(utils.itemHasSideViewGraphic(data.Offer.Configuration)) {
                utils.translateGraphicToXmlDoc(data.Offer.Configuration.VehicleDrawing).then(function (sideElevationXmlDoc) {
                    data.Offer.Configuration.DecompressedVehicleDrawing = sideElevationXmlDoc;
                    if(utils.itemHasTopViewGraphic(data.Offer.Configuration)) {
                        utils.translateGraphicToXmlDoc(data.Offer.Configuration.VehicleTopViewDrawing).then(function (topElevationXmlDoc) {
                            data.Offer.Configuration.DecompressedVehicleTopViewDrawing = topElevationXmlDoc;
                            dfd.resolve({ offer: data });
                        });
                    }else {
                        dfd.resolve({ offer: data });
                    }
                }).catch(function (){
                    dfd.resolve({ offer: data });
                });
            }else if(utils.itemHasTopViewGraphic(data.Offer.Configuration)) {
                utils.translateGraphicToXmlDoc(data.Offer.Configuration.VehicleTopViewDrawing).then(function (topElevationXmlDoc) {
                    data.Offer.Configuration.DecompressedVehicleTopViewDrawing = topElevationXmlDoc;
                    dfd.resolve({ offer: data });
                });
            }else {
                dfd.resolve({ offer: data });
            }

            // if (doReplace === true) {
            //     dfd.resolve({ offer: data });
            // } else {
            //     dfd.resolve({ offer: data });
            // }
            
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });
                
    return dfd.promise();
}

/**
* Retrieves the specification and advantages details for any vehicle displayed in selection list or for an existing offer if the existingOfferId param is supplied.
* @method getOfferSpecificationDetails
* @param {Number} vehicleId The id of the vehicle the specification relates to. 
* @param {Number} vehicleDistributionOptionId The id of the vehicle options distribution associated with the offer.
* @param {Number} vehicleDistributionGroupId The id of the distribution group the vehicle relating to the offer is associated with.
* @param {Number} languageId The id of the language the offer is presented in.
* @param {Number} currencySymbol The currency symbol.
* @param {Number} existingOfferId The id of the offer in the case that it is a saved offer and not a new offer the specification is being retrieved for. Used to determine 
* which op to implement and also as a DB key depending on the op.
* @return {Object} Returns specification and advantages data for a new offer or existing offer.
*/
function getOfferSpecificationDetails(vehicleId, vehicleDistributionOptionId, vehicleDistributionGroupId, languageId, currencySymbol, existingOfferId, competitor1Id, competitor2Id) {

    var dfd = $.Deferred(),
        competitor1VehicleId = competitor1Id !== undefined ? competitor1Id : 0,
        competitor2VehicleId = competitor2Id !== undefined ? competitor2Id : 0;

    var request = 'offers/specification?vehicleId=' + vehicleId + '&vehicleDistributionOptionId=' + vehicleDistributionOptionId +
        '&vehicleDistributionGroupId=' + vehicleDistributionGroupId + '&languageId=' + languageId + '&currencySymbol=' + currencySymbol + 
        '&competitor1VehicleId=' + competitor1VehicleId + '&competitor2VehicleId=' + competitor2VehicleId;
    
    var operation, key;
    if (existingOfferId !== undefined) {
        operation = OPERATION.GetExistingOfferSpecificationDetails;
        key = existingOfferId;
    } else {
        operation = OPERATION.GetNewOfferSpecificationDetails;
        key = vehicleId;
    }
        

    handleDataRequest(function () { return localDL.getFromCache(operation, key); }, request, operation, false)
        .then(function (data, doReplace) {
            //any custom pre-processing of data should be handled here
            if (doReplace === true) {
                var promises = [];
                replaceVehicleSpecificationImageURLs(data.Specification.BaseVehicle, promises);
                for (var i = 0; i < data.Specification.CompetitorVehicles.length; i++) {
                    replaceVehicleSpecificationImageURLs(data.Specification.CompetitorVehicles[i], promises);
                }
                $.when.apply($, promises)
                    .done(function () {
                        dfd.resolve({ offer: data });
                    });
            } else {
                if (operation === OPERATION.GetExistingOfferSpecificationDetails) {
                    localDL.addToCache(operation, existingOfferId, data)
                        .then(function () {
                            existingSpecDataCached.value = true;
                        });
                }
                dfd.resolve({ offer: data });
            }



        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

/**
* Retrieves existing offer details for any saved offer.
* @method getExistingOfferDetails
* @param {Number} offerId The id of the saved offer to be retrieved. 
* @param {Number} customerId The id of the customer associated with the offer.
* @param {Number} activeSalesPersonId The id of the user/salesperson assocaited with the offer.
* @return {Object} Returns data for an existing/saved offer except specification and advantages.
*/
function getExistingOfferDetails(offerId, customerId, activeSalesPersonId, isSharedOffer) {

    var dfd = $.Deferred();
    //this transaction cannot be used offline at the moment
    var offlineUses = 0;

    var request = 'offers/existingoffer?offerId=' + offerId + '&customerId=' + customerId + '&userId=' + activeSalesPersonId + '&offlineUses=' + offlineUses;
    var operation;
    var localFunc = null;
    var forceOnline = true;
    if (isSharedOffer === true) {
        operation = OPERATION.GetSharedOfferDetails.Code;
        forceOnline = true;
        request += '&isSharedOffer=true';
    } else {
        operation = OPERATION.GetExistingOfferDetails;
        //localFunc = function () { return localDL.getFromCache(operation, offerId); };
    }
    
    log("Existing Offer Opened", null, 'dataManager', config.LOG_MESSAGE_TYPE.INFO);
    handleDataRequest(localFunc, request, operation, forceOnline)
        .then(function (data, doReplace) {
            //any custom pre-processing of data should be handled here
            // if (doReplace === true) {
            //     dfd.resolve({ offer: data });
            // } else {
            //     dfd.resolve({ offer: data });
            // }
            let decompressionPromises = [];
            if(data.Changes.Trailers.length > 0) {
                data.Changes.Trailers.forEach(function (trailer) { 
                    decompressionPromises.push(decompressAnyGraphicFilesWithPromise(trailer));
                    // Object.keys(trailer.Accessories).forEach(function (key) {
                    //     if(trailer.Accessories[key].length > 0) {
                    //         trailer.Accessories[key].forEach(function (trailerItem) {
                    //             decompressionPromises.push(decompressAnyGraphicFilesWithPromise(trailerItem));
                    //         });
                    //     }
                    // });
                });
            }
            if(data.Changes.Payloads.length > 0) {
                data.Changes.Payloads.forEach(function (payload) { 
                    decompressionPromises.push(decompressAnyGraphicFilesWithPromise(payload));
                });
            }
            Object.keys(data.Changes.Accessories).forEach(function (key) {
                if(data.Changes.Accessories[key] && data.Changes.Accessories[key].length > 0) {
                    data.Changes.Accessories[key].forEach(function (item) {
                        decompressionPromises.push(decompressAnyGraphicFilesWithPromise(item));
                    });
                }
            });
            $.when.apply($, decompressionPromises)
            .done(function () {
                if(utils.itemHasSideViewGraphic(data.Offer.Configuration)) {
                    utils.translateGraphicToXmlDoc(data.Offer.Configuration.VehicleDrawing).then(function (sideElevationXmlDoc) {
                        data.Offer.Configuration.DecompressedVehicleDrawing = sideElevationXmlDoc;
                        if(utils.itemHasTopViewGraphic(data.Offer.Configuration)) {
                            utils.translateGraphicToXmlDoc(data.Offer.Configuration.VehicleTopViewDrawing).then(function (topElevationXmlDoc) {
                                data.Offer.Configuration.DecompressedVehicleTopViewDrawing = topElevationXmlDoc;
                                dfd.resolve({ offer: data });
                            });
                        }else {
                            dfd.resolve({ offer: data });
                        }
                    }).catch(function (error){
                        dfd.resolve({ offer: data });
                    });
                }else if(utils.itemHasTopViewGraphic(data.Offer.Configuration)) {
                    utils.translateGraphicToXmlDoc(data.Offer.Configuration.VehicleTopViewDrawing).then(function (topElevationXmlDoc) {
                        data.Offer.Configuration.DecompressedVehicleTopViewDrawing = topElevationXmlDoc;
                        dfd.resolve({ offer: data });
                    });
                }else {
                    dfd.resolve({ offer: data });
                }
            })
            .fail(function (error) {
                dfd.reject(error);
            });
            


        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });


    return dfd.promise();

}

function getBodyStubs() {
    var dfd = $.Deferred();

    if (doingBackgroundBodiesRetrieval.value === true || globals.isSyncing.value === true) {
        if (bodiesResult !== undefined && bodiesResult !== null) {
            doBodiesResolve(dfd, JSON.parse(JSON.stringify(bodiesResult)));
        } else {
            var doingBackgroundBodiesRetrievalSubscriptionRef = watch(doingBackgroundBodiesRetrieval, function (newValue) {
                doingBackgroundBodiesRetrievalSubscriptionRef();
                if (newValue === false && globals.isSyncing.value === false) {
                    doGetBodies(dfd);
                }
            });
            var globalsIsSyncingSubscriptionRef = watch(globals.isSyncing, function (newVal) {
                globalsIsSyncingSubscriptionRef();
                if (newVal === false && doingBackgroundBodiesRetrieval.value === false) {
                    doGetBodies(dfd);
                }
            });
        }

    } else {
        if (bodiesResult !== undefined && bodiesResult !== null) {
            doBodiesResolve(dfd, JSON.parse(JSON.stringify(bodiesResult)));
        } else {
            doGetBodies(dfd);
        }

    }

    return dfd.promise();
}

function doGetBodies(dfd, forceRemote) {
    var request = 'accessories/allbodystubs';
    if (typeof forceRemote !== 'boolean') {
        forceRemote = false;
    }

    handleDataRequest(function () { return localDL.getBodiesLocal(); }, request, OPERATION.GetBodyStubs, forceRemote)
    .then(function (data) {
        if (data.Bodies.length === globals.user.getNumberOfBodiesFromServer()) {
            //any custom pre-processing of data should be handled here
            doBodiesResolve(dfd, data);
        } else {
            doGetBodies(dfd, true);
        }
    })
    .fail(function (errorMessage) {
        dfd.reject(errorMessage);
    });
}

function doBodiesResolve(dfd, theData) {
    dfd.resolve({ bodies: theData });
}

function getBody(bodyId, source, accessLevel) {
    var dfd = $.Deferred();

    var request = 'accessories/bodydetail?bodyId=' + bodyId + '&source=' + source + '&accessLevel=' + accessLevel;
    var forceOnline = false;
    if (accessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
        forceOnline = true;
    }

    handleDataRequest(function () { return localDL.getFromCache(OPERATION.GetBody, bodyId + '_' + config.ACCESSORY_TYPES.BODY + '_' + source); }, request, OPERATION.GetBody, forceOnline)
        .then(function (data) {
            decompressAnyGraphicFilesAndResolve(data.Body, function() { dfd.resolve(data); });
            // dfd.resolve(data);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function getAccessoryStubs() {
    var dfd = $.Deferred();

    if (doingBackgroundAccessoriesRetrieval.value === true || globals.isSyncing.value === true) {
        if (accessoriesResult !== undefined && accessoriesResult !== null) {
            doAccessoriesResolve(dfd, JSON.parse(JSON.stringify(accessoriesResult)));
        } else {
            var doingBackgroundAccessoriesRetrievalSubscriptionRef = watch(doingBackgroundAccessoriesRetrieval, function (newValue) {
                doingBackgroundAccessoriesRetrievalSubscriptionRef();
                if (newValue === false && globals.isSyncing.value === false) {
                    doGetAccessories(dfd);
                }
            });
            var globalsIsSyncingSubscriptionRef = watch(globals.isSyncing, function (newVal) {
                globalsIsSyncingSubscriptionRef();
                if (newVal === false && doingBackgroundAccessoriesRetrieval.value === false) {
                    doGetAccessories(dfd);
                }
            });
        }

    } else {
        if (accessoriesResult !== undefined && accessoriesResult !== null) {
            doAccessoriesResolve(dfd, JSON.parse(JSON.stringify(accessoriesResult)));
        } else {
            doGetAccessories(dfd);
        }

    }

    return dfd.promise();
}

function doGetAccessories(dfd, forceRemote) {
    var request = 'accessories/allaccessorystubs';
    if (typeof forceRemote !== 'boolean') {
        forceRemote = false;
    }

    handleDataRequest(function () { return localDL.getAccessoriesLocal(); }, request, OPERATION.GetAccessoryStubs, forceRemote)
        .then(function (data) {
            if (data.Accessories.length === globals.user.getNumberOfAccessoriesFromServer()) {
                //any custom pre-processing of data should be handled here
                doAccessoriesResolve(dfd, data);
            } else {
                doGetAccessories(dfd, true);
            }
    })
    .fail(function (errorMessage) {
        dfd.reject(errorMessage);
    });
}

function doAccessoriesResolve(dfd, theData) {
    dfd.resolve({ accessories: theData });
}

function getAccessory(accessoryId, accessoryType, source, accessLevel) {
    var dfd = $.Deferred();

    var request = 'accessories/accessorydetail?accessoryId=' + accessoryId + '&accessoryType=' + accessoryType + '&source=' + source + '&accessLevel=' + accessLevel;
    var forceOnline = false;
    if (accessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
        forceOnline = true;
    }


    handleDataRequest(function () { return localDL.getFromCache(OPERATION.GetAccessory, accessoryId + '_' + accessoryType + '_' + source); }, request, OPERATION.GetAccessory, forceOnline)
        .then(function (data) {
            decompressAnyGraphicFilesAndResolve(data.Accessory, function() { doResolve(data); });
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();

    function doResolve(data) {
        dfd.resolve(data);
    }
}

function decompressAnyGraphicFilesAndResolve(objectWithGraphics, resolveFunc) {
    //decompress any graphic files
    if(objectWithGraphics && utils.itemHasSideViewGraphic(objectWithGraphics)) {
        utils.translateGraphicToXmlDoc(objectWithGraphics.GraphicBlob || objectWithGraphics.Graphic).then(function (sideElevationXmlDoc) {
            objectWithGraphics.DecompressedGraphicBlob = sideElevationXmlDoc;
            if(utils.itemHasTopViewGraphic(objectWithGraphics)) {
                utils.translateGraphicToXmlDoc(objectWithGraphics.TopViewGraphicBlob).then(function (topElevationXmlDoc) {
                    objectWithGraphics.DecompressedTopViewGraphicBlob = topElevationXmlDoc;
                    resolveFunc();
                }).catch(function (){
                    resolveFunc();
                });
            }else {
                resolveFunc();
            }
        }).catch(function (){
            resolveFunc();
        });
    }else if(objectWithGraphics && utils.itemHasTopViewGraphic(objectWithGraphics)) {
        utils.translateGraphicToXmlDoc(objectWithGraphics.TopViewGraphicBlob).then(function (topElevationXmlDoc) {
            objectWithGraphics.DecompressedTopViewGraphicBlob = topElevationXmlDoc;
            resolveFunc();
        }).catch(function (){
            resolveFunc();
        });
    }else {
        resolveFunc();
    }
}

function decompressAnyGraphicFilesWithPromise(objectWithGraphics) {
    let dfd = $.Deferred()

    //decompress any graphic files
    if(objectWithGraphics && utils.itemHasSideViewGraphic(objectWithGraphics)) {
        utils.translateGraphicToXmlDoc(objectWithGraphics.GraphicBlob || objectWithGraphics.Graphic).then(function (sideElevationXmlDoc) {
            objectWithGraphics.DecompressedGraphicBlob = sideElevationXmlDoc;
            if(utils.itemHasTopViewGraphic(objectWithGraphics)) {
                utils.translateGraphicToXmlDoc(objectWithGraphics.TopViewGraphicBlob).then(function (topElevationXmlDoc) {
                    objectWithGraphics.DecompressedTopViewGraphicBlob = topElevationXmlDoc;
                    dfd.resolve();
                }).catch(function (){
                    dfd.resolve();
                });
            }else {
                dfd.resolve();
            }
        }).catch(function (){
            dfd.resolve();
        });
    }else if(objectWithGraphics && utils.itemHasTopViewGraphic(objectWithGraphics)) {
        utils.translateGraphicToXmlDoc(objectWithGraphics.TopViewGraphicBlob).then(function (topElevationXmlDoc) {
            objectWithGraphics.DecompressedTopViewGraphicBlob = topElevationXmlDoc;
            dfd.resolve();
        }).catch(function (){
            dfd.resolve();
        });
    }else {
        dfd.resolve();
    }

    return dfd.promise();
}

function getTrailerStubs() {
    var dfd = $.Deferred();

    if (doingBackgroundTrailersRetrieval.value === true || globals.isSyncing.value === true) {
        if (trailersResult !== undefined && trailersResult !== null) {
            doTrailersResolve(dfd, JSON.parse(JSON.stringify(trailersResult)));
        } else {
            var doingBackgroundTrailersRetrievalSubscriptionRef = watch(doingBackgroundTrailersRetrieval, function (newValue) {
                doingBackgroundTrailersRetrievalSubscriptionRef();
                if (newValue === false && globals.isSyncing.value === false) {
                    doGetTrailers(dfd);
                }
            });
            var globalsIsSyncingSubscriptionRef = watch(globals.isSyncing, function (newVal) {
                globalsIsSyncingSubscriptionRef();
                if (newVal === false && doingBackgroundTrailersRetrieval.value === false) {
                    doGetTrailers(dfd);
                }
            });
        }

    } else {
        if (trailersResult !== undefined && trailersResult !== null) {
            doTrailersResolve(dfd, JSON.parse(JSON.stringify(trailersResult)));
        } else {
            doGetTrailers(dfd);
        }

    }

    return dfd.promise();
}

function doGetTrailers(dfd, forceRemote) {
    var request = 'accessories/alltrailerstubs';
    if (typeof forceRemote !== 'boolean') {
        forceRemote = false;
    }

    handleDataRequest(function () { return localDL.getTrailersLocal(); }, request, OPERATION.GetTrailerStubs, forceRemote)
    .then(function (data) {
        if (data.Trailers.length === globals.user.getNumberOfTrailersFromServer()) {
            //any custom pre-processing of data should be handled here
            doTrailersResolve(dfd, data);
        } else {
            doGetTrailers(dfd, true);
        }
    })
    .fail(function (errorMessage) {
        dfd.reject(errorMessage);
    });
}

function doTrailersResolve(dfd, theData) {
    dfd.resolve({ trailers: theData });
}

function getTrailer(trailerId, source, accessLevel) {
    var dfd = $.Deferred();

    var request = 'accessories/trailerdetail?trailerId=' + trailerId + '&source=' + source + '&accessLevel=' + accessLevel;
    var forceOnline = false;
    if (accessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
        forceOnline = true;
    }

    handleDataRequest(function () { return localDL.getFromCache(OPERATION.GetTrailer, trailerId + '_' + config.ACCESSORY_TYPES.TRAILER + '_' + source); }, request, OPERATION.GetTrailer, forceOnline)
        .then(function (data) {
            if(globals.chassisHasAddedEquipment(data.Trailer)) {    
                let decompressionPromises = [];             
                decompressionPromises.push(decompressAnyGraphicFilesWithPromise(data.Trailer));
                Object.keys(data.Trailer.Accessories).forEach(function (key) {
                    if(data.Trailer.Accessories[key]) {
                        if(data.Trailer.Accessories[key].length && data.Trailer.Accessories[key].length > 0) {
                            data.Trailer.Accessories[key].forEach(function (trailerItem) {
                                decompressionPromises.push(decompressAnyGraphicFilesWithPromise(trailerItem));
                            });
                        }else {
                            decompressionPromises.push(decompressAnyGraphicFilesWithPromise(data.Trailer.Accessories[key]));
                        }
                    }
                    
                });        
                $.when.apply($, decompressionPromises)
                    .done(function () {
                        dfd.resolve(data);
                    })
                    .fail(function (error) {
                        dfd.reject(error);
                    });        
            }else {
                decompressAnyGraphicFilesAndResolve(data.Trailer, function() { dfd.resolve(data); });
            }
            
            // dfd.resolve(data);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function getPayloadStubs() {
    var dfd = $.Deferred();

    if (doingBackgroundPayloadsRetrieval.value === true || globals.isSyncing.value === true) {
        if (payloadsResult !== undefined && payloadsResult !== null) {
            doPayloadsResolve(dfd, JSON.parse(JSON.stringify(payloadsResult)));
        } else {
            var doingBackgroundPayloadsRetrievalSubscriptionRef = watch(doingBackgroundPayloadsRetrieval, function (newValue) {
                doingBackgroundPayloadsRetrievalSubscriptionRef();
                if (newValue === false && globals.isSyncing.value === false) {
                    doGetPayloads(dfd);
                }
            });
            var globalsIsSyncingSubscriptionRef = watch(globals.isSyncing, function (newVal) {
                globalsIsSyncingSubscriptionRef();
                if (newVal === false && doingBackgroundPayloadsRetrieval.value === false) {
                    doGetPayloads(dfd);
                }
            });
        }

    } else {
        if (payloadsResult !== undefined && payloadsResult !== null) {
            doPayloadsResolve(dfd, JSON.parse(JSON.stringify(payloadsResult)));
        } else {
            doGetPayloads(dfd);
        }

    }

    return dfd.promise();
}

function doGetPayloads(dfd, forceRemote) {
    var request = 'accessories/allpayloadstubs';
    if (typeof forceRemote !== 'boolean') {
        forceRemote = false;
    }

    handleDataRequest(function () { return localDL.getPayloadsLocal(); }, request, OPERATION.GetPayloadStubs, forceRemote)
    .then(function (data) {
        if (data.Payloads.length === globals.user.getNumberOfPayloadsFromServer()) {
            //any custom pre-processing of data should be handled here
            doPayloadsResolve(dfd, data);
        } else {
            doGetPayloads(dfd, true);
        }
    })
    .fail(function (errorMessage) {
        dfd.reject(errorMessage);
    });
}

function doPayloadsResolve(dfd, theData) {
    dfd.resolve({ payloads: theData });
}

function getPayload(payloadId, source, accessLevel) {
    var dfd = $.Deferred();

    var request = 'accessories/payloaddetail?payloadId=' + payloadId + '&source=' + source + '&accessLevel=' + accessLevel;
    var forceOnline = false;
    if (accessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY) {
        forceOnline = true;
    }

    handleDataRequest(function () { return localDL.getFromCache(OPERATION.GetPayload, payloadId + '_' + config.ACCESSORY_TYPES.PAYLOAD + '_' + source); }, request, OPERATION.GetPayload, forceOnline)
        .then(function (data) {
            decompressAnyGraphicFilesAndResolve(data.Payload, function() { dfd.resolve(data); });
            // dfd.resolve(data);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function getSharedOfferStubs(sharerUserId, sharedWithUserId) {
    var dfd = $.Deferred();

    if (doingBackgroundSharedOfferStubsRetrieval.value === true || globals.isSyncing.value === true) {
        if (canUseRawSharedOfferStubsData()) {
            handleRawSharedOfferStubsDataResolve();
        } else {
            var doingBackgroundSharedOfferStubsRetrievalSubscriptionRef = watch(doingBackgroundSharedOfferStubsRetrieval, function (newValue) {
                doingBackgroundSharedOfferStubsRetrievalSubscriptionRef();
                if (canUseRawSharedOfferStubsData()) {
                    handleRawSharedOfferStubsDataResolve();
                } else {
                    if (newValue === false && globals.isSyncing.value === false) {
                        doGetSharedOfferStubs(sharerUserId, sharedWithUserId, dfd);
                    }
                }
            });
            var globalsIsSyncingSubscriptionRef = watch(globals.isSyncing, function (newVal) {
                globalsIsSyncingSubscriptionRef();
                if (canUseRawSharedOfferStubsData()) {
                    handleRawSharedOfferStubsDataResolve();
                } else {
                    if (newVal === false && doingBackgroundSharedOfferStubsRetrieval.value === false) {
                        doGetSharedOfferStubs(sharerUserId, sharedWithUserId, dfd);
                    }
                }

            });
        }

    } else {
        if (canUseRawSharedOfferStubsData(true)) {
            handleRawSharedOfferStubsDataResolve();
        } else {
            doGetSharedOfferStubs(sharerUserId, sharedWithUserId, dfd);
        }

    }

    function canUseRawSharedOfferStubsData(doWizardCheck) {
        if (doWizardCheck !== undefined && doWizardCheck === true) {
            if (sharedOfferStubsResult !== undefined && sharedOfferStubsResult !== null) {
                return true;
            } else {
                return false;
            }
        } else {
            if (sharedOfferStubsResult !== undefined && sharedOfferStubsResult !== null) {
                return true;
            } else {
                return false;
            }
        }
    }
    function handleRawSharedOfferStubsDataResolve() {
        doSharedOfferStubsResolve(dfd, JSON.parse(JSON.stringify(sharedOfferStubsResult)));
        //selectionListResult = null;
    }


    return dfd.promise();
}

function doGetSharedOfferStubs(sharerUserId, sharedWithUserId, dfd) {
    
    var request = OPERATION.GetSharedOfferStubs.BaseUrl + '?sharerUserId=' + sharerUserId + '&sharedWithUserId=' + sharedWithUserId;
    var operation = OPERATION.GetSharedOfferStubs.Code;

    handleDataRequest(/*function () { return localDL.getSharedOfferStubsLocal(); }*/null, request, operation, true)
    .then(function (data) {
        doSharedOfferStubsResolve(dfd, data);
    })
    .fail(function (errorMessage) {
        dfd.reject(errorMessage);
    });
}

function doSharedOfferStubsResolve(dfd, theData) {
    dfd.resolve({ sharedOfferStubs: theData });
}

function getSharedOfferStub(offerId) {
    
    
    return localDL.getFromCache(OPERATION.GetSharedOfferStub, offerId);

}

function getVehicleSpecificationAdvantages(securityToken, vehicleId, vehicleDistributionOptionId, vehicleDistributionGroupId) {

    var dfd = $.Deferred();

    securityTokenProvided = securityToken;

    $.ajax(utils.setOptions('vehiclespecificationadvantages/vehicleadvantages?vehicleId=' + vehicleId + '&vehicleDistributionOptionId=' + vehicleDistributionOptionId + '&vehicleDistributionGroupId=' + vehicleDistributionGroupId, operationTypeGet, dataTypeJson, setQueryHeader, globals.cloudServicesVersion1))
        .then(requestSucceeded)
        .fail(requestFailed);

    // handle the ajax callback
    function requestSucceeded(data, textStatus, jqXHR) {

        //update the local security token with the one received from the data layer
        var securityToken, validUntil;
        securityToken = jqXHR.getResponseHeader('SecurityToken');
        validUntil = jqXHR.getResponseHeader('ValidUntil');
        security.setSecurityToken(securityToken, validUntil);

        //dfd.resolve({ vehicle: ko.mapping.fromJS(data) });
        dfd.resolve({ advantages: data });
    }

    function requestFailed(xhr, ajaxOptions, thrownError) {
        dfd.reject({ errorMessage: xhr.statusText });
    }

    return dfd.promise();

}

function getCountries() {

    var dfd = $.Deferred();

    $.ajax(utils.setOptions('countries/getall', operationTypeGet, dataTypeJson, setQueryHeader, globals.cloudServicesVersion1))
        .then(requestSucceeded)
        .fail(requestFailed);

    // handle the ajax callback
    function requestSucceeded(data, textStatus, jqXHR) {
        var countryList = [];
        data.forEach(function (countryData) {
            countryData.disable = false;
            countryList.push(countryData);
        });
        dfd.resolve(countryList);
    }

    function requestFailed(xhr, ajaxOptions, thrownError) {
        dfd.reject({ errorMessage: xhr.statusText });
    }

    return dfd.promise();

}

/**
 * Send a list of waypoints to Bing Maps Rest API and resolve with the results returned from Bing Maps
 * @param {string} requestString - Should be a list of the waypoints in the format 'wp.' + waypointNumber + '=' + waypoint.getLatitude() + ',' + waypoint.getLongitude()
 * @param {any} credentials - Should be the same as globals.mapCredentials
 */
function sendToRouteRequestBingMapsRestApi(requestString, credentials) {
    var dfd = $.Deferred(),
        urlToUse = config.BING_MAPS_REST_URLS.ROUTES + '?';

    urlToUse += requestString;
    urlToUse += '&ra=routePath';
    urlToUse += '&key=' + credentials;

    $.ajax({
        url: urlToUse,
        dataType: "jsonp",
        jsonp: "jsonp"
    })
        .then(requestSucceeded)
        .fail(requestFailed);

    // handle the ajax callback
    function requestSucceeded(data, textStatus, jqXHR) {
        if (data.errorDetails !== undefined) {
            var errorDetailsString = 'Error returned from Bing Maps REST API.';
            data.errorDetails.forEach(function (errorString) {
                if (errorDetailsString.charAt(errorDetailsString.length - 1) === '.') {
                    errorDetailsString += ' ';
                } else if (errorDetailsString.charAt(errorDetailsString.length - 1) !== ' ') {
                    errorDetailsString += '. ';
                }
                errorDetailsString += errorString;
            });
            dfd.reject({ errorMessage: errorDetailsString });
        } else {
            dfd.resolve(data);
        }
    }

    function requestFailed(xhr, ajaxOptions, thrownError) {
        dfd.reject({ errorMessage: xhr.statusText });
    }

    return dfd.promise();

}

function postSimulationResults(securityToken, simulationDetails) {

    var dfd = $.Deferred();

    securityTokenProvided = securityToken;

    $.ajax(utils.setPostOptions('performance/simulationresults', operationTypePost, 'json', simulationDetails, setQueryHeader, globals.cloudServicesVersion1, true, 120000))
        .then(requestSucceeded)
        .fail(requestFailed);

    // handle the ajax callback
    function requestSucceeded(data, textStatus, jqXHR) {

        //update the local security token with the one received from the data layer
        var securityToken, validUntil;
        securityToken = jqXHR.getResponseHeader('SecurityToken');
        validUntil = jqXHR.getResponseHeader('ValidUntil');
        security.setSecurityToken(securityToken, validUntil);

        dfd.resolve({ simulation: data });

    }

    function requestFailed(xhr, ajaxOptions, thrownError) {
        dfd.reject({ errorMessage: xhr.statusText });
    }

    return dfd.promise();

}





//function getLegislationDetails(securityToken) {

//    var dfd = $.Deferred();

//    securityTokenProvided = securityToken;
    
//    // make ajax call
//    //$.ajax(utils.setOptions('legislations/get', operationTypeGet, dataTypeJson, setQueryHeader, globals.cloudServicesVersion1))
//    $.ajax(utils.setOptions('legislations/get', operationTypeGet, dataTypeJson, setQueryHeader, globals.cloudServicesVersion1))
//        .then(requestSucceeded)
//        .fail(requestFailed);

//    // handle the ajax callback
//    function requestSucceeded(data, textStatus, jqXHR) {

//        //update the local security token with the one received from the data layer
//        var securityToken, validUntil;
//        securityToken = jqXHR.getResponseHeader('SecurityToken');
//        validUntil = jqXHR.getResponseHeader('ValidUntil');
//        security.setSecurityToken(securityToken, validUntil);

//        logger.log('Retrieved legislation details from data source', data.Description, system.getModuleId(dataManager), config.showDebugToast);
//        dfd.resolve({ legislation: ko.mapping.fromJS(data) });
//    }

//    function requestFailed(xhr, ajaxOptions, thrownError) {
//        dfd.reject({ errorMessage: xhr.statusText });
//    }

//    return dfd.promise();

//}

//function emailOffer(securityToken, offerId, toAddress, subject, body, attachmentName, attachmentContent, asyncTransaction) {

//    var dfd = $.Deferred();

//    securityTokenProvided = securityToken;

//    var emailObject = {
//        "ToAddress": toAddress,
//        "Subject": subject,
//        "Body": body,
//        "AttachmentName": attachmentName,
//        "AttachmentContent": attachmentContent
//    };

//    // make ajax call
//    $.ajax(utils.setPostOptions('offers/emailoffer?offerId=' + offerId, operationTypePost, 'json', emailObject, setQueryHeader, globals.cloudServicesVersion1, asyncTransaction, 30000))
//        .done(requestCompleted)
//        .fail(requestFailed);

//    // handle the ajax callback
//    function requestCompleted(data, textStatus, jqXHR) {

//        //update the local security token with the one received from the data layer
//        var securityToken, validUntil;
//        securityToken = jqXHR.getResponseHeader('SecurityToken');
//        validUntil = jqXHR.getResponseHeader('ValidUntil');
//        security.setSecurityToken(securityToken, validUntil);

//        dfd.resolve({ savedCustomer: data });

//    }

//    function requestFailed(xhr, ajaxOptions, thrownError) {
//        dfd.reject({ errorMessage: xhr.statusText });
//    }

//    return dfd.promise();

//}

/**
* Retrieves the short version of customers(i.e. just top level details, no actions, notes or offers).
* @method getCustomers
* @return {Object} Returns data array of short version customers.
*/
function getCustomers() {

    var dfd = $.Deferred();

    if (doingBackgroundOfferRetrieval.value === true || globals.isSyncing.value === true) {
        if (canUseRawCustomerData()) {
            handleRawCustomerDataResolve();
        } else {
            var doingBackgroundOfferRetrievalSubscriptionRef = watch(doingBackgroundOfferRetrieval, function (newValue) {
                doingBackgroundOfferRetrievalSubscriptionRef();
                if (canUseRawCustomerData()) {
                    handleRawCustomerDataResolve();
                } else {
                    if (newValue === false && globals.isSyncing.value === false) {
                        doGetCustomers(dfd);
                    }
                }
            });
            var globalsIsSyncingSubscriptionRef = watch(globals.isSyncing, function (newVal) {
                globalsIsSyncingSubscriptionRef();
                if (canUseRawCustomerData()) {
                    handleRawCustomerDataResolve();
                } else {
                    if (newVal === false && doingBackgroundOfferRetrieval.value === false) {
                        doGetCustomers(dfd);
                    }
                }
            });
        }
        
    } else {
        if (canUseRawCustomerData()) {
            handleRawCustomerDataResolve();
        } else {
            doGetCustomers(dfd);
        }
    }

    function canUseRawCustomerData() {
        if (customersResult !== undefined && customersResult !== null) {
            return true;
        } else {
            return false;
        }
    }

    function handleRawCustomerDataResolve() {
        var custData = { data: { Customers: customersResult } };
        dfd.resolve(custData)
        //customersResult = null;
    } 

    return dfd.promise();

}

function doGetCustomers(dfd) {

    var request = 'customers/get';
    var operation = OPERATION.GetCustomers;

    handleDataRequest(function () {
        return localDL.getCustomersLocal();
    }, request, operation, false)
        .then(function (data) {
            dfd.resolve({ data: data });
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });
}

/**
* Retrieves the data required to populate the offers screen namely a list of customers with actions/notes/offers for the currently selected user (either the logged in user or a selected subordinate),
* a list of one or more users/sales-people with the logged in user coming first and any subordinates thereafter, and also the userId to which the returned data pertains.
* @method getSalesPeople
* @param {Number} userId The id of the user/salesperson who's data is to be retrieved. Passing 0 results in the logged in user's data being retrieved as the server pulls the userId out of the securityToken
* @param {Boolean} loggedInUser Flag denoting if the userId relating to the data being requested is the logged in user.
* @return {Object} Returns data for offers screen including Customers(offers/notes/actions) and Users(logged in user and any subordinates).
*/
function getSalesPeople(userId, loggedInUser) {

    var dfd = $.Deferred();
    
    if (doingBackgroundOfferRetrieval.value === true || globals.isSyncing.value === true) {
        var doingBackgroundOfferRetrievalSubscriptionRef = watch(doingBackgroundOfferRetrieval, function (newValue) {
            doingBackgroundOfferRetrievalSubscriptionRef();
            if (newValue === false && globals.isSyncing.value === false) {
                doGetSalesPeople(userId, dfd, loggedInUser);
            }
        });
        var globalsIsSyncingSubscriptionRef = watch(globals.isSyncing, function (newVal) {
            globalsIsSyncingSubscriptionRef();
            if (newVal === false && doingBackgroundOfferRetrieval.value === false) {
                doGetSalesPeople(userId, dfd, loggedInUser);
            }
        });
    } else {
        doGetSalesPeople(userId, dfd, loggedInUser);
    }
    
    return dfd.promise();

}

function doGetSalesPeople(userId, dfd, loggedInUser) {

    var request = 'customers/sync';
    var operation;

    var SyncObject = {
        UserId: userId,
        Customers: null
    };

    if (loggedInUser === true) {

        operation = OPERATION.Sync;

        if (globals.isOnline.value === true) {
            localDL.getCustomersSyncList()
                .then(function (syncList) {
                if (syncList.syncList.length > 0) {
                    SyncObject.Customers = syncList.syncList;
                    }

                    handleDataRequest(null, request, operation, true, SyncObject)
                        .then(function (data) {
                            doLocalGetSalesPeople();
                        })
                        .fail(function (errorMessage) {
                            dfd.reject(errorMessage);
                        });
                });

        } else {
            doLocalGetSalesPeople();
        }
    } else {
        operation = OPERATION.GetSalesPersonInfo;
        handleDataRequest(null, request, operation, true, SyncObject)
            .then(function (data) {
                dfd.resolve({ salespeople: data });
            })
            .fail(function (errorMessage) {
                dfd.reject(errorMessage);
            });
    }

    

    function doLocalGetSalesPeople() {
        handleDataRequest(function () { return localDL.getSalesPeopleLocal(); }, request, operation, false, SyncObject)
        .then(function (data) {
            //any custom pre-processing of data should be handled here
            dfd.resolve({ salespeople: data });
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });
    }
    
}

function getNumVehicleOpensPropertyVal() {
    var dfd = $.Deferred();

    localDL.getFromCache(OPERATION.NumVehicleOpens, PROPERTY.NumVehicleOpens)
        .then(function (numVehOpens) {
            if (numVehOpens === undefined || numVehOpens === null) {
                dfd.resolve(0);
            } else {
                dfd.resolve(numVehOpens);
            }
        });

    return dfd.promise()
}

function incrementNumVehicleOpensProperty() {
    getNumVehicleOpensPropertyVal()
        .then(function (currentNumVehOpensVal) {
            if (currentNumVehOpensVal === undefined || currentNumVehOpensVal === null) {
                localDL.addToCache(OPERATION.NumVehicleOpens, PROPERTY.NumVehicleOpens, 1);
            } else {
                localDL.addToCache(OPERATION.NumVehicleOpens, PROPERTY.NumVehicleOpens, currentNumVehOpensVal+1);
            }
        });
}

function checkEmailVerificationStatusOnline() {
    return makeCallToPostVerificationStatusController(OPERATION.CheckEmailVerificationStatus, { Email: globals.user.getEmailAddress() });
}

function postVerificationCode(code) {
    return makeCallToPostVerificationStatusController(OPERATION.VerifyCode, { Email: globals.user.getEmailAddress(), VerificationCode: code });
}

function makeCallToPostVerificationStatusController(operation, data) {
    var dfd = $.Deferred();

    var request = 'verificationcode/verificationstatus';

    handleDataRequest(null, request, operation, true, data)
        .then(function (emailVerifiedYesOrNo) {
            dfd.resolve(emailVerifiedYesOrNo);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function resendVerificationEmail() {
    var dfd = $.Deferred();

    var request = 'verificationcode/resendverificationemail?emailAddress=' + globals.user.getEmailAddress() + '&cultureCode=' + globals.user.getCultureCode();

    handleDataRequest(null, request, OPERATION.ResendVerificationEmail, true)
        .then(function (result) {
            dfd.resolve(result);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function postTransferLicenceVerificationCode(code) {
    var dfd = $.Deferred();

    var request = OPERATION.VerifyTransferLicenceCode.BaseUrl;

    handleDataRequest(null, request, OPERATION.VerifyTransferLicenceCode.Code, true, { Email: globals.user.getEmailAddress(), VerificationCode: code })
        .then(function (transferLicenceCodeVerifiedYesOrNo) {
            dfd.resolve(transferLicenceCodeVerifiedYesOrNo);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function resendTransferLicenceVerificationEmail() {
    var dfd = $.Deferred();

    var request = OPERATION.ResendTransferLicenceVerificationEmail.BaseUrl + '?emailAddress=' + globals.user.getEmailAddress() + '&cultureCode=' + globals.user.getCultureCode();

    handleDataRequest(null, request, OPERATION.ResendTransferLicenceVerificationEmail.Code, true)
        .then(function (result) {
            dfd.resolve(result);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function postEmailOffer(emailDetails) {
    var dfd = $.Deferred();

    var request = 'offers/emailoffer';

    handleDataRequest(null, request, OPERATION.PostEmailOffer, true, emailDetails)
        .then(function (result) {
            dfd.resolve(result);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

/**
* Saves a new offer or updates an existing saved offer. Will reflect any changes locally and queue a new sync item if the app is offline or the online save fails. 
* The app will attempt to sync the item next time it goes online. 
* @method saveOffer
* @param {Number} customerId The id of the customer associated with the offer. Can be 0 if the customer is newly created when the offer is being saved.
* @param {Number} offerId The id of the saved offer. 0 if it's a newly saved offer.
* @param {Object} customerObject Payload containing the details the customer related to the offer the offer. Can be an existing customer or a newly created customer.
* @param {Object} offerObject Payload containing the customisable details of the given offer.
* @param {Object} attachmentObject Payoad containing the generated PDF of the offer to be emailed to the customer.
* @param {Boolean} sendEmail Flag indicating whether or not an email is to be sent to the customer.
* @param {Object} saveOfferFlags describing the context or save scenario
* @param {Object} shareOptions describing optional info for shared offers
* @return {Object} Returns data supplied in response to successful server call, or dummies the data response if offline. Response data includes relevant offer and customer Ids, respective update counters
* and also an array of any sent offers.
*/
function saveOffer(customerId, offerId, activeSalesPersonId, customerObject, offerObject, attachmentObject, sendEmail, saveOfferFlags, shareOptions, folderPath) {

    var dfd = $.Deferred();

    
    var timeStamp = Date.now();
    var operation = OPERATION.SaveOffer.Code;

    var offerDetailsObject;

    

    offerDetailsObject = {
        Customer: customerObject,
        Email: attachmentObject,
        Offer: offerObject,
        UserIdOfSavedOffer: activeSalesPersonId,
        NotifyShareesofOfferChanges: shareOptions.notifyShareesofOfferChanges,
        OptionalOfferChangesCommentForShareeEmail: shareOptions.optionalOfferChangesCommentForShareeEmail,
        FolderPath: folderPath
    };

    var SAVE_TYPE = {
        NEW: 'new',
        EXISTING: 'existing',
        OVERWRITE: 'overwrite'
    };

    var saveType, key, dataToGet;

    var tempCustId = 'custId_' + timeStamp;
    var tempOfferId = 'offerId_' + timeStamp;
    var offerIdToUse;

    if (saveOfferFlags !== undefined && saveOfferFlags[config.SAVE_OFFER_FLAGS.SAVE_AS_NEW] !== undefined && saveOfferFlags[config.SAVE_OFFER_FLAGS.SAVE_AS_NEW] === true) {
        saveType = SAVE_TYPE.NEW;
        key = offerObject.VehicleId;
        dataToGet = "new";
        offerIdToUse = tempOfferId;
    } if (saveOfferFlags !== undefined && saveOfferFlags[config.SAVE_OFFER_FLAGS.SAVE_AS_SHARE] !== undefined && saveOfferFlags[config.SAVE_OFFER_FLAGS.SAVE_AS_SHARE] === true) {
        saveType = SAVE_TYPE.NEW;
        key = offerObject.Id;
        dataToGet = "share";
        offerIdToUse = tempOfferId;
    } else if (saveOfferFlags !== undefined && saveOfferFlags[config.SAVE_OFFER_FLAGS.SAVE_AS_EXISTING] !== undefined && saveOfferFlags[config.SAVE_OFFER_FLAGS.SAVE_AS_EXISTING] === true) {
        saveType = SAVE_TYPE.NEW;
        key = offerObject.Id;
        offerObject.Id = 0;
        offerId = 0;
        dataToGet = "existing";
        offerIdToUse = tempOfferId;
    } else if (saveOfferFlags !== undefined && saveOfferFlags[config.SAVE_OFFER_FLAGS.SAVE_NEW_TSV] !== undefined && saveOfferFlags[config.SAVE_OFFER_FLAGS.SAVE_NEW_TSV] === true) {
        saveType = SAVE_TYPE.NEW;
        //key = offerObject.VehicleId;
        dataToGet = "none";
        offerIdToUse = tempOfferId;
    } else if (saveOfferFlags !== undefined && saveOfferFlags[config.SAVE_OFFER_FLAGS.SAVE_EXISTING_TSV] !== undefined && saveOfferFlags[config.SAVE_OFFER_FLAGS.SAVE_EXISTING_TSV] === true) {
        saveType = SAVE_TYPE.EXISTING;
        dataToGet = "none";
        offerIdToUse = offerId;
    } else if (saveOfferFlags !== undefined && saveOfferFlags[config.SAVE_OFFER_FLAGS.SAVE_AS_EXISTING_TSV] !== undefined && saveOfferFlags[config.SAVE_OFFER_FLAGS.SAVE_AS_EXISTING_TSV] === true) {
        saveType = SAVE_TYPE.NEW;
        dataToGet = "none";
        offerObject.Id = 0;
        offerId = 0;
        offerIdToUse = tempOfferId;

    }else if (offerObject.Id === 0) {
        saveType = SAVE_TYPE.NEW;
        key = offerObject.VehicleId;
        dataToGet = "new";
        offerIdToUse = tempOfferId;
    } else if (saveOfferFlags !== undefined && saveOfferFlags[config.SAVE_OFFER_FLAGS.RESENDING_TO_DIFFERENT_CUSTOMER] !== undefined && saveOfferFlags[config.SAVE_OFFER_FLAGS.RESENDING_TO_DIFFERENT_CUSTOMER] === true) {
        saveType = SAVE_TYPE.NEW;
        key = offerObject.Id;
        dataToGet = "existing";
        offerIdToUse = tempOfferId;
    } else if (saveOfferFlags !== undefined && saveOfferFlags[config.SAVE_OFFER_FLAGS.OVERWRITE] !== undefined && saveOfferFlags[config.SAVE_OFFER_FLAGS.OVERWRITE] === true) {
        saveType = SAVE_TYPE.OVERWRITE;
        if (offerObject.Id > 0) {
            key = offerObject.Id;
            dataToGet = "existing";
        } else {
            key = offerObject.VehicleId;
            dataToGet = "new";
        }
        
        offerIdToUse = offerId;
    } else {

        saveType = SAVE_TYPE.EXISTING;
        key = offerObject.Id;
        dataToGet = "existing";
        offerIdToUse = offerId;
    }
    if (dataToGet === 'none') {
        doOfflineSave(offerDetailsObject, offerDetailsObject, customerObject, null);
    } else {
        getDataForSave(key, dataToGet)
            .then(function (cachedData) {
                if (cachedData === null || cachedData === undefined) {
                    if (dataToGet === 'existing') {
                        key = offerObject.VehicleId;
                        dataToGet = 'new';
                    } else {
                        key = offerId;
                        dataToGet = 'existing';
                    }
                    getDataForSave(key, dataToGet)
                        .then(function (cachedData) {
                            doActionsAfterRetrievingCachedData(cachedData);
                        });
                } else {
                    doActionsAfterRetrievingCachedData(cachedData);
                }


            });
    }
    

    function doActionsAfterRetrievingCachedData(cachedData) {
        var offerSaveObject = {};
        var fullCustomerObj;

        var newSavedOfferStub;


        if (saveType === SAVE_TYPE.NEW) {
            newSavedOfferStub = getOfferStub(cachedData);
        }
        //delete offerObject.BodyManufacturer;
        //delete offerObject.BodyType;
        function getOfferStub(cachedD) {
            var stub = {};
            stub.BodyManufacturer = offerObject.BodyManufacturer;
            stub.BodyType = offerObject.BodyType;

            //if (cachedD.Offer.Id === undefined) {
            stub.Id = offerIdToUse;
            //} else {
            //    stub.Id = cachedD.Offer.Id;
            //}
            stub.UpdateCounter = 0;
            stub.OfferDate = "";
            stub.ReasonCode = "";
            stub.ReasonCodeDescription = "";
            stub.StatusCode = "INPROGRESS";
            stub.VehicleRange = offerObject.VehicleRange;
            stub.VehicleMake = offerObject.VehicleMake;
            stub.LegislationId = offerObject.LegislationId;
            stub.VehicleDescription = cachedD.Offer.VehicleDescription;
            stub.VehicleId = cachedD.Offer.VehicleId;
            stub.Description = offerObject.Description;
            stub.AdditionalDescription = offerObject.AdditionalDescription;
            stub.AxleLayout = offerObject.AxleLayout;
            stub.VehicleTypeCode = offerObject.VehicleTypeCode;
            stub.VehicleType = offerObject.VehicleType;
            stub.SpecDate = offerObject.SpecDate;
            stub.Source = offerObject.Source;
            stub.VehicleTypeTranslation = offerObject.VehicleTypeTranslation;
            stub.VehicleModelCode = offerObject.VehicleModelCode;
            stub.WheelbaseTheoretical = offerObject.WheelbaseTheoretical;
            stub.GCW = offerObject.GCM;
            stub.GVW = offerObject.GVM;
            stub.PreparedForName = offerObject.PreparedForName;
            stub.WheelbaseCFACRA = offerObject.WheelbaseCFACRA;
            stub.WheelbaseFMSACRA = offerObject.WheelbaseFMSACRA;
            stub.WheelbaseFMSACRDA = offerObject.WheelbaseFMSACRDA;
            stub.WheelbaseFMSAFRDA = offerObject.WheelbaseFMSAFRDA;
            if (cachedD.Complete) {
                stub.OfferCached = cachedD.Complete;
            } else {
                stub.OfferCached = 0;
            }
            return stub;
        }
        if (customerObject.Id === 0) {
            fullCustomerObj = JSON.parse(JSON.stringify(customerObject));
            fullCustomerObj.Id = tempCustId;
            fullCustomerObj.Offers = [];
            fullCustomerObj.Notes = [];
            fullCustomerObj.Actions = [];
            if (newSavedOfferStub.Id === 0) {
                newSavedOfferStub.Id = offerIdToUse;
            }
        }

        if (saveType === SAVE_TYPE.NEW || saveType === SAVE_TYPE.OVERWRITE) {
            offerSaveObject.CachedDate = Date.now();
            if (cachedData !== null && cachedData !== undefined && cachedData.Complete) {
                offerSaveObject.Complete = cachedData.Complete;
            } else {
                offerSaveObject.Complete = 0;
            }

            offerSaveObject.Customer = customerObject;
            offerSaveObject.Changes = offerObject;
            if (customerObject.Id === 0) {
                offerSaveObject.CustomerId = tempCustId;
                offerSaveObject.Customer.Id = tempCustId;
                fullCustomerObj.Id = tempCustId;
            } else {
                offerSaveObject.CustomerId = customerObject.Id;
            }

            offerSaveObject.OfferId = offerIdToUse;
            offerSaveObject.Changes.Id = offerIdToUse;
            offerSaveObject.VehicleId = offerObject.VehicleId;
            offerSaveObject.UpdateCounter = 0;
            offerSaveObject.Offer = cachedData.Offer;
            offerSaveObject.Specification = cachedData.Specification;
            offerSaveObject.Advantages = cachedData.Advantages;
        } else {
            cachedData.CachedDate = Date.now();
            cachedData.Customer = customerObject;
            cachedData.Changes = offerObject;
            if (customerObject.Id === 0) {
                cachedData.CustomerId = tempCustId;
                cachedData.Customer.Id = tempCustId;
            } else {
                cachedData.CustomerId = customerObject.Id;
            }
            offerSaveObject = cachedData;
        }

        if (offerSaveObject.Changes.VehicleId !== offerSaveObject.Offer.VehicleId) {
            localDL.getFromCache(OPERATION.GetNewOfferDetails, offerSaveObject.Changes.VehicleId)
                .then(function (data) {
                    offerSaveObject.Offer = data.Offer;
                    continueWithSave();
                });
        } else {
            continueWithSave();
        }

        function continueWithSave() {
            if (saveOfferFlags !== undefined && saveOfferFlags[config.SAVE_OFFER_FLAGS.RESENDING_TO_DIFFERENT_CUSTOMER] !== undefined && saveOfferFlags[config.SAVE_OFFER_FLAGS.RESENDING_TO_DIFFERENT_CUSTOMER] === true) {

                offerId = 0;
                offerObject.Id = 0;
            }
            //if (globals.isOnline.value === true) {
            //    saveOfferOnline(offerDetailsObject, offerSaveObject, fullCustomerObj, newSavedOfferStub);
            //} else {
            doOfflineSave(offerDetailsObject, offerSaveObject, fullCustomerObj, newSavedOfferStub);
        }
        //}
    }

    function getDataForSave(key, dataToGet) {
        var dataDfd = $.Deferred();

        if (dataToGet === "new") {
            localDL.getFromCache(OPERATION.GetNewOfferDetails, key)
                .then(function (data) {
                    dataDfd.resolve(data);
                });
        } else if (dataToGet === "share") {
            localDL.getFromCache(OPERATION.GetSharedOfferStub, key)
                .then(function (data) {
                    dataDfd.resolve(data);
                });
        } else {
            localDL.getFromCache(OPERATION.GetExistingOfferDetails, key)
                .then(function (data) {
                    dataDfd.resolve(data);
                });
        }

        return dataDfd.promise();
    }

    //function saveOfferOnline(offerDetailsObject, localSaveObject, customer, offerStub) {
    //    var request = 'offers/saveoffer?customerId=' + customerId + '&offerId=' + offerId;
        
    //    handleDataRequest(null, request, operation, true, offerDetailsObject)
    //        .then(function (data) {
    //            //localSaveObject.
    //            if (data.Result.ReturnCode === 1) {

    //                localSaveObject.OfferId = data.Offer.Id;
    //                localSaveObject.UpdateCounter = data.Offer.UpdateCounter;
    //                localSaveObject.OfferDate = data.Offer.OfferDate;
    //                localSaveObject.Changes.Id = data.Offer.Id;
    //                if (offerStub) {
    //                    offerStub.Id = data.Offer.Id;
    //                    offerStub.UpdateCounter = data.Offer.UpdateCounter;
    //                    offerStub.OfferDate = moment(data.Offer.OfferDate, ["M/D/YYYY", "DD-MM-YYYY", "MM-DD-YYYY", "DD/MM/YYYY", "YYYY-MM-DD", "YYYY/MM/DD"]).format('YYYY-MM-DDTHH:mm:ss:SSS');
    //                }
    //                localSaveObject.Changes.UpdateCounter = data.Offer.UpdateCounter;

    //                //if (typeof localSaveObject.CustomerId  === 'string') {
    //                //    localSaveObject.CustomerId = data.Customer.Id;
    //                //    localSaveObject.Customer.Id = data.Customer.Id;
    //                //    customer.Id = data.Customer.Id;
    //                //}
    //                localSaveObject.CustomerId = data.Customer.Id;
    //                localSaveObject.Customer.Id = data.Customer.Id;
                    
    //                localSaveObject.Customer.UpdateCounter = data.Customer.UpdateCounter;
    //                localSaveObject.Customer.OverallUpdateCounter = data.Customer.OverallUpdateCounter;
    //                if (customer) {
    //                    customer.UpdateCounter = data.Customer.UpdateCounter;
    //                    customer.OverallUpdateCounter = data.Customer.OverallUpdateCounter;
    //                    customer.Id = data.Customer.Id;
    //                }
    //                localSaveObject.SentOffers = data.SentOffers;
    //                localSaveObject.OfferDate = moment(data.Offer.OfferDate, ["M/D/YYYY", "DD-MM-YYYY", "MM-DD-YYYY", "DD/MM/YYYY", "YYYY-MM-DD", "YYYY/MM/DD"]).format('YYYY-MM-DDTHH:mm:ss:SSS');
    //                updateLocalCache(localSaveObject, customer, offerStub);
    //            }

                
    //            dfd.resolve({ savedOffer: data });
    //        })
    //        .fail(function (errorMessage) {
    //            doOfflineSave(offerDetailsObject, localSaveObject);
    //        });
    //}

    function doOfflineSave(offerDetailsObject, localSaveObject, customer, offerStub) {
        var offlineSaveDfd = $.Deferred();
        updateLocalCache(localSaveObject, customer, offerStub)
            .then(function () {
                var custId, offId;

                custId = localSaveObject.Customer.Id;
                offId = localSaveObject.OfferId;

                manageSyncQueue(offId, custId, "SAVE", OPERATION.SaveOffer)
                    .then(function () {
                        var params = { customerId: custId, offerId: offId };
                        queueSyncJob(OPERATION.SaveOffer, timeStamp, params, offerDetailsObject)
                            .then(function () {

                                if (globals.isOnline.value) {
                                    globals.offerSyncing.value = true;
                                    doSyncIfNeeded();
                                }
                                var offlineDataVersion = {
                                    Customer: { Id: localSaveObject.CustomerId, UpdateCounter: localSaveObject.Customer.UpdateCounter, OverallUpdateCounter: localSaveObject.Customer.OverallUpdateCounter },
                                    Offer: { Id: localSaveObject.OfferId, UpdateCounter: localSaveObject.UpdateCounter, OfferDate: localSaveObject.OfferDate, Description: offerDetailsObject.Offer.Description, AdditionalDescription: offerDetailsObject.Offer.AdditionalDescription },
                                    Result: { ReturnCode: 1 }
                                };
                                dfd.resolve({ savedOffer: offlineDataVersion });
                            });
                    });
            });
        return offlineSaveDfd.promise();
    }

    function updateLocalCache(localSaveObject, customer, offerStub) {
        var updateLocalCacheDfd = $.Deferred();

        //handleCustUpdate()
        //    .then(function(){
                localDL.addToCache(operation, localSaveObject.OfferId, localSaveObject)
                    .then(function () {
                        updateLocalCacheDfd.resolve();
                    });
            //});
        
        
        
        function handleCustUpdate() {
            var custDfd = $.Deferred();

            if (customer) {
                if (offerStub) {
                    customer.Offers.push(offerStub);
                }
                localDL.addToCache(OPERATION.SaveCustomer.Code, customer.Id, customer)
                    .then(function () {
                        custDfd.resolve();
                    });
            } else {
                updateLocallyCachedCustomer(localSaveObject, offerStub)
                    .then(function () {
                        custDfd.resolve();
                    });
            }

            return custDfd.promise();
        }

        return updateLocalCacheDfd.promise();
    }



    function updateLocallyCachedCustomer(localSaveObject, newSavedOfferStub) {
        var localCustDfd = $.Deferred();
        var customerId = localSaveObject.CustomerId, newUpdateCounter = localSaveObject.Customer.UpdateCounter, newOverallUpdateCounter = localSaveObject.Customer.OverallUpdateCounter;
        localDL.getFromCache(OPERATION.GetCustomer, customerId)
            .then(function (custData) {
                
                //if (newSavedOfferStub) {
                //    custData.Offers.push(newSavedOfferStub);
                //} else {
                //    custData.Offers.forEach(function (offer) {
                //        if (offer.Id === localSaveObject.Changes.Id) {
                //            offer.BodyManufacturer = localSaveObject.Changes.BodyManufacturer;
                //            offer.BodyType = localSaveObject.Changes.BodyType;
                //            offer.VehicleRange = localSaveObject.Changes.VehicleRange;
                //            offer.VehicleMake = localSaveObject.Changes.VehicleMake;
                //            offer.LegislationId = localSaveObject.Changes.LegislationId;
                //            offer.VehicleDescription = localSaveObject.Offer.VehicleDescription;
                //            offer.VehicleId = localSaveObject.Offer.VehicleId;
                //            offer.Description = localSaveObject.Changes.Description;
                //            offer.AdditionalDescription = localSaveObject.Changes.AdditionalDescription;
                //            offer.AxleLayout = localSaveObject.Changes.AxleLayout;
                //            offer.VehicleTypeCode = localSaveObject.Changes.VehicleTypeCode;
                //            offer.VehicleType = localSaveObject.Changes.VehicleType;
                //            offer.SpecDate = localSaveObject.Changes.SpecDate;
                //            offer.Source = localSaveObject.Changes.Source;
                //            offer.VehicleTypeTranslation = localSaveObject.Changes.VehicleTypeTranslation;
                //            offer.WheelbaseTheoretical = localSaveObject.Changes.WheelbaseTheoretical;
                //            offer.GCW = localSaveObject.Changes.GCM;
                //            offer.GVW = localSaveObject.Changes.GVM;
                //            offer.PreparedForName = localSaveObject.Changes.PreparedForName || "";
                //            offer.WheelbaseCFACRA = localSaveObject.Changes.WheelbaseCFACRA;
                //            offer.WheelbaseFMSACRA = localSaveObject.Changes.WheelbaseFMSACRA;
                //            offer.WheelbaseFMSACRDA = localSaveObject.Changes.WheelbaseFMSACRDA;
                //            offer.WheelbaseFMSAFRDA = localSaveObject.Changes.WheelbaseFMSAFRDA;
                //        }

                //    });
                //}
                custData.UpdateCounter = newUpdateCounter;
                custData.OverallUpdateCounter = newOverallUpdateCounter;
                custData.Mobile = customerObject.Mobile;
                custData.DirectNumber = customerObject.DirectNumber;
                custData.Email = customerObject.Email;
                custData.ContactName = customerObject.ContactName;
                custData.Company = customerObject.Company;
                localDL.addToCache(OPERATION.SaveCustomer.Code, customerId, custData)
                    .then(function () {
                        localDL.getAllCustomersOffers(customerId)
                            .then(function (custOffersData) {
                                delete custData.Notes;
                                delete custData.Offers;
                                delete custData.Actions;
                                if (custOffersData.length === 0) {
                                    localCustDfd.resolve();
                                } else {
                                    var savedOfferCustUpdateOps = [];
                                    for (var i = 0; i < custOffersData.length; i++) {
                                        custOffersData[i].CustomerId = customerId;

                                        custOffersData[i].Customer = custData;
                                        savedOfferCustUpdateOps.push(localDL.addToCache(OPERATION.SaveOffer.Code, custOffersData[i].OfferId, custOffersData[i]));
                                    }
                                    $.when.apply($, savedOfferCustUpdateOps)
                                        .done(function () {
                                            localCustDfd.resolve();
                                        });
                                }
                            });
                        
                    });
            });

        return localCustDfd.promise();
    }

    return dfd.promise();
}

function saveCustomAccessory(accessoryId, accessoryType, accessoryDetail, accessLevel, submitToPublicLibrary, publicLibraryEditLevel, submitToDistributorLibrary, distributorLibraryEditLevel) {
    var dfd = $.Deferred();
    var newSave, accessorySaveObject, accessoryObjectString;
    var timeStamp = Date.now();
    var tempAccessoryId = 'accessoryId_' + timeStamp;

    accessoryDetail.AccessLevel = accessLevel;
    
    accessoryDetail.Source = config.VEHICLE_SOURCE_TYPES.CUSTOM;
    accessoryDetail.AccessLevel = accessLevel;
    //accessoryDetail.SubmitToPublicLibrary = submitToPublicLibraryBoolean;

    accessorySaveObject = JSON.parse(JSON.stringify(accessoryDetail));
    accessoryDetail.Id = typeof accessoryId === 'string' ? 0 : accessoryId;
    if (accessoryDetail.SourceDatabaseId && typeof accessoryDetail.SourceDatabaseId === 'string') {
        accessoryDetail.SourceDatabaseId = 0;
    }

    accessoryObjectString = JSON.stringify(accessoryDetail);
    var remotePayloadObject = {
        AccessoryType: accessoryType,
        AccessoryDetail: accessoryObjectString,
        AccessLevel: accessLevel,
        ParentType: accessoryDetail.ParentType,
        ForceSave: false,
        SubmitToPublicLibrary: typeof submitToPublicLibrary === 'boolean' ? submitToPublicLibrary : false,
        PublicLibraryEditLevel: typeof publicLibraryEditLevel === 'string' ? publicLibraryEditLevel : config.ITEM_EDIT_LEVEL_OPTIONS.NO_EDIT,
        SubmitToDistributorLibrary: typeof submitToDistributorLibrary === 'boolean' ? submitToDistributorLibrary : false,
        DistributorLibraryEditLevel: typeof distributorLibraryEditLevel === 'string' ? distributorLibraryEditLevel : config.ITEM_EDIT_LEVEL_OPTIONS.NO_EDIT,
        ClientStructureVersionNumber: globals.getPaddedStructureVersionNumber()
    };
    if (accessoryId === 0) {
        accessorySaveObject.Id = tempAccessoryId;
        newSave = true;
    } else {
        newSave = false;
    }

    if (typeof accessoryId === 'string' && accessoryId.indexOf('_') !== -1) {
        if (newSave === false) {
            timeStamp = accessoryId.split('_')[1];
        }
        accessoryId = 0;

    }
    //if (globals.isOnline.value === true) {
    //    saveAccessoryOnline(testObject, accessorySaveObject);
    //} else {
    doOfflineSave(remotePayloadObject, accessorySaveObject);
    //}

    //function saveAccessoryOnline(accessoryObject, localSaveObject) {
    //    //var request = 'accessories/post?id=' + accessoryId;
    //    var request = 'accessories/post';

    //    handleDataRequest(null, request, OPERATION.SaveCustomAccessory.Code, true, accessoryObject)
    //        .then(function (data) {
    //            if (data.Result.ReturnCode === 1) {
    //                localSaveObject.Id = data.AccessoryId;
    //                localSaveObject.UpdateCounter = data.UpdateCounter;
    //                localSaveObject.Source = config.VEHICLE_SOURCE_TYPES.CUSTOM;
    //                data.Description = localSaveObject.Description;
    //                data.Source = config.VEHICLE_SOURCE_TYPES.CUSTOM;
    //                data.AccessoryType = accessoryObject.AccessoryType;
    //                updateLocalCache(localSaveObject, newSave, data.OverallCustomerUpdateCounter);
    //            }

    //            dfd.resolve({ savedAccessory: data, newSave: newSave });
    //        })
    //        .fail(function (errorMessage) {
    //            doOfflineSave(accessoryObject, localSaveObject);
    //        });
    //}

    function doOfflineSave(syncObjectDetails, localSaveObject) {
        if (localSaveObject.IsComplete === true) {
            localSaveObject.CompletionDate = moment().format('DD/MM/YYYY');
        } else {
            localSaveObject.CompletionDate = "01/01/1900";
        }
        updateLocalCache(localSaveObject, newSave)
            .then(function () {
                manageSyncQueue(localSaveObject.Id, undefined, "SAVE", OPERATION.SaveCustomAccessory)
                    .then(function () {
                        var params = { id: accessoryId };

                        queueSyncJob(OPERATION.SaveCustomAccessory, timeStamp, params, syncObjectDetails)
                            .then(function () {
                                if (globals.isOnline.value) {
                                    doSyncIfNeeded();
                                }
                                var offlineDataVersion = {
                                    AccessoryId: localSaveObject.Id,
                                    UpdateCounter: localSaveObject.UpdateCounter,
                                    Description: localSaveObject.Description,
                                    Source: config.VEHICLE_SOURCE_TYPES.CUSTOM,
                                    AccessoryType: localSaveObject.AccessoryType,
                                    AccessLevel: localSaveObject.AccessLevel,
                                    SubmitToPublicLibrary: localSaveObject.SubmitToPublicLibrary,
                                    PublicLibraryEditLevel: localSaveObject.PublicLibraryEditLevel,
                                    SubmitToDistributorLibrary: localSaveObject.SubmitToDistributorLibrary,
                                    DistributorLibraryEditLevel: localSaveObject.DistributorLibraryEditLevel,
                                    Type: localSaveObject.Type,
                                    Result: { ReturnCode: 1 }
                                };
                                dfd.resolve({ savedAccessory: offlineDataVersion, newSave: newSave });
                            });
                    });
            });
    }

    function updateLocalCache(accessory, isNewAccessory) {
        return updateLocallyCachedAccessory(accessory.Id, accessory, isNewAccessory);
    }

    function updateLocallyCachedAccessory(accessoryId, accessory, isNew, optionalNewId) {
        var updateLocallyCachedAccessoryDfd = $.Deferred();

        var op;
        if (accessory.AccessoryType === config.ACCESSORY_TYPES.BODY) {
            op = OPERATION.SaveBody;
        } else if (accessory.AccessoryType === config.ACCESSORY_TYPES.TRAILER) {
            op = OPERATION.SaveTrailer;
        } else if (accessory.AccessoryType === config.ACCESSORY_TYPES.PAYLOAD) {
            op = OPERATION.SavePayload;
        } else {
            op = OPERATION.SaveAccessory;
            //localDL.addToCache(OPERATION.SaveAccessory, accessory.Id + '_' + accessory.AccessoryType + '_' + accessory.Source, accessory);
        }
        localDL.addToCache(op, accessory.Id + '_' + accessory.AccessoryType + '_' + accessory.Source, accessory)
                .then(function () {
                    updateLocallyCachedAccessoryDfd.resolve();
                });
        return updateLocallyCachedAccessoryDfd.promise();
    }

    return dfd.promise();
}

/**
     * Deletes a custom accessory. Will reflect any changes locally and queue a new sync item if the app is offline or the online save fails.
     * The app will attempt to sync the item next time it goes online. 
     * @method deleteCustomAccessory
     * @param {Number} id Id of the Custom Accessory to be deleted.
     * @param {Number} updateCounter Update counter of the Custom Accessory to be deleted.
     * @param {String} accessoryType .
     * @return {Object} Returns data supplied in response to successful server call, or dummies the data response if offline. Data response is a ReturnCode indicating success or failure of delete operation. 
     */
function deleteCustomAccessory(id, description, updateCounter, accessoryType, accessorySource, accessLevel) {

    var dfd = $.Deferred();

    var timeStamp = Date.now();
    //if (globals.isOnline.value === true) {
    //    deleteCustomAccessoryOnline(id, updateCounter, accessoryType, accessorySource);
    //} else {
        doOfflineDelete(id, description, updateCounter, accessoryType, accessorySource, accessLevel);
    //}

    //function deleteCustomAccessoryOnline(id, updateCounter, accessoryType, accessorySource) {
    //    var request = 'accessories/delete?id=' + id + '&accessoryType=' + accessoryType;
    //    handleDataRequest(null, request, OPERATION.DeleteCustomAccessory.Code, true)
    //        .then(function (data) {
    //            if (data.ReturnCode === 1) {
    //                updateCacheLocally(id, accessoryType, accessorySource);
    //            }
    //            dfd.resolve({ deletedCustomAccessory: data });
    //        })
    //        .fail(function () {
    //            doOfflineDelete(id, updateCounter, accessoryType, accessorySource);
    //        });
    //}

    function updateCacheLocally(id, accessoryType, accessorySource, accessLevel) {
        var updateCacheLocallyDfd = $.Deferred();

        if (accessoryType === config.ACCESSORY_TYPES.BODY) {
            localDL.deleteRecord(localDL.STORE.Bodies, id + '_' + accessoryType + '_' + accessorySource)
                .then(function () {
                    updateCacheLocallyDfd.resolve();
                });
        } else if (accessoryType === config.ACCESSORY_TYPES.TRAILER) {
            localDL.deleteRecord(localDL.STORE.Trailers, id + '_' + accessoryType + '_' + accessorySource)
                .then(function () {
                    updateCacheLocallyDfd.resolve();
                });
        } else if (accessoryType === config.ACCESSORY_TYPES.PAYLOAD) {
            localDL.deleteRecord(localDL.STORE.Payloads, id + '_' + accessoryType + '_' + accessorySource)
                .then(function () {
                    updateCacheLocallyDfd.resolve();
                });
        } else {
            localDL.deleteRecord(localDL.STORE.Accessories, id + '_' + accessoryType + '_' + accessorySource)
                .then(function () {
                    updateCacheLocallyDfd.resolve();
                });
        }
        //updateLocallyCachedOffersEquipmentSetSourceDatabaseIdToZero(id, accessoryType);
        return updateCacheLocallyDfd.promise();
    }

    function doOfflineDelete(id, description, updateCounter, accessoryType, accessorySource, accessLevel) {

        var offlineDataVersion = {
            ReturnCode: 1
        };

        updateCacheLocally(id, accessoryType, accessorySource, accessLevel)
            .then(function () {
                manageSyncQueue(id, accessoryType, "DELETE", OPERATION.DeleteCustomAccessory)
                    .then(function (abortAdd) {
                        if (abortAdd === false) {
                            var params = { id: id, accessoryType: accessoryType, accessLevel: accessLevel };
                            queueSyncJob(OPERATION.DeleteCustomAccessory, timeStamp, params, {Description : description})
                                .then(function () {
                                    if (globals.isOnline.value) {
                                        doSyncIfNeeded();
                                    }
                                    dfd.resolve({ deletedCustomAccessory: offlineDataVersion });
                                });
                        } else {
                            if (globals.isOnline.value) {
                                doSyncIfNeeded();
                            }
                            dfd.resolve({ deletedCustomAccessory: offlineDataVersion });
                        }
                    });
            });

        
    }

    return dfd.promise();
}

function updateLocallyCachedOffersEquipmentSetSourceDatabaseIdToZero(idOfDeletedCustomAccessory, accessoryType) {
    getCustomers()
        .then(function (success) {
            var savedOffers = [];
            success.data.Customers.forEach(function (customer) {
                localDL.getAllCustomersOffers(customer.Id)
                    .then(function (offers) {
                        offers.forEach(function (offer) {
                            var offerChanged = false;
                            if (accessoryType === config.ACCESSORY_TYPES.TRAILER) {
                                if (offer.Changes.Trailers && offer.Changes.Trailers.length > 0) {
                                    offer.Changes.Trailers.forEach(function (trailer) {
                                        if (trailer.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
                                            if (trailer.SourceDatabaseId === idOfDeletedCustomAccessory) {
                                                trailer.SourceDatabaseId = 0;
                                                offerChanged = true;
                                            }
                                        }
                                    })
                                }
                            } else if (accessoryType === config.ACCESSORY_TYPES.PAYLOAD) {
                                if (offer.Changes.Payloads && offer.Changes.Payloads.length > 0) {
                                    offer.Changes.Payloads.forEach(function (payload) {
                                        if (payload.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
                                            if (payload.SourceDatabaseId === idOfDeletedCustomAccessory) {
                                                payload.SourceDatabaseId = 0;
                                                offerChanged = true;
                                            }
                                        }
                                    })
                                }
                            } else {
                                Object.keys(offer.Changes.Accessories).forEach(function (key) {
                                    offer.Changes.Accessories[key].forEach(function (accessory) {
                                        if (accessory.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM && accessory.AccessoryType === accessoryType) {
                                            if (accessory.SourceDatabaseId === idOfDeletedCustomAccessory) {
                                                accessory.SourceDatabaseId = 0;
                                                offerChanged = true;
                                            }
                                        }
                                    })
                                });
                            }
                            
                            if (offerChanged === true) {
                                localDL.addToCache(OPERATION.SaveOffer.Code, offer.Changes.Id, offer);
                            }
                        });

                    });
            });
        });
}



/**
* Saves a new customer or updates an existing one. Will reflect any changes locally and queue a new sync item if the app is offline or the online save fails. 
* The app will attempt to sync the item next time it goes online. 
* @method saveCustomerDetailsOnOffers
* @param {Object} customerDetails Payload containing the details the customer to be saved. Can be an existing customer or a newly created customer.
* @return {Object} Returns data supplied in response to successful server call, or dummies the data response if offline. Data response includes customerId and update counters.
*/
function saveCustomerDetailsOnOffers(customerDetails) {

    var dfd = $.Deferred();

    var newSave, customerSaveObject;
    var timeStamp = Date.now();
    var tempCustId = 'custId_' + timeStamp;
    

    var customerObject = {
        "Id": customerDetails.id(),
        "ContactName": customerDetails.tempContactName(),
        "Company": customerDetails.tempCompany(),
        "Mobile": customerDetails.tempMobile(),
        "DirectNumber": customerDetails.tempDirectNumber(),
        "Email": customerDetails.tempEmail(),
        "UpdateCounter": customerDetails.updateCounter(),
        "OverallUpdateCounter": customerDetails.overallUpdateCounter()
    };
    customerSaveObject = JSON.parse(JSON.stringify(customerObject));
    if (customerDetails.id() === 0) {
        customerSaveObject.Id = tempCustId;
        newSave = true;
    } else {
        newSave = false;
    }

    //if (globals.isOnline.value === true) {
    //    saveCustomerOnline(customerObject, customerSaveObject);
    //} else {
        doOfflineSave(customerObject, customerSaveObject);
    //}

    //function saveCustomerOnline(customerObject, localSaveObject) {
    //    var request = 'customers/post?id=' + customerObject.Id;

    //    handleDataRequest(null, request, OPERATION.SaveCustomer.Code, true, customerObject)
    //        .then(function (data) {
    //            if (data.Result.ReturnCode === 1) {
    //                localSaveObject.Id = data.Customer.Id;
    //                localSaveObject.UpdateCounter = data.Customer.UpdateCounter;
    //                localSaveObject.OverallUpdateCounter = data.Customer.OverallUpdateCounter;
    //                updateLocalCache(localSaveObject, newSave);
    //            }

    //            dfd.resolve({ savedCustomer: data });
    //        })
    //        .fail(function (errorMessage) {
    //            doOfflineSave(customerObject, localSaveObject);
    //        });
    //}

    function doOfflineSave(customerDetailsObject, localSaveObject) {
        updateLocalCache(localSaveObject, newSave)
            .then(function () {
                manageSyncQueue(localSaveObject.Id, 0, "SAVE", OPERATION.SaveCustomer)
                    .then(function () {
                        var params = { id: customerDetailsObject.Id };
                        queueSyncJob(OPERATION.SaveCustomer, timeStamp, params, customerDetailsObject)
                            .then(function () {
                                if (globals.isOnline.value) {
                                    doSyncIfNeeded();
                                }
                                var offlineDataVersion = {
                                    Customer: { Id: localSaveObject.Id, UpdateCounter: localSaveObject.UpdateCounter, OverallUpdateCounter: localSaveObject.OverallUpdateCounter },
                                    Result: { ReturnCode: 1 }
                                };
                                dfd.resolve({ savedCustomer: offlineDataVersion });
                            });
                    });

                        
            });
        
        
    }

    function updateLocalCache(customer, isNewCustomer, optNewId) {
        var updateLocalCacheDfd = $.Deferred();

        if (isNewCustomer === true) {
            localDL.addToCache(OPERATION.SaveCustomer.Code, customer.Id, customer)
                .then(function () {
                    updateLocalCacheDfd.resolve();
                });
        } else {
            updateLocallyCachedCustomer(customer.Id, customer)
                .then(function () {
                    updateLocalCacheDfd.resolve();
                });
        }

        return updateLocalCacheDfd.promise();
    }



    function updateLocallyCachedCustomer(customerId, customer, optionalNewId) {
        var updateLocallyCachedCustomerDfd = $.Deferred();

        localDL.getFromCache(OPERATION.GetCustomer, customerId)
            .then(function (custData) {
                if (custData === null) {
                    localDL.addToCache(OPERATION.SaveCustomer.Code, customerId, customer)
                        .then(function () {
                            updateLocallyCachedCustomerDfd.resolve();
                        });
                } else {
                    var idToSaveUnder = customerId;
                    custData.UpdateCounter = customer.UpdateCounter;
                    custData.OverallUpdateCounter = customer.OverallUpdateCounter;
                    if (optionalNewId) {
                        custData.Id = optionalNewId;
                        idToSaveUnder = optionalNewId
                    }
                    
                    custData.ContactName = customer.ContactName;
                    custData.Company = customer.Company;
                    custData.DirectNumber = customer.DirectNumber;
                    custData.Mobile = customer.Mobile;
                    custData.Email = customer.Email;

                    localDL.addToCache(OPERATION.SaveCustomer.Code, idToSaveUnder, custData)
                        .then(function () {
                            if (custData.Offers !== undefined && custData.Offers.length > 0) {
                                localDL.getAllCustomersOffers(customerId)
                                    .then(function (custOffersData) {
                                        delete custData.Notes;
                                        delete custData.Offers;
                                        delete custData.Actions;
                                        if (custOffersData.length === 0) {
                                            updateLocallyCachedCustomerDfd.resolve();
                                        } else {

                                            var savedOfferCustUpdateOps = [];

                                            for (var i = 0; i < custOffersData.length; i++) {
                                                custOffersData[i].CustomerId = idToSaveUnder;

                                                custOffersData[i].Customer = custData;
                                                savedOfferCustUpdateOps.push(localDL.addToCache(OPERATION.SaveOffer.Code, custOffersData[i].OfferId, custOffersData[i])
                                                    .then(function () {
                                                        if (optionalNewId) {
                                                            localDL.deleteRecord(localDL.STORE.Customers, customerId)
                                                                .then(function () {
                                                                    
                                                                });
                                                        } 
                                                    }));
                                                $.when.apply($, savedOfferCustUpdateOps)
                                                    .done(function () {
                                                        updateLocallyCachedCustomerDfd.resolve();
                                                    })
                                            }
                                        }
                                        
                                        
                                    });
                            } else {
                                updateLocallyCachedCustomerDfd.resolve();
                            }
                            
                        });
                }
            });

        return updateLocallyCachedCustomerDfd.promise();
    }
    
    return dfd.promise();
}

/**
* Saves/updates offer status. Will reflect any changes locally and queue a new sync item if the app is offline or the online save fails. Note that locally the status info is stored on the offer stub in the customers
* offers array. The app will attempt to sync the item next time it goes online. 
* @method updateOfferStatus
* @param {Object} offerDetails Payload containing the offer status details to be updated.
* @return {Object} Returns data supplied in response to successful server call, or dummies the data response if offline. Data response is a ReturnCode indicating success or failure.
*/
function updateOfferStatus(offerDetails) {

    var dfd = $.Deferred();
    var timeStamp = Date.now();
    

    var offerObject = {
        "StatusCode": offerDetails.tempStatusCode(),
        "ReasonCode": offerDetails.tempReasonCode(),
        "ReasonCodeDescription": offerDetails.tempReasonCodeDescription(),
        "UpdateCounter": offerDetails.updateCounter(),
        "Description": offerDetails.description()
    };
    
    

    //if (globals.isOnline.value === true) {
    //    saveOfferStatusOnline(offerObject, offerDetails.id(), offerDetails.customerId());
    //} else {
        doOfflineSave(offerObject, offerDetails.id(), offerDetails.customerId());
    //}

    //function saveOfferStatusOnline(offerObject, offerId, customerId) {
    //    var request = 'offers/offerstatus?id=' + offerId + '&customerId=' + customerId;

    //    handleDataRequest(null, request, OPERATION.UpdateOfferStatus.Code, true, offerObject)
    //        .then(function (data) {
    //            if (data.Result.ReturnCode === 1) {
    //                offerObject.UpdateCounter += 1;
    //                updateLocalCache(offerObject, offerId, customerId, data.OverallCustomerUpdateCounter);
    //            }
                
    //            dfd.resolve({ savedCustomer: data });
    //        })
    //        .fail(function (errorMessage) {
    //            doOfflineSave(offerObject, offerId, customerId);
    //        });
    //}

    function doOfflineSave(offerObject, offerId, customerId) {
        updateLocalCache(offerObject, offerId, customerId)
            .then(function () {
                manageSyncQueue(offerId, customerId, "SAVE", OPERATION.UpdateOfferStatus)
                    .then(function () {
                        var params = { id: offerId, customerId: customerId };
                        queueSyncJob(OPERATION.UpdateOfferStatus, timeStamp, params, offerObject)
                            .then(function () {
                                if (globals.isOnline.value) {
                                    doSyncIfNeeded();
                                }
                                var offlineDataVersion = {
                                    Result: { ReturnCode: 1 }
                                };
                                dfd.resolve({ offerStatusResponse: offlineDataVersion });
                            });
                        //var params = { id: customerDetailsObject.Id };
                        //queueSyncJob(OPERATION.SaveCustomer, timeStamp, params, customerDetailsObject);
                    });


                
            });

        
    }

    function updateLocalCache(updateOfferObject, offerId, customerId, overallCustUpdateCounter) {
        return updateLocallyCachedOfferStub(updateOfferObject, offerId, customerId, overallCustUpdateCounter);
    }



    function updateLocallyCachedOfferStub(updateOfferObject, offerId, customerId, overallCustUpdateCounter) {
        var updateLocallyCachedOfferStubDfd = $.Deferred();

        localDL.getFromCache(OPERATION.GetCustomer, customerId)
            .then(function (custData) {
                if (custData !== null) {

                    if (overallCustUpdateCounter) {
                        custData.OverallUpdateCounter = overallCustUpdateCounter;
                    }

                    for (var i = 0; i < custData.Offers.length; i++) {
                        if (custData.Offers[i].Id === offerId) {
                            custData.Offers[i].StatusCode = updateOfferObject.StatusCode;
                            custData.Offers[i].ReasonCode = updateOfferObject.ReasonCode;
                            custData.Offers[i].ReasonCodeDescription = updateOfferObject.ReasonCodeDescription;
                            custData.Offers[i].UpdateCounter = updateOfferObject.UpdateCounter;
                            break;
                        }
                    }
                    
                    localDL.addToCache(OPERATION.SaveCustomer.Code, customerId, custData)
                        .then(function () {
                            updateLocallyCachedOfferStubDfd.resolve();
                        });
                }
            });

        return updateLocallyCachedOfferStubDfd.promise();
    }

    return dfd.promise();
}

/**
* Saves/updates a cusotomer note. Will reflect any changes locally and queue a new sync item if the app is offline or the online save fails.
* The app will attempt to sync the item next time it goes online. 
* @method saveCustomerNote
* @param {Number} customerId Id of the customer against which the note is being saved.
* @param {Number} noteId Id of the note to be saved. 0 in the case of a new note, an existing note id in the case of an update, and a string if updating a note that was created offline.
* @param {String} newNoteDescriptionText The test of the note to be saved/upadted.
* @param {Number} updateCounter Update counter of the note being saved/updated.
* @param {Number} overallUpdateCounter An overall update counter on the parent customer that indicates when the object tree under the customer has changed in any way. 
* @return {Object} Returns data supplied in response to successful server call, or dummies the data response if offline. Data response includes the notes id, update counter and creation date and 
* also the overall update counter for the parent cutomer.
*/
function saveCustomerNote(customerId, noteId, newNoteDescriptionText, updateCounter, overallUpdateCounter) {

    var dfd = $.Deferred();

    var newSave, noteSaveObject;
    var timeStamp = Date.now();
    var tempCreationDate = moment().format('YYYY-MM-DDTHH:mm:ss:SSS');
    var tempNoteId = 'noteId_' + timeStamp;
    

    var noteObject = {
        "Id": noteId,
        "Description": newNoteDescriptionText,
        "CustomerId": customerId,
        "UpdateCounter": updateCounter
    };
    noteSaveObject = JSON.parse(JSON.stringify(noteObject));
    if (noteId === 0) {
        noteSaveObject.Id = tempNoteId;
        noteSaveObject.CreationDate = tempCreationDate;
        newSave = true;
    } else {
        newSave = false;
    }

    //if (globals.isOnline.value === true) {
    //    saveNoteOnline(noteObject, noteSaveObject);
    //} else {
        doOfflineSave(noteObject, noteSaveObject);
    //}

    //function saveNoteOnline(noteObject, localSaveObject) {
    //    var request = 'notes/post?Id=' + noteObject.Id;

    //    handleDataRequest(null, request, OPERATION.SaveCustomerNote.Code, true, noteObject)
    //        .then(function (data) {
    //            if (data.Result.ReturnCode === 1) {
    //                localSaveObject.Id = data.NoteId;
    //                localSaveObject.UpdateCounter = data.UpdateCounter;
    //                localSaveObject.CreationDate = moment(data.CreationDate, ["M/D/YYYY", "DD-MM-YYYY", "MM-DD-YYYY", "DD/MM/YYYY", "YYYY-MM-DD", "YYYY/MM/DD"]).format('YYYY-MM-DDTHH:mm:ss:SSS');
    //                updateLocalCache(localSaveObject, newSave, data.OverallCustomerUpdateCounter);
    //                data.CreationDate = localSaveObject.CreationDate;
    //            }

    //            dfd.resolve({ savedNote: data });
    //        })
    //        .fail(function (errorMessage) {
    //            doOfflineSave(noteObject, localSaveObject);
    //        });
    //}

    function doOfflineSave(noteDetailsObject, localSaveObject) {
        updateLocalCache(localSaveObject, newSave)
            .then(function () {
                manageSyncQueue(localSaveObject.Id, localSaveObject.CustomerId, "SAVE", OPERATION.SaveCustomerNote)
                    .then(function () {
                        var params = { id: noteId, customerId: localSaveObject.CustomerId };
                        queueSyncJob(OPERATION.SaveCustomerNote, timeStamp, params, noteDetailsObject)
                            .then(function () {
                                if (globals.isOnline.value) {
                                    doSyncIfNeeded();
                                }
                                var offlineDataVersion = {

                                    OverallUpdateCounter: overallUpdateCounter,
                                    NoteId: localSaveObject.Id,
                                    UpdateCounter: localSaveObject.UpdateCounter,
                                    CreationDate: localSaveObject.CreationDate,
                                    CustomerId: localSaveObject.CustomerId,
                                    Result: { ReturnCode: 1 }

                                };
                                dfd.resolve({ savedNote: offlineDataVersion });
                            });
                    });
            });
    }

    function updateLocalCache(note, isNewNote, overallCustUpdateCounter) {
        return updateLocallyCachedNote(note.Id, note, isNewNote, overallCustUpdateCounter);
    }

    function updateLocallyCachedNote(noteId, note, isNew, overallCustUpdateCounter, optionalNewId) {

        var updateLocallyCachedNoteDfd = $.Deferred();

        localDL.getFromCache(OPERATION.GetCustomer, note.CustomerId)
            .then(function (custData) {
                if (custData !== null) {
                    if (overallCustUpdateCounter) {
                        custData.OverallUpdateCounter = overallCustUpdateCounter;
                    }
                    
                    if (isNew) {
                        custData.Notes.push(note);
                    } else {
                        for(var i = 0; i < custData.Notes.length; i++) {
                            if (custData.Notes[i].Id === noteId) {
                                note.CreationDate = custData.Notes[i].CreationDate;
                                custData.Notes[i] = note;
                                if(optionalNewId) {
                                    custData.Notes[i].Id = optionalNewId;
                                }
                                break;
                            }
                        }
                    }
                    localDL.addToCache(OPERATION.SaveCustomer.Code, custData.Id, custData)
                        .then(function () {
                            updateLocallyCachedNoteDfd.resolve();
                        });
                } else {
                    updateLocallyCachedNoteDfd.resolve();
                }
            });

        return updateLocallyCachedNoteDfd.promise();
    }


    return dfd.promise();
}


/**
 * Saves/updates a user's settings. Will reflect any changes locally and queue a new sync item if the app is offline or the online save fails.
 * The app will attempt to sync the item next time it goes online. 
 * @method saveUserSettings
 * @param {Number} legislationId Id of the legislation against which the action is being saved.
 * @param {Number} measurementSystemId Id of the action to be saved. 0 in the case of a new action, an existing action id in the case of an update, and a string if updating an action that was created offline.
 * @param {Number} lengthIncrement The date the action is due by.
 * @param {Number} massIncrement The action text.
 * @param {Number} percentageIncrement A flag showing if the action has been completed or not.
 * @param {boolean} colourDrawings Indicates if user would like to see colour in the drawings on configuration page
 * @param {boolean} showComplianceScorecard Indicates if the user would like to see the compliance dashboard on configuration page
 * @param {string} specifyWheelbaseAs What type of wheelbase that will be used in calculation
 * @param {Array} integrations Array of integrations (NTEA) that the user has with TruckScience
 * @param {string} specifyCabDimensionsAs Indicates if the user can change BBC/AC in menu
 * @param {string} specifyChassisLengthAs Indicates if the user can change Wheelbase/CA in menu
 * @param {string} specifyFuelConsumptionAs Indicates whether the fuel consumption is expressed as l/100km or km/l
 * @param {string} specifyLicencingRegionAs Indicates default licencing region
 * @param {string} specifyAxleRatingAs Indicates whether to display the SIMPLIFIED (USA) or DETAILED (ROW)
 * @param {string} defaultReportPdfPageSize Sets the page size of the PDF on summary when opening an offer
 * @param {string?} reportLogo Image to be displayed in the upper left corner of the report
 * @param {object} dashboardConfiguration Object containing data points, internal standards and legislations
 * @param {string} companyAdditionalNote String containing the company disclaimer/additional notes
 * @param {string} specifyBodyPositionAs String containing value from config.SPECIFY_POSITION_AS_OPTIONS enum
 * @param {string} specifyEquipmentPositionAs String containing value from config.SPECIFY_POSITION_AS_OPTIONS enum
 * @param {boolean} showTips Indicates if the user would like to see legislation tips
 * @param {string} reportViewLayout String containing value from config.REPORT_VIEW_LAYOUT_OPTIONS enum
 * @param {Array} reportViews Array of objects containing report view data
 * @return {Object} Returns data supplied in response to successful server call, or dummies the data response if offline. Data response includes the action's id, update counter, completion date and 
 * due date and also the overall update counter for the parent cutomer.
 */
function saveUserSettings(legislationId, measurementSystemId, lengthIncrement, massIncrement, percentageIncrement, colourDrawings, showComplianceScorecard, specifyWheelbaseAs, integrations, specifyCabDimensionsAs, specifyChassisLengthAs, specifyFuelConsumptionAs, specifyLicencingRegionAs, specifyAxleRatingAs, defaultReportPdfPageSize, reportLogo, dashboardConfiguration, companyAdditionalNote, specifyBodyPositionAs, specifyEquipmentPositionAs, showTips, reportViewLayout, reportViews) {

    var dfd = $.Deferred();
    var timeStamp = Date.now(),
        base64Position,
        reportLogoToSave = '';

    if(reportLogo !== undefined && reportLogo !== null && typeof reportLogo === 'string') {
        if (reportLogo.indexOf('base64,') > -1) {
            base64Position = reportLogo.indexOf('base64,') + 7;
            reportLogoToSave = reportLogo.slice(base64Position);
        }
    } else {
        reportLogoToSave = '';
    }
    

    var userSettingsObject = {
        "DefaultLegislationId": legislationId,
        "DefaultMeasurementSystemId": measurementSystemId,
        "DefaultLengthIncrement": lengthIncrement,
        "DefaultMassIncrement": massIncrement,
        "DefaultPercentageIncrement": percentageIncrement,
        "ColourDrawings": colourDrawings,
        "ShowComplianceScorecard": showComplianceScorecard,
        "DefaultSpecifyWheelbaseAs": specifyWheelbaseAs,
        "DefaultSpecifyCabDimensionsAs": specifyCabDimensionsAs,
        "DefaultSpecifyChassisLengthAs": specifyChassisLengthAs,
        "DefaultSpecifyFuelConsumptionAs": specifyFuelConsumptionAs,
        "DefaultSpecifyLicencingRegionAs": specifyLicencingRegionAs,
        "DefaultSpecifyAxleRatingAs": specifyAxleRatingAs,
        "DefaultReportPdfPageSize": defaultReportPdfPageSize,
        "ReportLogoToSave": reportLogoToSave,
        "AdditionalNote": companyAdditionalNote,
        "DashboardConfiguration": dashboardConfiguration,
        "Integrations": integrations,
        "SpecifyBodyPositionAs": specifyBodyPositionAs,
        "SpecifyEquipmentPositionAs": specifyEquipmentPositionAs,
        "ShowTips": showTips,
        "ReportViewLayout": reportViewLayout,
        "ReportViews": reportViews
    };

    var userSettingsLocalSaveObject = JSON.parse(JSON.stringify(userSettingsObject));
    doOfflineSave(userSettingsObject, userSettingsLocalSaveObject);
    

    function doOfflineSave(userSettingsObject, localSaveObject) {
        
        manageSyncQueue(0, 0, "SAVE", OPERATION.SaveUserSettings)
            .then(function () {      
                queueSyncJob(OPERATION.SaveUserSettings, timeStamp, {}, userSettingsObject)
                    .then(function () {
                        if (globals.isOnline.value) {
                            doSyncIfNeeded();
                        }
                        var offlineDataVersion = {
                            Result: {
                                ReturnCode: 1
                            }
                        };
                        dfd.resolve({ data: offlineDataVersion });
                    });
            });

            
    }

    function updateLocalCache(userSettings) {
        var updateLocalCacheDfd = $.Deferred();

        localDL.addToCache(OPERATION.SaveUserSettings.Code, PROPERTY.UserSettings, userSettings)
            .then(function () {
                updateLocalCacheDfd.resolve();
            });

        return updateLocalCacheDfd.promise();
    }

    return dfd.promise();
}

/**
 * Saves/updates an action relating to a given customer. Will reflect any changes locally and queue a new sync item if the app is offline or the online save fails.
 * The app will attempt to sync the item next time it goes online. 
 * @method saveCustomerAction
 * @param {Number} customerId Id of the customer against which the action is being saved.
 * @param {Number} actionId Id of the action to be saved. 0 in the case of a new action, an existing action id in the case of an update, and a string if updating an action that was created offline.
 * @param {String} dueDate The date the action is due by.
 * @param {String} actionDescriptionText The action text.
 * @param {Boolean} isCompleted A flag showing if the action has been completed or not.
 * @param {Number} updateCounter Update counter of the action being saved/updated.
 * @param {Number} overallUpdateCounter An overall update counter on the parent customer that indicates when the object tree under the customer has changed in any way.
 * @return {Object} Returns data supplied in response to successful server call, or dummies the data response if offline. Data response includes the action's id, update counter, completion date and 
 * due date and also the overall update counter for the parent cutomer.
 */
function saveCustomerAction(customerId, actionId, dueDate, actionDescriptionText, isCompleted, updateCounter, overallUpdateCounter) {

    var dfd = $.Deferred();

    
    var newSave, actionSaveObject;
    var timeStamp = Date.now();
    var tempActionId = 'actionId_' + timeStamp;
    

    var actionObject = {
        "Id": actionId,
        "Description": actionDescriptionText,
        "DueDate": dueDate,
        "IsComplete": isCompleted,
        "CustomerId": customerId,
        "UpdateCounter": updateCounter
    };

    actionSaveObject = JSON.parse(JSON.stringify(actionObject));
    if (actionId === 0) {
        actionSaveObject.Id = tempActionId;
        newSave = true;
    } else {
        newSave = false;
        
    }

    //if (globals.isOnline.value === true) {
    //    saveActionOnline(actionObject, actionSaveObject);
    //} else {
        doOfflineSave(actionObject, actionSaveObject);
    //}

    //function saveActionOnline(actionObject, localSaveObject) {
    //    var request = 'actions/post?Id=' + actionId;

    //    handleDataRequest(null, request, OPERATION.SaveCustomerAction.Code, true, actionObject)
    //        .then(function (data) {
    //            if (data.Result.ReturnCode === 1) {
    //                localSaveObject.Id = data.ActionId;
    //                localSaveObject.UpdateCounter = data.UpdateCounter;
    //                localSaveObject.CompletionDate = data.CompletionDate;
    //                localSaveObject.DueDate = data.DueDate;

    //                updateLocalCache(localSaveObject, newSave, data.OverallCustomerUpdateCounter);
    //            }

    //            dfd.resolve({ savedAction: data });
    //        })
    //        .fail(function (errorMessage) {
    //            doOfflineSave(actionObject, localSaveObject);
    //        });
    //}

    function doOfflineSave(actionDetailsObject, localSaveObject) {
        if (actionSaveObject.IsComplete === true) {
            actionSaveObject.CompletionDate = moment().format('DD/MM/YYYY');
        } else {
            actionSaveObject.CompletionDate = "01/01/1900";
        }
        updateLocalCache(localSaveObject, newSave)
            .then(function () {
                manageSyncQueue(localSaveObject.Id, localSaveObject.CustomerId, "SAVE", OPERATION.SaveCustomerAction)
                    .then(function () {
                        var params = { id: actionId, customerId: localSaveObject.CustomerId };
                        queueSyncJob(OPERATION.SaveCustomerAction, timeStamp, params, actionDetailsObject)
                            .then(function () {
                                if (globals.isOnline.value) {
                                    doSyncIfNeeded();
                                }
                                var offlineDataVersion = {
                                    ActionId: localSaveObject.Id,
                                    UpdateCounter: localSaveObject.UpdateCounter,
                                    OverallUpdateCounter: overallUpdateCounter,
                                    DueDate: dueDate,
                                    CompletionDate: localSaveObject.CompletionDate,
                                    Result: { ReturnCode: 1 }
                                };
                                dfd.resolve({ savedAction: offlineDataVersion });
                            });
                    });
            });
    }

    function updateLocalCache(action, isNewAction, overallCustUpdateCounter) {
        if (isNewAction) {
            action.IsSystemGenerated = false;
            action.CreationDate = moment().format('DD/MM/YYYY');
        }
        return updateLocallyCachedAction(action.Id, action, isNewAction, overallCustUpdateCounter);
    }



    function updateLocallyCachedAction(actionId, action, isNew, overallCustUpdateCounter, optionalNewId) {
        var updateLocallyCachedActionDfd = $.Deferred();

        localDL.getFromCache(OPERATION.GetCustomer, action.CustomerId)
            .then(function (custData) {
                if (custData !== null) {

                    if (overallCustUpdateCounter) {
                        custData.OverallUpdateCounter = overallCustUpdateCounter;
                    }

                    if (isNew) {
                        custData.Actions.push(action);
                    } else {
                        for (var i = 0; i < custData.Actions.length; i++) {
                            if (custData.Actions[i].Id === actionId) {
                                action.IsSystemGenerated = custData.Actions[i].IsSystemGenerated;
                                action.CompletionDate = custData.Actions[i].CompletionDate;
                                action.CreationDate = custData.Actions[i].CreationDate;
                                
                                custData.Actions[i] = action;
                                if (optionalNewId) {
                                    custData.Actions[i].Id = optionalNewId;
                                }
                                break;
                            }
                        }
                    }
                    localDL.addToCache(OPERATION.SaveCustomer.Code, custData.Id, custData)
                        .then(function () {
                            updateLocallyCachedActionDfd.resolve();
                        });
                } else {
                    updateLocallyCachedActionDfd.resolve();
                }
            });

        return updateLocallyCachedActionDfd.promise();
    }

    return dfd.promise();
}

function changePassword(oldPassword, newPassword) {
    var dfd = $.Deferred();

    var timeStamp = Date.now();
    var tempChangePasswordId = 'changePasswordId_' + timeStamp;

    

    //securityTokenValue = securityToken;

    var passwordObject = {
        "OldPassword": oldPassword,
        "NewPassword": newPassword
    }
    let request = OPERATION.ChangePassword.BaseUrl
    if (globals.isOnline.value) {
        // queueSyncJob(OPERATION.ChangePassword, timeStamp, {}, passwordObject)
        //     .then(function () {

        //         doSyncIfNeeded();

        //         var changePasswordResponse = {
        //             ReturnCode: 1,
        //             Message:''
        //         };
        //         dfd.resolve({ chgPwd: changePasswordResponse });
        //     });
            handleDataRequest(undefined, request, OPERATION.ChangePassword.Code, true, passwordObject)
                .then(function (data) {
                    // if (data.ReturnCode === 1) {
                    //     // globals.user.updateUser({ password: curSyncItem.Details.NewPassword });
                    //     var changePasswordResponse = {
                    //             ReturnCode: 1,
                    //             Message:''
                    //         };
                    //     dfd.resolve({ chgPwd: changePasswordResponse });
                    // } else {
                    //     handleSyncItemFailure(curSyncItem.Operation.Code, itemDescriptionText, data.ReturnCode, data.Message);
                    // }
                    dfd.resolve(data);
                })
                .fail(function (errorMessage) {
                    dfd.reject(errorMessage);
                });
    } else {
        dfd.reject({ errorMessage: 'offline' });
    }
    

    return dfd.promise();
}

    /**
     * Deletes a given customer. Will reflect any changes locally and queue a new sync item if the app is offline or the online save fails.
     * The app will attempt to sync the item next time it goes online. 
     * @method deleteCustomer
     * @param {Number} customerId Id of the customer to be deleted.
     * @param {Number} updateCounter Update counter of the customer to be deleted.
     * @return {Object} Returns data supplied in response to successful server call, or dummies the data response if offline. Data response is a ReturnCode indicating success or failure of delete operation. 
     */
function deleteCustomer(customerId, updateCounter, contactName) {

    var dfd = $.Deferred();
    var timeStamp = Date.now();
    //if (globals.isOnline.value === true) {
    //    deleteCustomerOnline(customerId, updateCounter);
    //} else {
        doOfflineDelete(customerId, updateCounter, contactName);
    //}

    //function deleteCustomerOnline(customerId, updateCounter) {
    //    var request = 'customers/delete?id=' + customerId + '&updateCounter=' + updateCounter;
    //    handleDataRequest(null, request, OPERATION.DeleteCustomer.Code, true)
    //        .then(function (data) {
    //            if (data.ReturnCode === 1) {
    //                updateCacheLocally(customerId);
    //            }
    //            dfd.resolve({ deletedCustomer: data });
    //        })
    //        .fail(function () {
    //            doOfflineDelete(customerId, updateCounter);
    //        });
    //}

    function updateCacheLocally(customerId) {
        return localDL.deleteCustomer(customerId);
    }

    function doOfflineDelete(customerId, updateCounter, contactName) {

        var offlineDataVersion = {
            ReturnCode: 1
        };

        updateCacheLocally(customerId)
            .then(function () {
                manageSyncQueue(customerId, 0, "DELETE", OPERATION.DeleteCustomer)
                    .then(function (abortAdd) {
                        if (abortAdd === false) {
                            var params = { id: customerId, updateCounter: updateCounter };
                            queueSyncJob(OPERATION.DeleteCustomer, timeStamp, params, {Description: contactName})
                                .then(function () {
                                    if (globals.isOnline.value) {
                                        doSyncIfNeeded();
                                    }
                                    dfd.resolve({ deletedCustomer: offlineDataVersion });
                                });
                        } else {
                            if (globals.isOnline.value) {
                                doSyncIfNeeded();
                            }
                            dfd.resolve({ deletedCustomer: offlineDataVersion });
                        }
                    });
            });

        
    }
    
    return dfd.promise();
}

/**
     * Deletes a given customer note. Will reflect any changes locally and queue a new sync item if the app is offline or the online save fails.
     * The app will attempt to sync the item next time it goes online. 
     * @method deleteCustomerNote
     * @param {Number} customerNoteId Id of the note to be deleted.
     * @param {Number} updateCounter Update counter of the note to be deleted.
     * @param {Number} customerId Id of the parent customer object of the note to be deleted.
     * @return {Object} Returns data supplied in response to successful server call, or dummies the data response if offline. Data response is a ReturnCode indicating success or failure of delete operation. 
     */
function deleteCustomerNote(customerNoteId, updateCounter, customerId) {

    var dfd = $.Deferred();

    var timeStamp = Date.now();
    //if (globals.isOnline.value === true) {
    //    deleteCustomerNoteOnline(customerNoteId, updateCounter, customerId);
    //} else {
        doOfflineDelete(customerNoteId, updateCounter, customerId);
    //}

    //function deleteCustomerNoteOnline(customerNoteId, updateCounter, customerId) {
    //    var request = 'notes/delete?id=' + customerNoteId + '&updateCounter=' + updateCounter + '&customerId=' + customerId;
    //    handleDataRequest(null, request, OPERATION.DeleteCustomerNote.Code, true)
    //        .then(function (data) {
    //            if (data.ReturnCode === 1) {
    //                updateCacheLocally(customerNoteId, customerId);
    //            }
    //            dfd.resolve({ deletedCustomerNote: data });
    //        })
    //        .fail(function () {
    //            doOfflineDelete(customerNoteId, customerId);
    //        });
    //}

    function updateCacheLocally(customerNoteId, customerId) {
        var updateCacheLocallyDfd = $.Deferred();

        localDL.getFromCache(OPERATION.GetCustomer, customerId)
            .then(function (custData) {
                if (custData != null) {
                    for (var i = 0; i < custData.Notes.length; i++) {
                        if (custData.Notes[i].Id === customerNoteId) {
                            custData.Notes.splice(i, 1);
                            break;
                        }
                    }
                    localDL.addToCache(OPERATION.SaveCustomer.Code, customerId, custData)
                        .then(function () {
                            updateCacheLocallyDfd.resolve();
                        });
                } else {
                    updateCacheLocallyDfd.resolve();
                }
            });

        return updateCacheLocallyDfd.promise();
    }

    function doOfflineDelete(customerNoteId, updateCounter, customerId) {

        var offlineDataVersion = {
            ReturnCode: 1
        };

        updateCacheLocally(customerNoteId, customerId)
            .then(function () {
                manageSyncQueue(customerNoteId, customerId, "DELETE", OPERATION.DeleteCustomerNote)
                    .then(function (abortAdd) {
                        if (abortAdd === false) {
                            var params = { id: customerNoteId, updateCounter: updateCounter, customerId: customerId };
                            queueSyncJob(OPERATION.DeleteCustomerNote, timeStamp, params)
                                .then(function () {
                                    if (globals.isOnline.value) {
                                        doSyncIfNeeded();
                                    }
                                    dfd.resolve({ deletedCustomerNote: offlineDataVersion });
                                });
                        } else {
                            if (globals.isOnline.value) {
                                doSyncIfNeeded();
                            }
                            dfd.resolve({ deletedCustomerNote: offlineDataVersion });
                        }
                    });


                
                
            });

        
    }

    return dfd.promise();
}

/**
     * Deletes a given customer action. Will reflect any changes locally and queue a new sync item if the app is offline or the online save fails.
     * The app will attempt to sync the item next time it goes online. 
     * @method deleteCustomerAction
     * @param {Number} customerActionId Id of the action to be deleted.
     * @param {Number} updateCounter Update counter of the action to be deleted.
     * @param {Number} customerId Id of the parent customer object of the action to be deleted.
     * @param {Number} asyncTransaction Update counter of the customer to be deleted.
     * @return {Object} Returns data supplied in response to successful server call, or dummies the data response if offline. Data response is a ReturnCode indicating success or failure of delete operation. 
     */
function deleteCustomerAction(customerActionId, updateCounter, customerId) {

    var dfd = $.Deferred();
    var timeStamp = Date.now();

    //if (globals.isOnline.value === true) {
    //    deleteCustomerActionOnline(customerActionId, updateCounter, customerId);
    //} else {
        doOfflineDelete(customerActionId, updateCounter, customerId);
    //}

    //function deleteCustomerActionOnline(customerActionId, updateCounter, customerId) {
    //    var request = 'actions/delete?id=' + customerActionId + '&updateCounter=' + updateCounter + '&customerId=' + customerId;
    //    handleDataRequest(null, request, OPERATION.DeleteCustomerAction.Code, true)
    //        .then(function (data) {
    //            if (data.ReturnCode === 1) {
    //                updateCacheLocally(customerActionId, customerId);
    //            }
    //            dfd.resolve({ deletedCustomerAction: data });
    //        })
    //        .fail(function () {
    //            doOfflineDelete(customerActionId, customerId);
    //        });
    //}

    function updateCacheLocally(customerActionId, customerId) {
        var updateCacheLocallyDfd = $.Deferred();

        localDL.getFromCache(OPERATION.GetCustomer, customerId)
            .then(function (custData) {
                if (custData != null) {
                    for (var i = 0; i < custData.Actions.length; i++) {
                        if (custData.Actions[i].Id === customerActionId) {
                            custData.Actions.splice(i, 1);
                            break;
                        }
                    }
                    localDL.addToCache(OPERATION.SaveCustomer.Code, customerId, custData)
                        .then(function () {
                            updateCacheLocallyDfd.resolve();
                        });
                } else {
                    updateCacheLocallyDfd.resolve();
                }
            });

        return updateCacheLocallyDfd.promise();
    }

    function doOfflineDelete(customerActionId, updateCounter, customerId) {

        var offlineDataVersion = {
            ReturnCode: 1
        };

        updateCacheLocally(customerActionId, customerId)
            .then(function () {
                manageSyncQueue(customerActionId, customerId, "DELETE", OPERATION.DeleteCustomerAction)
                    .then(function (abortAdd) {
                        if (abortAdd === false) {
                            var params = { id: customerActionId, updateCounter: updateCounter, customerId: customerId };
                            queueSyncJob(OPERATION.DeleteCustomerAction, timeStamp, params)
                                .then(function () {
                                    if (globals.isOnline.value) {
                                        doSyncIfNeeded();
                                    }
                                    dfd.resolve({ deletedCustomerAction: offlineDataVersion });
                                });
                        } else {
                            if (globals.isOnline.value) {
                                doSyncIfNeeded();
                            }
                            dfd.resolve({ deletedCustomerAction: offlineDataVersion });
                        }
                    });
            });
    }

    return dfd.promise();
}

/**
     * Deletes a given customer offer. Will reflect any changes locally and queue a new sync item if the app is offline or the online save fails.
     * The app will attempt to sync the item next time it goes online. 
     * @method deleteOffer
     * @param {Number} offerId Id of the offer to be deleted.
     * @param {Number} updateCounter Update counter of the offer to be deleted.
     * @param {Number} customerId Id of the parent customer object of the offer to be deleted.
     * @return {Object} Returns data supplied in response to successful server call, or dummies the data response if offline. Data response is a ReturnCode indicating success or failure of delete operation. 
     */
function deleteOffer(offerId, updateCounter, customerId, description) {

    var dfd = $.Deferred();

    var timeStamp = Date.now();
    //if (globals.isOnline.value === true) {
    //    deleteOfferOnline(offerId, customerId, updateCounter);
    //} else {
        doOfflineDelete(offerId, customerId, updateCounter, description);
    //}

    //function deleteOfferOnline(offerId, customerId, updateCounter) {
    //    var request = 'offers/delete?id=' + offerId + '&updateCounter=' + updateCounter + '&customerId=' + customerId;
    //    handleDataRequest(null, request, OPERATION.DeleteOffer.Code, true)
    //        .then(function (data) {
    //            if (data.ReturnCode === 1) {
    //                updateCacheLocally(offerId, customerId);
    //            }
    //            dfd.resolve({ deletedOffer: data });
    //        })
    //        .fail(function () {
    //            doOfflineDelete(offerId, customerId, updateCounter);
    //        });
    //}

    function updateCacheLocally(offerId, customerId) {
        var updateCacheLocallyDfd = $.Deferred();

        localDL.deleteRecord(STORE.SavedOffers, offerId)
            .then(function () {
                localDL.getFromCache(OPERATION.GetCustomer, customerId)
                    .then(function (custData) {
                        for (var i = 0; i < custData.Offers.length; i++) {
                            if (custData.Offers[i].Id === offerId) {
                                custData.Offers.splice(i, 1);
                                break;
                            }
                        }
                        localDL.addToCache(OPERATION.SaveCustomer.Code, customerId, custData)
                            .then(function () {
                                updateCacheLocallyDfd.resolve();
                            });
                    });
            });

        return updateCacheLocallyDfd.promise();
    }

    function doOfflineDelete(offerId, customerId, updateCounter, description) {

        var offlineDataVersion = {
            ReturnCode: 1
        };

        updateCacheLocally(offerId, customerId)
            .then(function () {
                manageSyncQueue(offerId, customerId, "DELETE", OPERATION.DeleteOffer)
                    .then(function (abortAdd) {
                        if (abortAdd === false) {
                            var params = { id: offerId, updateCounter: updateCounter, customerId: customerId };
                            queueSyncJob(OPERATION.DeleteOffer, timeStamp, params, {Description: description})
                                .then(function () {
                                    if (globals.isOnline.value) {
                                        doSyncIfNeeded();
                                    }
                                    dfd.resolve({ deletedOffer: offlineDataVersion });
                                });
                        } else {
                            if (globals.isOnline.value) {
                                doSyncIfNeeded();
                            }
                            dfd.resolve({ deletedOffer: offlineDataVersion });
                        }
                    });      
            });

        
    }

    return dfd.promise();
}



function getLoginPageInformation(cultureCode) {

    var dfd = $.Deferred();

    //securityTokenProvided = securityToken;
    cultureCodeProvided = cultureCode;

    // make ajax call
    $.ajax(utils.setOptions('language/unauthenticatedtranslations?cultureCode=' + cultureCode, operationTypeGet, dataTypeJson, setUnAuthenticatedQueryHeader, globals.cloudServicesVersion1, true))
        .then(requestSucceeded)
        .fail(requestFailed);

    // handle the ajax callback
    function requestSucceeded(data, textStatus, jqXHR) {

        //update the local security token with the one received from the data layer
        var securityToken, validUntil;
        securityToken = jqXHR.getResponseHeader('SecurityToken');
        validUntil = jqXHR.getResponseHeader('ValidUntil');
        security.setSecurityToken(securityToken, validUntil);

        //dfd.resolve({ legislation: ko.mapping.fromJS(data) });
        dfd.resolve({messages : data });

    }

    function requestFailed(xhr, ajaxOptions, thrownError) {
        dfd.reject({ errorMessage: xhr.statusText });
    }

    return dfd.promise();
}





// /**
//      * Retrieves onRoadCosts. onRoadCosts are cached during startup and then updated by the version control system as and when required, so this method effectively always retrieves onRoadCosts locally,
//      * however if local retrieval fails for some reason it will then attempt to get them online
//      * @method getOnRoadCosts
//      * @param {Number} custDistGroupId Id of the customer distribution group the user belongs to. Passing the CDG Id enables the server to determine which onRaodCostsDistributionGroup 
//      * the user belongs to and therefore which onRoadCosts data to return.
//      * @return {Object} Returns data array of onRoadCosts. 
//      */
// function getOnRoadCosts(custDistGroupId) {
//     var dfd = $.Deferred();

//     var request = 'onroadcosts/get?customerDistributionGroupId=' + custDistGroupId;
//     var operation = OPERATION.GetOnRoadCosts;
//     handleDataRequest(function () { return localDL.getFromCache(operation, PROPERTY.OnRoadCosts); }, request, operation, false)
//         .then(function (data) {
//             //any custom pre-processing of data should be handled here
//             var onRoadCosts = data.onRoadCosts,
//                 onRoadCostsCosts = [],
//                 onRoadCostsDiscounts = [],
//                 onRoadCostsVersion = data.onRoadCostsVersion;
//             for (var i = 0; i < onRoadCosts.length; i++) {
//                 if (onRoadCosts[i].Type === 'DISCOUNT') {
//                     onRoadCostsDiscounts.push(onRoadCosts[i]);
//                 } else {
//                     onRoadCostsCosts.push(onRoadCosts[i]);
//                 }
//             }
//             dfd.resolve({ onRoadCostsCosts: onRoadCostsCosts, onRoadCostsDiscounts: onRoadCostsDiscounts, onRoadCostsVersion: onRoadCostsVersion });

//         })
//         .fail(function (errorMessage) {
//             dfd.reject(errorMessage);
//         });

//     return dfd.promise();
// }

/**
     * Retrieves specified database attribute translations. Translations are cached during startup and then updated by the version control system as and when required, so this method effectively 
     * always retrieves translations locally, however if local retrieval fails for some reason it will then attempt to get them online.
     * @method getTranslations
     * @param {Number} custDistGroupId Id of the customer distribution group the user belongs to. Passing the CDG Id enables the server to determine which CommonDistributionGroup 
     * the user belongs to and therefore which translations data to return.
     * @return {Object} Returns data array of Translations. 
     */
function getTranslations(custDistGroupId) {
    var dfd = $.Deferred();

    var request = 'databaseattributevalues/get?customerDistributionGroupId=' + custDistGroupId + "&databaseAttributeIds=" + config.databaseAttributesForTranslation;
    var operation = OPERATION.GetTranslations;

    handleDataRequest(function () { return localDL.getFromCache(operation, PROPERTY.Translations); }, request, operation, false)
        .then(function (data) {
            //any custom pre-processing of data should be handled here                    

            dfd.resolve({ translations: data });
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

/**
     * Retrieves common pack details which includes specified database attribute translations. Common pack details are cached during startup and then updated by the version control system as and when required, so this method effectively 
     * always retrieves common pack details locally, however if local retrieval fails for some reason it will then attempt to get them online.
     * @method getCommonPackDetails
     * @param {Number} custDistGroupId Id of the customer distribution group the user belongs to. Passing the CDG Id enables the server to determine which CommonDistributionGroup 
     * the user belongs to and therefore which translations data to return.
     * @return {Object} Returns object containing data array of Translations, data array of available legislations and a data array of tolls/toll locations
     */
function getCommonPackDetails(custDistGroupId) {
    var dfd = $.Deferred();

    var request = 'commonpackdetails/get?customerDistributionGroupId=' + custDistGroupId + "&databaseAttributeIds=" + config.databaseAttributesForTranslation;
    var operation = OPERATION.GetCommonPackDetails;

    handleDataRequest(function () { return localDL.getFromCache(operation, PROPERTY.CommonPackDetails); }, request, operation, false)
        .then(function (data) {
            //any custom pre-processing of data should be handled here                    
            var availableLegislations = [];

            data.Legislations.forEach(function (legislation) {
                var temp = {
                    id: legislation.Id,
                    legislationId: legislation.LegislationId,
                    countryId: legislation.CountryId,
                    description: legislation.Description,
                    name: legislation.Name,
                    isDefault: legislation.IsDefault,
                    countryAbbreviation: legislation.CountryAbbreviation,
                    roadNames: legislation.RoadNames
                };
                availableLegislations.push(temp);
            });

            dfd.resolve({
                translations: data.Translations,
                availableLegislations: availableLegislations,
                tolls: data.Tolls,
                dataPoints: data.DataPoints,
                dDRTranslations: data.DDRTranslations,
                accessoryTranslations: data.AccessoryTranslations,
                companyInterface: data.CompanyInterface || {}
            });
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

/**
     * Retrieves body masses. Body masses are cached during startup and then updated by the version control system as and when required, so this method effectively 
     * always retrieves body masses locally, however if local retrieval fails for some reason it will then attempt to get them online.
     * @method getBodyMasses
     * @return {Object} Returns object containing data array of body masses
     */
function getBodyMasses() {
    var dfd = $.Deferred();

    var request = OPERATION.GetBodyMasses.BaseUrl;
    var operation = OPERATION.GetBodyMasses.Code;

    handleDataRequest(function () { return localDL.getFromCache(operation, PROPERTY.BodyMasses); }, request, operation, false)
        .then(function (data) {
            
            dfd.resolve({bodyMasses: data.BodyMasses});
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

/**
     * Retrieves licence categories. Licence Categories are cached during startup and then updated by the version control system as and when required, so this method effectively 
     * always retrieves licence categories locally, however if local retrieval fails for some reason it will then attempt to get them online.
     * @method getLicenceCategories
     * @return {Object} Returns object containing data array of licence categories
     */
function getLicenceCategories() {
    var dfd = $.Deferred();

    var request = OPERATION.GetLicenceCategories.BaseUrl + '?companyId=' + globals.user.getCompanyId()
    var operation = OPERATION.GetLicenceCategories.Code;

    handleDataRequest(function () { return localDL.getFromCache(operation, PROPERTY.LicenceCategories); }, request, operation, false)
        .then(function (data) {

            dfd.resolve({ licenceCategories: data.CountryLicenceCategoryInfo });
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

/**
     * Retrieves legislation details which includes name and Id and list of legislation instructions
     * always retrieves common pack details locally, however if local retrieval fails for some reason it will then attempt to get them online.
     * @method getLegislationDetails
     * @param {Number} legislationId Legislation Id of the active legislation object on the user/offer. 
     * @param {Number} countryId Country Id of the active legislation object on the user/offer. 
     * the user belongs to and therefore which translations data to return.
     * @return {Object} Returns object that has legislation Id, name and data array of legislation instructions. 
     */
function getLegislationDetails(legislationId, countryId) {
    var dfd = $.Deferred();

    var request = 'legislations/get?legislationId=' + legislationId + "&countryId=" + countryId;
    var operation = OPERATION.GetLegislationDetails;

    handleDataRequest(function () { return localDL.getLocalLegislationDetailsById(legislationId, countryId); }, request, operation, false)
        .then(function (data) {
            //any custom pre-processing of data should be handled here                    
            
            dfd.resolve(data);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

///**
//  * Retrieves legislation list which includes legislationId, countryId, description, name and default
//  * always retrieves common pack details locally, however if local retrieval fails for some reason it will then attempt to get them online.
//  * @method getLegislationList
//  * @param {Number} cdgId Customer distribution group for the user. 
//  * the user belongs to and therefore which translations data to return.
//  * @return {Object} Returns object that has legislation Id, name and data array of legislation instructions. 
//  */
//function getLegislationList(cdgId) {
//    var dfd = $.Deferred();

//    var request = 'legislations/get?customerDistributionGroupId=' + cdgId;
//    var operation = OPERATION.GetLegislationList;

//    handleDataRequest(function () { return localDL.getFromCache(operation, PROPERTY.LegislationList); }, request, operation, false)
//        .then(function (data) {
//            //any custom pre-processing of data should be handled here
//            var returnArray = [];

//            ko.utils.arrayForEach(data, function (legislation) {
//                var temp = {
//                    id: legislation.Id,
//                    legislationId: legislation.LegislationId,
//                    countryId: legislation.CountryId,
//                    description: legislation.Description,
//                    name: legislation.Name,
//                    default: legislation.IsDefault
//                };
//                returnArray.push(temp);
//            });

//            dfd.resolve(returnArray);
//        })
//        .fail(function (errorMessage) {
//            dfd.reject(errorMessage);
//        });

//    return dfd.promise();
//}

/**
     * Retrieves specified database attribute translations. Translations are cached during startup and then updated by the version control system as and when required, so this method effectively 
     * always retrieves translations locally, however if local retrieval fails for some reason it will then attempt to get them online.
     * @method getTranslations
     * @param {Number} custDistGroupId Id of the customer distribution group the user belongs to. Passing the CDG Id enables the server to determine which CommonDistributionGroup 
     * the user belongs to and therefore which translations data to return.
     * @return {Object} Returns data array of Translations. 
     */
function getAppConfig() {
    var dfd = $.Deferred();

    var request = OPERATION.GetAppConfig.BaseUrl;
    var operation = OPERATION.GetAppConfig.Code;

    handleDataRequest(function () { return localDL.getFromCache(operation, PROPERTY.AppConfig); }, request, operation, false)
        .then(function (data) {
            //any custom pre-processing of data should be handled here                    

            dfd.resolve(data);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

/**
     * Sends data request to cloud services which will email user and notify support
     * @method postDataRequest
     * @param {string} make - Make of vehicle that user has requested
     * @param {string} model - Model of vehicle that user has requested
     * @param {string} additionalInfo - Any additional info that the user has provided
     * @param {string} type - The item type to request (vehicle/body/crane/etc)
     * @param {string} market - Country code/abbreviation of markket country
     * @param {boolean} isEnterpriseUser - Whether the user is an enterprise user or not
     * @return {Promise} Returns object that contains success/failure return codes
     */
function postDataRequest(make, model, additionalInfo, type, market, isEnterpriseUser) {
    var dfd = $.Deferred();

    var request = 'customers/datarequest';
    var operation = OPERATION.PostDataRequest;
    var returnObject = {
        "Make": make,
        "Model": model,
        "AdditionalInfo": additionalInfo,
        "Type": type,
        "Market": market,
        "IsEnterpriseUser": isEnterpriseUser
    };

    handleDataRequest(function () { return "" }, request, operation, true, returnObject)
        .then(function (data) {
            //any custom pre-processing of data should be handled here
            dfd.resolve(data);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function sendFeedback(feedback) {
    var dfd = $.Deferred();

    var request = 'customers/feedback?feedback';
    var operation = OPERATION.SendFeedback;
    var data = {
        Feedback: feedback
    };
    handleDataRequest(null, request, operation, true, data)
        .then(function (transactionResult) {
            dfd.resolve(transactionResult);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function sendReferral(referralData) {
    var dfd = $.Deferred();

    var request = 'customers/referral?referral';
    var operation = OPERATION.SendReferral;
    var data = {
        FirstName: referralData.firstName,
        LastName: referralData.lastName,
        CompanyName: referralData.companyName,
        Phone: referralData.telephoneNumber,
        Email: referralData.emailAddress
    };
    handleDataRequest(null, request, operation, true, data)
        .then(function (transactionResult) {
            dfd.resolve(transactionResult);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function sendReferralPromptNotification(numberOfUses) {
    var dfd = $.Deferred();

    var request = 'users/referralprompt?numberOfUsesFromClient=' + numberOfUses;
    var operation = OPERATION.SendReferralPromptNotification;

    handleDataRequest(null, request, operation, true)
        .then(function (transactionResult) {
            dfd.resolve(transactionResult);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function postCSVFileData(formData) {
    var dfd = $.Deferred();

    var operation = OPERATION.PostCSVData.Code;

    var request = OPERATION.PostCSVData.BaseUrl + '?operationType=CSV-VEHICLES&persistToRequester=true';
    

    handleDataRequest(null, request, operation, true, formData)
        .then(function (transactionResult) {
            dfd.resolve(transactionResult);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function postDXFFileData(formData) {
    var dfd = $.Deferred();

    var operation = OPERATION.PostDXFData.Code;

    //var request = OPERATION.PostDXFData.BaseUrl + '?operationType=CSV-VEHICLES&persistToRequester=true';
    var request = OPERATION.PostDXFData.BaseUrl;
    // var request = 'http://localhost:7071/api/Function1';// + '/?rand=' + Date.now();

    handleDataRequest(null, request, operation, true, formData)
        .then(function (transactionResult) {
            dfd.resolve(transactionResult);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function postTransSolveFileData(formData) {
    var dfd = $.Deferred();

    var operation = OPERATION.PostTransSolveData.Code;

    //var request = OPERATION.PostDXFData.BaseUrl + '?operationType=CSV-VEHICLES&persistToRequester=true';
    var request = OPERATION.PostTransSolveData.BaseUrl;

    handleDataRequest(null, request, operation, true, formData)
        .then(function (transactionResult) {
            dfd.resolve(transactionResult);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function updatePendingVehicle(id, vehicleData, vehicleChanges, isSubmit, postValidatedVehicleStatus, comments) {
    var dfd = $.Deferred();

    var isSubmitVal = isSubmit || false;
    var postValidatedVehicleStatusVal = postValidatedVehicleStatus || '';

    var postPayload = {
        VehicleDetail: vehicleData,
        PostValidatedVehicleStatus: postValidatedVehicleStatusVal,
        Comments: comments,
        IsSubmit: isSubmitVal
    };

    var operation = OPERATION.UpdatePendingVehicle.Code;

    //var request = OPERATION.UpdatePendingVehicle.BaseUrl + '/' + id + '?isSubmit=' + isSubmitVal + '&postValidatedVehicleStatus=' + postValidatedVehicleStatusVal;
    var request = OPERATION.UpdatePendingVehicle.BaseUrl + '/' + id;

    handleDataRequest(null, request, operation, true, postPayload)
    //handleDataRequest(null, request, operation, true, vehicleData)
        .then(function (result) {
            if (result.Result.ReturnCode === 1 && isSubmit) {
                //remove locally cached offer
                removeLocallyCachedVehicleAndVehicleStub(id)
                    .then(function () {
                        dfd.resolve(result);
                    });
                //remove offer stub from user
            } else if (result.Result.ReturnCode === 1) {
                //update locally cached offer representing this pending vehicle
                vehicleChanges.UpdateCounter = result.UpdateCounter;
                updateVehicleDetailLocally(id, vehicleChanges)
                    .then(function () {
                        dfd.resolve(result);
                    });
            } else {
                dfd.resolve(result);
            }
            
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();

    function removeLocallyCachedVehicleAndVehicleStub(vehicleId) {
        var dfd = $.Deferred();

        localDL.deleteRecord(localDL.STORE.NewOffers, vehicleId)
            .then(function () {
                localDL.deleteRecord(localDL.STORE.Vehicles, vehicleId)
                    .then(function () {
                        dfd.resolve();
                    });
            });

        return dfd.promise();
    }

    function updateVehicleDetailLocally(vehicleId, changesToApply) {
        var dfd = $.Deferred();

        localDL.getFromCache(OPERATION.GetNewOfferDetails, vehicleId)
            .then(function (data) {
                
                var vehicleDetails = data.Offer.Configuration;
                Object.keys(changesToApply).forEach(function (key) {
                    if (vehicleDetails[key] !== undefined) {
                        vehicleDetails[key] = changesToApply[key];
                    }
                });
                var axleCounter = 0;
                changesToApply.Axles.forEach(function (axleAsJSON) {
                    Object.keys(axleAsJSON).forEach(function (key) {
                        if (vehicleDetails.Axles[axleCounter][key] !== undefined) {
                            vehicleDetails.Axles[axleCounter][key] = axleAsJSON[key];
                        }
                    });
                    axleCounter++;
                });
                

                localDL.addToCache(OPERATION.GetNewOfferDetails, vehicleId, data)
                    .then(function () {
                        dfd.resolve();
                    });
            });

        return dfd.promise();
    }
}

/*
localDL.getFromCache(OPERATION.GetCustomer, customerId)
            .then(function (custData) {
                
                if (newSavedOfferStub) {
                    custData.Offers.push(newSavedOfferStub);
                } else {
                    custData.Offers.forEach(function (offer) {
                        if (offer.Id === localSaveObject.Changes.Id) {
                            offer.BodyManufacturer = localSaveObject.Changes.BodyManufacturer;
                            offer.BodyType = localSaveObject.Changes.BodyType;
                            offer.VehicleRange = localSaveObject.Changes.VehicleRange;
                            offer.VehicleMake = localSaveObject.Changes.VehicleMake;
                            offer.LegislationId = localSaveObject.Changes.LegislationId;
                            offer.VehicleDescription = localSaveObject.Offer.VehicleDescription;
                            offer.VehicleId = localSaveObject.Offer.VehicleId;
                            offer.Description = localSaveObject.Changes.Description;
                            offer.AdditionalDescription = localSaveObject.Changes.AdditionalDescription;
                            offer.AxleLayout = localSaveObject.Changes.AxleLayout;
                            offer.VehicleTypeCode = localSaveObject.Changes.VehicleTypeCode;
                            offer.VehicleType = localSaveObject.Changes.VehicleType;
                            offer.SpecDate = localSaveObject.Changes.SpecDate;
                            offer.Source = localSaveObject.Changes.Source;
                            offer.VehicleTypeTranslation = localSaveObject.Changes.VehicleTypeTranslation;
                            offer.WheelbaseTheoretical = localSaveObject.Changes.WheelbaseTheoretical;
                            offer.GCW = localSaveObject.Changes.GCM;
                            offer.GVW = localSaveObject.Changes.GVM;
                            offer.PreparedForName = localSaveObject.Changes.PreparedForName || "";
                        }

                    });
                }
                custData.UpdateCounter = newUpdateCounter;
                custData.OverallUpdateCounter = newOverallUpdateCounter;
                custData.Mobile = customerObject.Mobile;
                custData.DirectNumber = customerObject.DirectNumber;
                custData.Email = customerObject.Email;
                custData.ContactName = customerObject.ContactName;
                custData.Company = customerObject.Company;
                localDL.addToCache(OPERATION.SaveCustomer.Code, customerId, custData)
                    .then(function () {
                        localDL.getAllCustomersOffers(customerId)
                            .then(function (custOffersData) {
                                delete custData.Notes;
                                delete custData.Offers;
                                delete custData.Actions;
                                if (custOffersData.length === 0) {
                                    localCustDfd.resolve();
                                } else {
                                    var savedOfferCustUpdateOps = [];
                                    for (var i = 0; i < custOffersData.length; i++) {
                                        custOffersData[i].CustomerId = customerId;

                                        custOffersData[i].Customer = custData;
                                        savedOfferCustUpdateOps.push(localDL.addToCache(OPERATION.SaveOffer.Code, custOffersData[i].OfferId, custOffersData[i]));
                                    }
                                    $.when.apply($, savedOfferCustUpdateOps)
                                        .done(function () {
                                            localCustDfd.resolve();
                                        });
                                }
                            });
                        
                    });
            });

*/

function shareOffer(shareeInfoStubs, offerInfoStubs, optionalCommentForSharee, copyMeIn) {

    var dfd = $.Deferred();

    var shareData = {
        SharerOfferInfoStubs: offerInfoStubs,
        ShareeInfoStubs: shareeInfoStubs,
        OptionalCommentForSharee: optionalCommentForSharee,
        CopyMeInOnInvitation: copyMeIn
    }

    var operation = config.OPERATION.ShareOffer.Code;
    var request = config.OPERATION.ShareOffer.BaseUrl;

    handleDataRequest(null, request, operation, true, shareData)
        .then(function (result) {
            if (result.Result.ReturnCode === 1) {
                dfd.resolve(result);
            } else {
                dfd.resolve(result);
            }

        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function deleteUserOfferShareObject(shareeUserId, offerStubs, drivenBySharer) {

    var dfd = $.Deferred();


    var requestPayload = {
        OfferStubs: offerStubs,
        ShareeUserId: shareeUserId,
        SharerDriven: drivenBySharer
    }

    var operation = OPERATION.DeleteUserOfferShare.Code;
    var request = OPERATION.DeleteUserOfferShare.BaseUrl;


    handleDataRequest(null, request, operation, true, requestPayload)
        .then(function (result) {
            dfd.resolve(result);
        })
        .fail(function (error) {
            dfd.reject(error);
        });

    return dfd.promise();
}

function getOfferShareStatus(userId, offerId, customerId, shareeUserId) {
    //GetOfferShareStatus
    var dfd = $.Deferred();

    var operation = OPERATION.GetOfferShareStatus.Code;
    var request = OPERATION.GetOfferShareStatus.BaseUrl + '?userId=' + userId + '&offerId=' + offerId + '&customerId=' + customerId + '&shareeUserId=' + shareeUserId;

    handleDataRequest(null, request, operation, true)
        .then(function (result) {
            if (result.Result.ReturnCode === 1) {


                dfd.resolve(result);
            } else {
                dfd.resolve(result);
            }

        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function getUserAssociations() {
    var dfd = $.Deferred();

    var operation = OPERATION.GetUserAssociations;
    var request = 'users/userAssociations';

    handleDataRequest(null, request, operation, true)
        .then(function (result) {
            if (result.Result.ReturnCode === 1) {


                dfd.resolve(result);
            } else {
                dfd.resolve(result);
            }

        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function getShareeUserOfferDetails(offerStubs) {
    var dfd = $.Deferred();

    var offerDetails = {
        OfferStubs: offerStubs
    }

    var operation = OPERATION.GetShareeUserOfferDetails.Code;
    var request = OPERATION.GetShareeUserOfferDetails.BaseUrl;

    handleDataRequest(null, request, operation, true, offerDetails)
        .then(function (result) {
            if (result.Result.ReturnCode === 1) {
                

                dfd.resolve(result);
            } else {
                dfd.resolve(result);
            }

        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function updateShareeUserDetails(signUpFirstName, signUpLastName, signUpPassword, updateCounter, authenticationMethod) {
    var dfd = $.Deferred();

    /*
    
Public Property Password As String
Public Property AuthenticationMethod As String
Public Property FirstName As String
Public Property LastName As String
Public Property UpdateCounter As Integer
    */

    var shareeData = {
        FirstName: signUpFirstName,
        LastName: signUpLastName,
        Password: signUpPassword,
        AuthenticationMethod: authenticationMethod,
        UpdateCounter: updateCounter
    }

    var operation = config.OPERATION.UpdateShareeUserDetails.Code;
    var request = config.OPERATION.UpdateShareeUserDetails.BaseUrl;

    handleDataRequest(null, request, operation, true, shareeData)
        .then(function (result) {
            dfd.resolve(result);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function updateUserAfterCheckout(hostedPageId, boughtEdition) {
    var dfd = $.Deferred();

    

    var updateData = {
        HosteddPageIdFromCheckout: hostedPageId,
        VerifiedEmail: globals.user.getEmailAddress(),
        BoughtEdition: boughtEdition
    }

    var operation = config.OPERATION.UpdateUserAfterCheckout.Code;
    var request = config.OPERATION.UpdateUserAfterCheckout.BaseUrl;

    handleDataRequest(null, request, operation, true, updateData)
        .then(function (result) {
            dfd.resolve(result);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function updateOfferShareStatus(userId, offerId, customerId, shareeUserId, shareStatus) {
    var dfd = $.Deferred();

    /*
    
Public Property UserId As Integer
Public Property CustomerId As Integer
Public Property ShareeUserId As Integer
Public Property OfferId As Integer
Public Property ShareStatus As String
    */

    var userOfferData = {
        UserId: userId,
        CustomerId: customerId,
        ShareeUserId: shareeUserId,
        OfferId: offerId,
        ShareStatus: shareStatus
    }

    var operation = config.OPERATION.UpdateOfferShareStatus.Code;
    var request = config.OPERATION.UpdateOfferShareStatus.BaseUrl;

    handleDataRequest(null, request, operation, true, userOfferData)
        .then(function (result) {
            dfd.resolve(result);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}


/**
 * Always calls CS online to retrieve folders and calc stubs at the given level. Note root level is denoted by folderId of zero
 * @method getFolderContents
 * @param {Integer} folderId of folder to retrieve contents for         
 * @return {Object} Returns object that contains success/failure return codes, list of folders if any and list of calcs if any 
 */
function getFolderContents(folderId) {
    var dfd = $.Deferred();

    var operation = OPERATION.GetFolderContents.Code;
    var request = OPERATION.GetFolderContents.BaseUrl + '?folderId=' + folderId;

    handleDataRequest(null, request, operation, true)
        .then(function (result) {
            if (result.Result.ReturnCode === 1) {


                dfd.resolve(result);
            } else {
                dfd.resolve(result);
            }

        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

/**
* Always calls CS online to retrieve folders and calc stubs that match a given search term.
* @method getSearchResults
* @param {String} searchTerm to match folders and calcs against         
* @return {Object} Returns object that contains success/failure return codes, list of search results, each of which contains information on the path to the folder/calc  
*/
function getSearchResults(searchTerm, searchType) {
    var dfd = $.Deferred();

    var operation = OPERATION.GetSearchResults.Code;
    var request = OPERATION.GetSearchResults.BaseUrl + '?searchTerm=' + searchTerm + '&searchType=' + searchType;

    handleDataRequest(null, request, operation, true)
        .then(function (result) {
            if (result.Result.ReturnCode === 1) {


                dfd.resolve(result);
            } else {
                dfd.resolve(result);
            }

        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

/**
* Always calls CS online to retrieve the users complete folder tree structure.
* @method getFolderTreeStructure         
* @return {Object} Returns object that contains success/failure return codes, a dummy root folder object that contains the full folder structure  
*/
function getFolderTreeStructure() {
    var dfd = $.Deferred();

    var operation = OPERATION.GetFolderTreeStructure.Code;
    var request = OPERATION.GetFolderTreeStructure.BaseUrl;

    handleDataRequest(null, request, operation, true)
        .then(function (result) {
            if (result.Result.ReturnCode === 1) {


                dfd.resolve(result);
            } else {
                dfd.resolve(result);
            }

        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

/**
* Always calls CS online to create new folder with given name in given location.
* @method getSearchResults
* @param {String} searchTerm to match folders and calcs against         
* @return {Object} Returns object that contains success/failure return codes, list of search results, each of which contains information on the path to the folder/calc  
*/
function createFolder(name, parentFolderId) {
    var dfd = $.Deferred();
    
    var folderInfo = {
        name: name,
        parentFolderId: parentFolderId
    }

    var operation = OPERATION.CreateFolder.Code;
    var request = OPERATION.CreateFolder.BaseUrl;

    handleDataRequest(null, request, operation, true, folderInfo)
        .then(function (result) {
            dfd.resolve(result);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

/**
* Always calls CS online to move selected item(s) to a given target folder.
* @method moveTo
* @param {Array} selectedItems to move         
* @param {Integer} targetFolderId to move selected item(s) to         
* @return {Object} Returns object that contains success/failure return codes, list of duplicate folder names if operation failed due to duplicate folder names in target directory
*/
function moveTo(selectedItems, pathToFolder) {
    var dfd = $.Deferred();

    var moveToInfo = {
        SelectedItems: selectedItems,
        FolderPath: pathToFolder
    }

    var operation = OPERATION.MoveTo.Code;
    var request = OPERATION.MoveTo.BaseUrl;

    handleDataRequest(null, request, operation, true, moveToInfo)
        .then(function (result) {
            dfd.resolve(result);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

/**
* Always calls CS online to retrieve the list of sharers for the user.
* @method getSharers         
* @return {Object} Returns object that contains success/failure return codes, a list of sharers  
*/
function getSharers() {
    var dfd = $.Deferred();

    var operation = OPERATION.GetSharers.Code;
    var request = OPERATION.GetSharers.BaseUrl;

    handleDataRequest(null, request, operation, true)
        .then(function (result) {
            if (result.Result.ReturnCode === 1) {
                dfd.resolve(result);
            } else {
                dfd.resolve(result);
            }
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

/**
* Always calls CS online to rename selected item(offer or folder).
* @method rename
* @param {Integer} objectId of item to rename          
* @param {String} newName of item         
* @param {String} renameType flag to indicate to server whether item is a folder or offer         
* @return {Object} Returns object that contains success/failure return codes
*/
function rename(objectId, newName, renameType) {
    var dfd = $.Deferred();

    var renameInfo = {
        RenameObjectId: objectId,
        NewName: newName,
        RenameType: renameType
    }

    var operation = OPERATION.Rename.Code;
    var request = OPERATION.Rename.BaseUrl;

    handleDataRequest(null, request, operation, true, renameInfo)
        .then(function (result) {
            dfd.resolve(result);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}
/**
* Always calls CS online to remove selected items(offers and/or folders).
* @method removeItems
* @param {Object} selectedItems list of one or more folders and calculations to be removed          
* @return {Object} Returns object that contains success/failure return codes
*/
function removeItems(selectedItems) {
    var dfd = $.Deferred();

    var removeInfo = {
        SelectedItems: selectedItems
    }

    var operation = OPERATION.Remove.Code;
    var request = OPERATION.Remove.BaseUrl;

    handleDataRequest(null, request, operation, true, removeInfo)
        .then(function (result) {
            dfd.resolve(result);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

/**
* Always calls CS online to fetch user's saved offer stubs.
* @method getSavedOfferStubs
* @return {Object} Returns object that contains success/failure return codes and a list of offer stubs
*/
function getSavedOfferStubs() {

    var dfd = $.Deferred();

    var request = OPERATION.GetSavedOfferStubs.BaseUrl;
    var operation = OPERATION.GetSavedOfferStubs.Code;

    handleDataRequest(null, request, operation, true)
    .then(function (data) {
        
        dfd.resolve(data);

    })
    .fail(function (errorMessage) {
        dfd.reject(errorMessage);
    });

    return dfd.promise();
}

/**
* Always calls intercom to fetch TSc resources and messages the response into a format for local consumption in the app.
* @method getResources
* @return {Object} Returns array of resource sections that contain resource articles
*/
function getResources() {

    var dfd = $.Deferred();
    
    var request = OPERATION.GetResources.BaseUrl;
    var operation = OPERATION.GetResources.Code;

    handleDataRequest(null, request, operation, true)
    .then(function (data) {
        dfd.resolve(data);
    })
    .fail(function (errorMessage) {
        dfd.reject(errorMessage);
    });
    //var data = {
    //    ResourceAreas: [
    //        {
    //            name: 'Webinars',
    //            description: 'Video tutorials on how to do things in the app',
    //            Articles: [
    //                {
    //                    title: 'Webinar - Axle Weight Calculator Basics',
    //                    description: 'Get an overview of what TruckScience offers and how to achieve various tasks through the app',
    //                    body: "<p>Hello from - Axle Weight Calculator Basics</p>"
    //                },
    //                {
    //                    title: 'Webinar - Payload Optimisation Strategies',
    //                    description: 'Deep dive into specific strategies that can be applied through the app to make sure payloads are optimised while remaining compliant',
    //                    body: "<p>Hello from - Payload Optimisation Strategies</p>"
    //                }
    //            ]
    //        },
    //        {
    //            name: 'Case Studies',
    //            description: 'Real world examples of customers utilising TruckScience in their business',
    //            Articles: [
    //                {
    //                    title: 'Case Study 1',
    //                    description: 'John Doe describes how TruckScience has improved customer project delivery times and allowed their business to expand',
    //                    body: "<p>Hello from - Case Study 1</p>"
    //                },
    //                {
    //                    title: 'Case Study 2',
    //                    description: 'RandomTrucks speak about how TruckScience has allowed them to produce more consistent results leading to happier customers',
    //                    body: "<p>Hello from - Case Study 2</p>"
    //                }
    //            ]
    //        }
    //    ]
    //};

    //dfd.resolve(data);


    return dfd.promise();
}

function getOriginalsForItemsAddedToRig(accessories, trailers, payloads, axles) {

    var dfd = $.Deferred();

    var hasAccessories = offerHasAnyAddedAccessories(accessories); 

    if (hasAccessories || (trailers && trailers.length > 0) || (payloads && payloads.length > 0)) {

        preventCaching = true;

        var originalsPromises = [];
        var retrievedOriginals = {};
        var retrievedAccessories = null;
        var retrievedTrailers = null;
        var retrievedPayloads = null;

        if (hasAccessories) {
            retrievedAccessories = {
                Bodies: [],
                Cranes: [],
                Taillifts: [],
                Fridges: [],
                Others: [],
                FifthWheels: [],
                Hitches: [],
                Hooklifts: []
            };

            if (accessories.Bodies && accessories.Bodies.length > 0) {
                accessories.Bodies.forEach(function (body) {
                    if (body.SourceDatabaseId > 0) {
                        originalsPromises.push(getBody(body.SourceDatabaseId, body.Source)
                        .then(function (data) {
                            data.Body.ParentType = body.ParentType;
                            retrievedAccessories.Bodies.push(data.Body);
                        }));
                    } else {
                        var bodyCopy = JSON.parse(JSON.stringify(body));
                        var tempReplacementIdForIdEqualZero = 'unknown_' + globals.nextId();
                        body.SourceDatabaseId = tempReplacementIdForIdEqualZero;
                        bodyCopy.Id = tempReplacementIdForIdEqualZero;
                        bodyCopy.AccessoryType = config.ACCESSORY_TYPES.BODY;
                        bodyCopy.ParentType = body.ParentType;
                        retrievedAccessories.Bodies.push(bodyCopy);
                    }
                });
            }

            if (accessories.Cranes && accessories.Cranes.length > 0) {
                accessories.Cranes.forEach(function (crane) {
                    if (crane.SourceDatabaseId > 0) {
                        originalsPromises.push(getAccessory(crane.SourceDatabaseId, config.ACCESSORY_TYPES.CRANE, crane.Source)
                        .then(function (data) {
                            data.Accessory.ParentType = crane.ParentType;
                            retrievedAccessories.Cranes.push(data.Accessory);
                        }));
                    } else {
                        var craneCopy = JSON.parse(JSON.stringify(crane));
                        var tempReplacementIdForIdEqualZero = 'unknown_' + globals.nextId();
                        crane.SourceDatabaseId = tempReplacementIdForIdEqualZero;
                        craneCopy.Id = tempReplacementIdForIdEqualZero;
                        craneCopy.AccessoryType = config.ACCESSORY_TYPES.CRANE;
                        craneCopy.ParentType = crane.ParentType;
                        retrievedAccessories.Cranes.push(craneCopy);
                    }

                });
            }

            if (accessories.Others && accessories.Others.length > 0) {
                accessories.Others.forEach(function (other) {
                    if (other.SourceDatabaseId > 0) {
                        originalsPromises.push(getAccessory(other.SourceDatabaseId, config.ACCESSORY_TYPES.OTHER, other.Source)
                        .then(function (data) {
                            data.Accessory.ParentType = other.ParentType;
                            retrievedAccessories.Others.push(data.Accessory);
                        }));
                    } else {
                        var otherCopy = JSON.parse(JSON.stringify(other));
                        var tempReplacementIdForIdEqualZero = 'unknown_' + globals.nextId();
                        other.SourceDatabaseId = tempReplacementIdForIdEqualZero;
                        otherCopy.Id = tempReplacementIdForIdEqualZero;
                        otherCopy.AccessoryType = config.ACCESSORY_TYPES.OTHER;
                        otherCopy.ParentType = other.ParentType;
                        retrievedAccessories.Others.push(otherCopy);
                    }

                });
            }

            if (accessories.Fridges && accessories.Fridges.length > 0) {
                accessories.Fridges.forEach(function (fridge) {
                    if (fridge.SourceDatabaseId > 0) {
                        originalsPromises.push(getAccessory(fridge.SourceDatabaseId, config.ACCESSORY_TYPES.FRIDGE, fridge.Source)
                        .then(function (data) {
                            data.Accessory.ParentType = fridge.ParentType;
                            retrievedAccessories.Fridges.push(data.Accessory);
                        }));
                    } else {
                        var fridgeCopy = JSON.parse(JSON.stringify(fridge));
                        var tempReplacementIdForIdEqualZero = 'unknown_' + globals.nextId();
                        fridge.SourceDatabaseId = tempReplacementIdForIdEqualZero;
                        fridgeCopy.Id = tempReplacementIdForIdEqualZero;
                        fridgeCopy.AccessoryType = config.ACCESSORY_TYPES.FRIDGE;
                        fridgeCopy.ParentType = fridge.ParentType;
                        retrievedAccessories.Fridges.push(fridgeCopy);
                    }

                });
            }

            if (accessories.Taillifts && accessories.Taillifts.length > 0) {
                accessories.Taillifts.forEach(function (taillift) {
                    if (taillift.SourceDatabaseId > 0) {
                        originalsPromises.push(getAccessory(taillift.SourceDatabaseId, config.ACCESSORY_TYPES.TAILLIFT, taillift.Source)
                        .then(function (data) {
                            data.Accessory.ParentType = taillift.ParentType;
                            retrievedAccessories.Taillifts.push(data.Accessory);
                        }));
                    } else {
                        var tailliftCopy = JSON.parse(JSON.stringify(taillift));
                        var tempReplacementIdForIdEqualZero = 'unknown_' + globals.nextId();
                        taillift.SourceDatabaseId = tempReplacementIdForIdEqualZero;
                        tailliftCopy.Id = tempReplacementIdForIdEqualZero;
                        tailliftCopy.AccessoryType = config.ACCESSORY_TYPES.TAILLIFT;
                        tailliftCopy.ParentType = taillift.ParentType;
                        retrievedAccessories.Taillifts.push(tailliftCopy);
                    }

                });
            }

            if (accessories.FifthWheels && accessories.FifthWheels.length > 0) {
                accessories.FifthWheels.forEach(function (fifthWheel) {
                    if (fifthWheel.SourceDatabaseId > 0) {
                        originalsPromises.push(getAccessory(fifthWheel.SourceDatabaseId, config.ACCESSORY_TYPES.FIFTH_WHEEL, fifthWheel.Source)
                        .then(function (data) {
                            data.Accessory.ParentType = fifthWheel.ParentType;
                            retrievedAccessories.FifthWheels.push(data.Accessory);
                        }));
                    } else {
                        var fifthWheelCopy = JSON.parse(JSON.stringify(fifthWheel));
                        var tempReplacementIdForIdEqualZero = 'unknown_' + globals.nextId();
                        fifthWheel.SourceDatabaseId = tempReplacementIdForIdEqualZero;
                        fifthWheelCopy.Id = tempReplacementIdForIdEqualZero;
                        fifthWheelCopy.AccessoryType = config.ACCESSORY_TYPES.FIFTH_WHEEL;
                        fifthWheelCopy.ParentType = fifthWheel.ParentType;
                        retrievedAccessories.FifthWheels.push(fifthWheelCopy);
                    }

                });
            }

            if (accessories.Hitches && accessories.Hitches.length > 0) {
                accessories.Hitches.forEach(function (hitch) {
                    if (hitch.SourceDatabaseId > 0) {
                        originalsPromises.push(getAccessory(hitch.SourceDatabaseId, config.ACCESSORY_TYPES.HITCH, hitch.Source)
                        .then(function (data) {
                            data.Accessory.ParentType = hitch.ParentType;
                            retrievedAccessories.Hitches.push(data.Accessory);
                        }));
                    } else {
                        var hitchCopy = JSON.parse(JSON.stringify(hitch));
                        var tempReplacementIdForIdEqualZero = 'unknown_' + globals.nextId();
                        hitch.SourceDatabaseId = tempReplacementIdForIdEqualZero;
                        hitchCopy.Id = tempReplacementIdForIdEqualZero;
                        hitchCopy.AccessoryType = config.ACCESSORY_TYPES.HITCH;
                        hitchCopy.ParentType = hitch.ParentType;
                        retrievedAccessories.Hitches.push(hitchCopy);
                    }

                });
            }

            if (accessories.Hooklifts && accessories.Hooklifts.length > 0) {
                accessories.Hooklifts.forEach(function (hooklift) {
                    if (hooklift.SourceDatabaseId > 0) {
                        originalsPromises.push(getAccessory(hooklift.SourceDatabaseId, config.ACCESSORY_TYPES.HOOKLIFT, hooklift.Source)
                            .then(function (data) {
                                data.Accessory.ParentType = hooklift.ParentType;
                                retrievedAccessories.Hooklifts.push(data.Accessory);
                            }));
                    } else {
                        var hookliftCopy = JSON.parse(JSON.stringify(hooklift));
                        var tempReplacementIdForIdEqualZero = 'unknown_' + globals.nextId();
                        hooklift.SourceDatabaseId = tempReplacementIdForIdEqualZero;
                        hookliftCopy.Id = tempReplacementIdForIdEqualZero;
                        hookliftCopy.AccessoryType = config.ACCESSORY_TYPES.HOOKLIFT;
                        hookliftCopy.ParentType = hooklift.ParentType;
                        retrievedAccessories.Hooklifts.push(hookliftCopy);
                    }
                });
            }
        }
        if (trailers && trailers.length > 0) {

            retrievedTrailers = [];
            var trailerCounter = 1;
            trailers.forEach(function (trailer) {
                if (trailer.SourceDatabaseId > 0) {
                    originalsPromises.push(getTrailer(trailer.SourceDatabaseId, trailer.Source)
                    .then(function (data) {
                        data.Trailer.RigPosition = trailer.RigPosition;
                        retrievedTrailers.push(data.Trailer);
                    }));
                } else {
                    var trailerCopy = JSON.parse(JSON.stringify(trailer));

                    var chassisObjectType;
                    if (trailerCounter === 1) {
                        chassisObjectType = config.CHASSIS_OBJECT_TYPES.TRAILER1;
                        if (axles.length > 0) {
                            var trailer1Axles = axles.filter(function (axleAsJSON) {
                                return axleAsJSON.ParentType === config.CHASSIS_OBJECT_TYPES.TRAILER1;
                            });
                            trailerCopy.Axles = trailer1Axles;
                        }
                        trailerCounter++;
                    } else {
                        chassisObjectType = config.CHASSIS_OBJECT_TYPES.TRAILER2;
                        if (axles.length > 0) {
                            var trailer2Axles = axles.filter(function (axleAsJSON) {
                                return axleAsJSON.ParentType === config.CHASSIS_OBJECT_TYPES.TRAILER2;
                            });
                            trailerCopy.Axles = trailer2Axles;
                        }
                    }

                    
                    var tempReplacementIdForIdEqualZero = 'unknown_' + globals.nextId();
                    trailer.SourceDatabaseId = tempReplacementIdForIdEqualZero;
                    trailerCopy.Id = tempReplacementIdForIdEqualZero;
                    trailerCopy.AccessoryType = config.ACCESSORY_TYPES.TRAILER;
                    trailerCopy.RigPosition = trailer.RigPosition;
                    retrievedTrailers.push(trailerCopy);
                }

            });
        }

        if (payloads && payloads.length > 0) {

            retrievedPayloads = [];

            payloads.forEach(function (payload) {
                if (payload.SourceDatabaseId > 0) {
                    originalsPromises.push(getPayload(payload.SourceDatabaseId, payload.Source)
                    .then(function (data) {
                        data.Payload.ParentType = payload.ParentType;
                        retrievedPayloads.push(data.Payload);
                    }));
                } else {
                    var payloadCopy = JSON.parse(JSON.stringify(payload));
                    var tempReplacementIdForIdEqualZero = 0;
                    if (payload.PayloadType !== config.PAYLOAD_TYPES.SIMPLE) {
                        tempReplacementIdForIdEqualZero = 'unknown_' + globals.nextId();
                    }
                    payload.SourceDatabaseId = tempReplacementIdForIdEqualZero;
                    payloadCopy.Id = tempReplacementIdForIdEqualZero;
                    payloadCopy.AccessoryType = config.ACCESSORY_TYPES.PAYLOAD;
                    payloadCopy.ParentType = payload.ParentType;
                    retrievedPayloads.push(payloadCopy);
                }

            });
        }

        $.when.apply($, originalsPromises)
            .done(function () {
                preventCaching = false;
                allOriginalAccessoriesRetrievedObv.value = true;
                dfd.resolve({ accessories: retrievedAccessories, trailers: retrievedTrailers, payloads: retrievedPayloads });
            })
            .fail(function (error) {
                preventCaching = false;
                dfd.reject(error);
            });
    } else {
        allOriginalAccessoriesRetrievedObv.value = true;
        dfd.resolve(null);
    }




    function offerHasAnyAddedAccessories(accessories) {

        if (accessories === undefined || accessories === null) {
            return false;
        }

        if (accessories.Bodies && accessories.Bodies.length > 0) {
            return true;
        }

        if (accessories.Cranes && accessories.Cranes.length > 0) {
            return true;
        }

        if (accessories.Others && accessories.Others.length > 0) {
            return true;
        }

        if (accessories.Fridges && accessories.Fridges.length > 0) {
            return true;
        }

        if (accessories.Taillifts && accessories.Taillifts.length > 0) {
            return true;
        }

        if (accessories.FifthWheels && accessories.FifthWheels.length > 0) {
            return true;
        }

        if (accessories.Hitches && accessories.Hitches.length > 0) {
            return true;
        }

        if (accessories.Hooklifts && accessories.Hooklifts.length > 0) {
            return true;
        }

        return false;
    }

    return dfd.promise();
}

// function sendDataToGoogleAnalytics(category, action, label, value) {
//     doSendDataToGoogleAnalytics(category, action, label, value);
    
//     function doSendDataToGoogleAnalytics(category, action, label, value) {
//         var fieldsObject = {};
//         fieldsObject.hitType = 'event';
//         fieldsObject.eventCategory = category;
//         fieldsObject.eventAction = action;
//         if (label !== undefined) {
//             fieldsObject.eventLabel = label;
//         }
//         if (value !== undefined) {
//             fieldsObject.eventValue = value;
//         }

//         window.ga('send', fieldsObject);
//     }
// }

//#region Internal methods
function setQueryHeader(xhr) {
    xhr.setRequestHeader('SecurityToken', securityTokenProvided);
    xhr.setRequestHeader('cultureCode', cultureCodeProvided);
    //xhr.setRequestHeader('machineCode', cultureCodeProvided);
}

function setUnAuthenticatedQueryHeader(xhr) {
    xhr.setRequestHeader('cultureCode', cultureCodeProvided);
}


//#endregion


//#region code

/**
 * Retrieves specified image and converts it to a dataURL which it resolves to the caller. If it fails it will retry for the specified number of times.
 * @method convertImgToBase64
 * @param {String} url The url of the image to be retrieved and converted.
 * @param {String} outputFormat The format of the image described by the resulting dataUrl.
 * @return {String} Returns dataUrl representation of image. 
 */
function convertImgToBase64(url, outputFormat) {
    var dfd = $.Deferred();

    var canvas = document.createElement('CANVAS'),
        ctx = canvas.getContext('2d');
        //img = new Image;
    if (outputFormat === undefined) {
        outputFormat = "image/jpeg";
    }

    doImageRetrieval(1);

    function doImageRetrieval(numRetries) {
        var img = new Image;

        img.crossOrigin = 'Anonymous';

        img.onload = function () {
            var dataURL;
            canvas.height = img.height;
            canvas.width = img.width;
            ctx.drawImage(img, 0, 0);
            dataURL = canvas.toDataURL(outputFormat);
            canvas = null;
            dfd.resolve(dataURL);
        };

        img.onerror = function () {
            if (numRetries > 0) {
                numRetries--;
                img.onerror = null;
                img.onload = null;
                img = null;
                doImageRetrieval(numRetries);
            } else {
                dfd.resolve();
            }
            
        };
        img.src = decodeURI(url);
    }

    return dfd.promise();
}

/**
 * Replaces image url on a given object with the dataUrl stored locally. If there is no locally stored dataUrl then the method will kick off a job to retrieve and 
 * store the image but return to the caller without waiting for image to be retrieved.
 * @method replaceImageUrl
 * @param {Object} object The object on which an image url is to be swapped for a dataUrl.
 * @param {String} property The property on the object that contains the image url to be replaced.
 * @param {String} pack The pack the image is associated with. This is only used in the event that the image is not stored locally and needs to be retrieved and stored. 
 * @returns {Promise} Promise resolves when localDL.getImageIfInDB resolves
 */
function replaceImageUrl(object, property, pack) {
    var dfd = $.Deferred();
    if (object[property] === "" || object[property] === undefined || object[property] === null) {
        dfd.resolve();
    }else {
        localDL.getImageIfInDB(object[property])
        .then(function (url, dataUrl) {
            if (dataUrl === undefined) {
                convertAndAddImageToDB(url, pack);
                dfd.resolve();
            } else {
                object[property] = dataUrl;
                dfd.resolve();
            }
        })
        .fail(function () {
            dfd.resolve();
        });
    }
    

    return dfd.promise();
}

/**
        * Replaces vehicleBodyPlus image url on a given offer object with the dataUrl stored locally. If there is no locally stored dataUrl then the method will kick off a job to retrieve and 
        * store the image but return to the caller without waiting for image to be retrieved.
        * @method replaceVehiclePlusBodyImageURL
        * @param {Object} offer The offer object on which the vehicleBodyPlus image url is to be swapped for a dataUrl.
        * @returns {Promise} Promise resolves when localDL.getImageIfInDB resolves
        */
function replaceVehiclePlusBodyImageURL(offer) {
    var dfd = $.Deferred();

    localDL.getImageIfInDB(offer.VehiclePlusBodyImageURL)
        .then(function (url,dataUrl) {
            if (dataUrl === undefined) {
                convertAndAddImageToDB(url, localDL.PACK.Vehicle);
                dfd.resolve();
            } else {
                offer.VehiclePlusBodyImageURL = dataUrl;
                dfd.resolve();
            }
        })
        .fail(function () { dfd.resolve()});
    return dfd.promise();
}

/**
        * Replaces image url in HTML in brochure item with the dataUrl stored locally. If there is no locally stored dataUrl then the method will kick off a job to retrieve and 
        * store the image but return to the caller without waiting for image to be retrieved.
        * @method replaceBrochureItemURLs
        * @param {Array} brochureItemGroups An array of brochure item groups with each group containing borchure items with image urls to be swapped for a dataUrl.
        * @returns {Promise} Promise resolves when localDL.getImageIfInDB resolves
        */
function replaceBrochureItemURLs(brochureItemGroups) {
    var dfd = $.Deferred();
    var promises = [];

    var i = 0, j, tempItems, tempItem;
    for (; i < brochureItemGroups.length; i++) {
        tempItems = brochureItemGroups[i].VehicleBrochureItems;
        for (j = 0; j < tempItems.length; j++) {
            tempItem = tempItems[j];
            if (tempItem["HTML"] !== undefined) {
                promises.push(localDL.getImageIfInDB(getImageSrc(tempItem["HTML"]), tempItem));
            }
        }
        $.when.apply($, promises).done(function () {
            var results = Array.prototype.slice.call(arguments);
            var k = 0;
            var urlPromises = [];

            if (typeof results !== 'string' && typeof results[0] === 'string' && typeof results[2] !== 'string') {
                processResult(urlPromises, results);
            } else {
                for (; k < results.length; k++) {
                    processResult(urlPromises, results[k]);
                }
            }
            
            $.when.apply($, urlPromises).done(function () {
                dfd.resolve();
            });
        });

        
    }
    function processResult(urlPromiseArray, result) {
        var url;
        var dataUrl;
        var brochureItem;
        //if result is a string then only the image url has been returned i.e. image/dataUrl not in DB
        if (typeof result === 'string') {
            url = result;
        } else {
            url = result[0];
            dataUrl = result[1];
            brochureItem = result[2];
        }

        if (dataUrl === undefined) {
            var imageFormat = getImageFormat(url);
            convertAndAddImageToDB(url, localDL.PACK.Vehicle);
        } else {
            urlPromiseArray.push(replaceSrcInHTML(brochureItem, url, dataUrl));
        }
    }
    return dfd.promise();
}

/**
    * Queues replaceImageUrl jobs onto a given promise array to replace the necessary image urls on a given vehicle specification.
    * @method replaceVehicleSpecificationImageURLs
    * @param {Object} vehicleSpec The vehicle specification with image urls to be swapped for a dataUrl.
    * @param {Array} promises An array the replaceImageUrl jobs will be pushed onto.
    */
function replaceVehicleSpecificationImageURLs(vehicleSpec, promises) {
    var pack = localDL.PACK.Vehicle;
    
    promises.push(replaceImageUrl(vehicleSpec, "ManufacturerLogo", pack));
    promises.push(replaceImageUrl(vehicleSpec, "ReportManufacturerLogo", pack));
    promises.push(replaceImageUrl(vehicleSpec, "ReportVehicleChassisImage", pack));
    promises.push(replaceImageUrl(vehicleSpec, "VehicleChassisImage", pack));
}

function replaceSrcInHTML(brochureItem, url, dataUrl) {
    
    brochureItem["HTML"] = brochureItem["HTML"].replace(url, dataUrl);
}

function getImageSrc(html) {
    var regex = /<img[^>]+src="(https:\/\/[^">]+)"/g;
    var temp;

    temp = regex.exec(html);
    if (temp === null) {
        return '';
    } else {
        return temp[1];
    }            
}

function getVideoSrc(html) {
    var regex = /<iframe[^>]+src="(\/\/[^">]+)"/g;
    var temp;

    temp = regex.exec(html);

    return temp[1];
}

function doRegExReplace(dataURL, url, htmlDoc) {
    var dfd = $.Deferred();

    htmlDoc.replace(url, dataURL);
    dfd.resolve();

    return dfd.promise();
}

/**
* Wrapper method for setting up indexed db and then making sure it's open and ready to be used.
* @method setupIndexedDB
*/
function setupIndexedDB() {
    var dfd = $.Deferred();

    localDL.setupDBAndObjectStore()
        .then(function () {
            localDL.openDB().then(function () {
                dfd.resolve("db_ready");
            })
            .fail(function () {
                dfd.reject("db_failed");
            });
        })
        .fail(function () {
            dfd.reject("db_failed");
        });

    return dfd.promise();
}

function setupDataManager() {

}

function sendInfoToIntercom(operation, dataToSend) {
    var dfd = $.Deferred();
    if (globals.preventSendingOfIntercomEvent(dataToSend.eventName, dataToSend.metadata) === false) {
        if (globals.isOnline.value === true) {
            // Add sync queue code
            //handleDataRequest(null, null, config.OPERATION.SendIntercomEventData.Code, true, dataToSend)
            handleDataRequest(null, null, operation.Code, true, dataToSend)
                .then(function (data) {
                    //any custom pre-processing of data should be handled here                    
                    dfd.resolve(data);
                })
                .fail(function (e) {
                    if (e.errorType === 'INTERCOM') {
                        if (dataToSend.eventName !== config.INTERCOM_EVENT.ERROR_RAISED) {
                            logger.log(e.errorMessage, dataToSend, 'dataManager', config.showDebugToast);
                            log(e.errorMessage, e.errorDetails, 'sendInfoToIntercom', config.LOG_MESSAGE_TYPE.ERROR);
                        }
                    } else {
                        saveItemToSyncQueue();
                    }
                    //dfd.reject(errorMessage);
                });
        } else {
            saveItemToSyncQueue();
        }
    } else {
        dfd.resolve()
    }
        function saveItemToSyncQueue() {
            queueSyncJob(OPERATION.SendIntercomEventData, Date.now(), {}, dataToSend);
            dfd.resolve();
        }
    //} else {
    //    dfd.resolve();
    //}
    
    return dfd.promise();
}

// function triggerEventInGoogleAnalytics(eventName, eventCategory, eventAction, eventLabel, eventValue) {
function triggerEventInGoogleAnalytics(eventName, parametersObj) {
    let objToSend = {
        'event': eventName
    };
    if (parametersObj !== undefined && typeof parametersObj === 'object') {
        objToSend = Object.assign(objToSend, parametersObj);
    }
    dataLayer.push(objToSend);
    // dataLayer.push({
    //     'event': eventName,
    //     'variable_name': 'variable_value'
    // });
}

//function sendUserInfoToIntercom(dataToSend) {
//    var dfd = $.Deferred();
//    if (globals.isOnline.value === true) {
//        // Add sync queue code
//        handleDataRequest(null, null, config.OPERATION.SendIntercomUserData.Code, true, dataToSend)
//            .then(function (data) {
//                //any custom pre-processing of data should be handled here
//                dfd.resolve(data);
//            })
//            .fail(function (errorMessage) {
//                saveItemToSyncQueue();
//                //dfd.reject(errorMessage);
//            });
//    } else {
//        saveItemToSyncQueue();
//    }

//    function saveItemToSyncQueue() {
//        queueSyncJob(OPERATION.SendIntercomUserData.Code, Date.now(), undefined, dataToSend);
//        dfd.resolve();
//    }

//    return dfd.promise();
//}

//function sendEventInfoToIntercom(dataToSend) {
//    var dfd = $.Deferred();
//    if (globals.isOnline.value === true) {
//        // Add sync queue code
//        handleDataRequest(null, null, config.OPERATION.SendIntercomEventData.Code, true, dataToSend)
//            .then(function (data) {
//                //any custom pre-processing of data should be handled here                    
//                dfd.resolve(data);
//            })
//            .fail(function (errorMessage) {
//                saveItemToSyncQueue();
//                //dfd.reject(errorMessage);
//            });
//    } else {
//        saveItemToSyncQueue();
//    }

//    //checkIsOnline()
//    //    .then(function () {
//    //        // Add sync queue code
//    //        handleDataRequest(null, null, config.OPERATION.SendIntercomEventData.Code, true, dataToSend)
//    //            .then(function (data) {
//    //                //any custom pre-processing of data should be handled here                    
//    //                dfd.resolve(data);
//    //            })
//    //            .fail(function (errorMessage) {
//    //                saveItemToSyncQueue();
//    //                //dfd.reject(errorMessage);
//    //            });
//    //    })
//    //    .fail(function () {
//    //        saveItemToSyncQueue();
//    //    });

//    function saveItemToSyncQueue() {
//        queueSyncJob(OPERATION.SendIntercomEventData, Date.now(), {}, dataToSend);
//        dfd.resolve();
//    }
//    return dfd.promise();
//}

function loadProperties() {
    var dfd = $.Deferred();
    
    localDL.getFromCache(OPERATION.LastDebugLogUpload, PROPERTY.LastDebugLogUpload)
        .then(function (lastDebugLogUpload) {
            if (lastDebugLogUpload === undefined || lastDebugLogUpload === null) {
                lastDebugLogUploadTimeStamp = 0;
            } else {
                lastDebugLogUploadTimeStamp = Number(lastDebugLogUpload);
            }
            localDL.getFromCache(OPERATION.LastUsageLogUpload, PROPERTY.LastUsageLogUpload)
                .then(function (lastUsageLogUpload) {
                    if (lastUsageLogUpload === undefined || lastUsageLogUpload === null) {
                        lastUsageLogUploadTimeStamp = 0;
                    } else {
                        lastUsageLogUploadTimeStamp = Number(lastUsageLogUpload);
                    }
                    getAppConfig()
                        .then(function (appConfigData) {
                            if (appConfigData.Result.ReturnCode === 1) {
                                setConfigVals(appConfigData.AppConfig);
                            } else {
                                setConfigVals(config.defaultAppConfig);
                            }
                            updatePriorityFromConfigValues();
                            //config.debugLogCommunicationPeriod = 60;//hardcoded seconds = 1 min for testing, will be replace with app config values loaded from properties in due course. 
                            //config.loggingLevel = LOGGING_LEVEL.DETAILED;// hardcoded for testing, will be replaced with app config value loaded from properties...
                            //config.debugLogRetentionPeriod = 120;// hardcoded to 2 mins for testing etc
                            dfd.resolve();
                        })
                        .fail(function () {
                            setConfigVals(config.defaultAppConfig);
                            updatePriorityFromConfigValues();
                            //config.debugLogCommunicationPeriod = 60;//hardcoded seconds = 1 min for testing, will be replace with app config values loaded from properties in due course. 
                            //config.loggingLevel = LOGGING_LEVEL.DETAILED;// hardcoded for testing, will be replaced with app config value loaded from properties...
                            //config.debugLogRetentionPeriod = 120;// hardcoded to 2 mins for testing etc
                            dfd.resolve();
                        });
            
                });

        });

    function setConfigVals(appConfigData) {
        config.loggingLevel = appConfigData.LoggingLevel;
        config.debugLogRetentionPeriod = appConfigData.LogRetentionPeriod;//in seconds
        config.debugLogCommunicationPeriod = appConfigData.LogCommunicationPeriod;//in seconds
        config.noOfDaysLicenceExpiryMessageShows = appConfigData.NoOfDaysLicenceExpiryMessageShows;
        config.isOnlineCheckPeriod = appConfigData.IsOnlineCheckPeriod;
        config.isOnlineTimeout = appConfigData.IsOnlineTimeout;
        config.isOnlineLowPriorityValidityPeriod = appConfigData.IsOnlineLowPriorityValidityPeriod;
        config.isOnlineMediumPriorityValidityPeriod = appConfigData.IsOnlineMediumPriorityValidityPeriod;
        config.isOnlineHighPriorityValidityPeriod = appConfigData.IsOnlineHighPriorityValidityPeriod;
        config.postRequestTimeout = appConfigData.PostRequestTimeout;
        config.getRequestTimeout = appConfigData.GetRequestTimeout;
        config.usageCommunicationPeriod = appConfigData.UsageCommunicationPeriod;//in seconds
        config.maxNewOffersCached = appConfigData.MaxNewOffersCached;
        config.maxSavedOffersCached = appConfigData.MaxSavedOffersCached;

        globals.appConfigApplied = true;
       
    }

    return dfd.promise();
}



/**
* Gets the app logos if they are stored locally or kicks off a job to retrieve them if they are not
* @method loadLocallyOrRetrieveAndStoreAppLogos
*/
function loadLocallyOrRetrieveAndStoreAppLogos() {
    var dfd = $.Deferred();
    
    if (localDL.isDBReady() === false) {
        setupIndexedDB
            .then(function (success) {
                doLoadLocallyOrRetrieveAndStoreAppLogos();
            })
            .fail(function (failure) {
                dfd.reject(failure);
            });
    } else {
        doLoadLocallyOrRetrieveAndStoreAppLogos();
    }

    function doLoadLocallyOrRetrieveAndStoreAppLogos() {
        var headerUrl = config.getAppHeaderURL();
        var defaultReportFooterUrl = config.defaultReportFooterURL();
        var defaultReportHeaderUrl = config.defaultReportHeaderURL();
        
        var appImageUrls = [];
        $.when(localDL.getImageIfInDB(headerUrl),
            localDL.getImageIfInDB(defaultReportFooterUrl),
            localDL.getImageIfInDB(defaultReportHeaderUrl))
            .done(function (r1, r2, r3) {
                var pack = localDL.PACK.Graphic;
                if (typeof r1 === 'string') {
                    appImageUrls.push(r1);
                } else {
                    config.appHeaderURL.value = r1[1];
                }

                if (typeof r2 === 'string') {
                    appImageUrls.push(r2);
                } else {
                    config.setDefaultReportFooterURL(r2[1]);
                }

                if (typeof r3 === 'string') {
                    appImageUrls.push(r3);
                } else {
                    config.setDefaultReportHeaderURL(r3[1]);
                }
                
                dfd.resolve();
                if (appImageUrls.length > 0) {
                    loadAllImages(appImageUrls, pack, function (allImagesDownloaded) {
                        if (allImagesDownloaded === false) {
                            globals.appLogosDownloaded = false;
                        }
                    });
                }
            });
    }
    
    return dfd.promise();
}



/**
    * Wraps two method calls to combine the process of retrieving, converting and then storing images as dataUrls in the DB.
    * @method convertAndAddImageToDB
    * @param {String} url The url of the image to be retrieved and stored.
    * @param {String} pack The pack that the image is associated with.
    */
function convertAndAddImageToDB(url, pack) {
    var dfd = $.Deferred();

    var imageFormat = getImageFormat(url);
    convertImgToBase64(url, imageFormat)
        .then(function (dataUrl) {
            if (dataUrl) {
                localDL.putImageInDb(dataUrl, url, pack)
                .then(function () {
                    alreadyLoadedImages.push(url);
                    dfd.resolve();
                })
                .fail(function () {
                    dfd.reject();
                });
            } else {
                dfd.reject();
            }
            
        });

    return dfd.promise();
}

/**
    * Helper function for extracting the image file format from a given image url.
    * @method getImageFormat
    * @param {String} url The url of the image to figure out the format of.
    */
function getImageFormat(url) {
    var imageFormat, urlParts, fileExt;

    urlParts = url.split("/");
    fileExt = ((urlParts[urlParts.length - 1]).split("."))[1];
    switch (fileExt) {
        case "png":
            imageFormat = "image/png";
            break;
        case "jpg":
        case "jpeg":
            imageFormat = "image/jpeg";
            break;
        default:
            imageFormat = "image/png";
    }
    return imageFormat;
}

/**
    * Determines whether or not the app needs to diaplay a warning about the number of licence days the user has left, based on the Licence Lease Expiry Date stored in the DB.
    * @method checkLicenceDays
    * @return {Boolean} Returns true or false depending on whether licence warning should be displayed. If true it also returns the number of day remaining. 
    */
function checkLicenceDays() {
    // check stored value for when licence will expire to determine if the user needs to be notified
    var dfd = $.Deferred();

    localDL.getFromCache(OPERATION.LicenceLease, localDL.PROPERTY.LicenceLeaseExpiryDate)
        .then(function (storedData) {
            if (storedData) {
                var now = Date.now();
                var remaining = storedData - now;
                var days = Math.round(remaining / config.millisInDay);
                if (days >= 0 && days <= config.noOfDaysLicenceExpiryMessageShows) {
                    dfd.resolve(true, days);
                } else {
                    dfd.resolve(false);
                }   
            }
        });

    return dfd.promise();
}

/**
* Wrapper function for accessing localDL cache.
* @method retrieve
* @param {String} operation The operation being carried out.
* @param {String} keyOrProp The key of the data or name of the property to be retrieved.
*/
function retrieve(operation, keyOrProp) {
    
    switch (operation) {
        case OPERATION.ConfigDrawing:
            return retrieveImage(keyOrProp);
        default:
            return localDL.getFromCache(operation, keyOrProp).promise();
    }
    
}

/**
    * Wraps localDL getImageIfInDB without trying to download image if not found.
    * @method retrieveImage
    * @param {String} imageKeyOrUrl The key/url of the image to be retrieved.
    */
function retrieveImage(imageKeyOrUrl) {
    var dfd = $.Deferred();

    localDL.getImageIfInDB(imageKeyOrUrl)
        .then(function(urlOrKey, imageDataUrl){
            if(imageDataUrl === undefined) {
                dfd.resolve(null);
            }else {
                dfd.resolve(imageDataUrl);
            }
        });

    return dfd.promise();
}

/**
    * Retrieves all customers and associated offers/notes/actions/subordinates for a given user to facilitate caching and faster load time first time into offers screen. Sets a flag to indicate it is working to
    * prevent the same call being repeated if the user enters the screen before the operation has returned.
    * @method preloadOffers
    * @param {Number} userId The id of the user/salesperson whose customers details are to be retrieved.
    */
function preloadOffers(userId) {
    
    var preloadOffersDfd = $.Deferred();

    var offerIdsToRetrieve = [];
    
    var request = 'customers/sync';
    var operation = OPERATION.Sync;

    var SyncObject = {
        UserId: userId,
        Customers: null
    };

    backgroundOfferRetrievalFailed.value = false;
    doingBackgroundOfferCaching.value = true;
    doingBackgroundOfferRetrieval.value = true;

    var doingBackgroundOfferCachingPreloadOffersSubscriptionRef = watch(doingBackgroundOfferCaching, function (newValue) {
        if (newValue === false) {
            doingBackgroundOfferCachingPreloadOffersSubscriptionRef();
            //preloadOffersDfd.resolve();
        }
    });

    localDL.getCustomersSyncList().then(function (syncList) {
        if (syncList.syncList.length > 0) {
            SyncObject.Customers = syncList.syncList;
        }
        handleDataRequest(null, request, operation, true, SyncObject)
            .then(function (custData) {
                customersResult = custData.UpdatedCustomers;
                preloadOffersDfd.resolve({ data: { Customers: mergeCustomerArrays(syncList.fullCustomers, customersResult) } });
                doingBackgroundOfferRetrieval.value = false;
                if (backgroudOfferRetrievalRetryComputedSubscriptionRef !== null) {
                    backgroudOfferRetrievalRetryComputedSubscriptionRef();
                    backgroudOfferRetrievalRetryComputedSubscriptionRef = null;
                }
                
            })
            .fail(function (error) {
                if (backgroudOfferRetrievalRetryComputedSubscriptionRef === null) {
                    backgroudOfferRetrievalRetryComputedSubscriptionRef = watch(backgroudOfferRetrievalRetryComputed, function (newValue) {
                        if (newValue === true) {
                            if (preloadOffersRetryCount < 3) {
                                preloadOffersRetryCount++;
                                preloadOffers(0);
                            } else {
                                doingBackgroundOfferCachingPreloadOffersSubscriptionRef();
                                doingBackgroundOfferRetrieval.value = false;
                                doingBackgroundOfferCaching.value = false;
                                if (backgroudOfferRetrievalRetryComputedSubscriptionRef !== null) {
                                    backgroudOfferRetrievalRetryComputedSubscriptionRef();
                                    backgroudOfferRetrievalRetryComputedSubscriptionRef = null;
                                }
                                log("dataMaanager.preloadOffers, Preloading of offers failed", error, 'dataManager', config.LOG_MESSAGE_TYPE.ERROR);
                                preloadOffersDfd.reject(error);
                            }
                            
                        }
                    });
                }
                
                backgroundOfferRetrievalFailed.value = true;
            });
    });

    function mergeCustomerArrays(localCustomers, updatedCustomers) {
        
        if (localCustomers.length === 0) {
            return updatedCustomers;
        } else if(localCustomers.length !== 0 && updatedCustomers.length === 0) {
            return localCustomers;
        } else {
            var mergedArray = [];
            localCustomers.forEach(function (localCust) {

                var updatedMatched = false;

                updatedCustomers.forEach(function (updatedCust) {
                    if (localCust.Id === updatedCust.Id) {
                        mergedArray.push(updatedCust);
                        updatedMatched = true;
                    }
                });
                if (updatedMatched === false) {
                    mergedArray.push(localCust);
                }
            });
            return mergedArray;
        }
    }

    return preloadOffersDfd.promise();
}

/**
    * Handles version management for graphic, map, common, vehicle, company and onRoadCosts packs/distribution groups. The lastest version information for each of these packs is sent down everytime there is an 
    * authorise. The latest version value is checked against the locally stored value and if the version has increased one or more actions is carried out such as purging old data and downloading the new data.
    * @method handleVersions
    * @param {Object} latestVersions The latest version numbers for all of the packs handled by version control.
    */
function handleVersions(latestVersions) {
    var dfd = $.Deferred();
    TScMessenger.writeTimerMessage('In handleVersions');
    retrieve(OPERATION.LatestVersions, localDL.PROPERTY.Versions)
        .then(function (storedData) {
            var handleVersionsPromises = [];
            var slowTasks = [];
            var needToSaveVersions = false;
            var isFirstTime = true;
            var request;
            if (storedData) {
                TScMessenger.writeTimerMessage('In handleVersions, has stored data');
                isFirstTime = false;
                

                var latestVal = getVersionVal(latestVersions, "graphicVersion");
                var curStoredVal = getVersionVal(storedData, "graphicVersion");
                if (curStoredVal < latestVal) {
                    log("Graphic version changed ", null, 'dataManager', config.LOG_MESSAGE_TYPE.INFO);
                    needToSaveVersions = true;
                    forceGraphicsRefresh(localDL.PACK.Graphic);
                    replaceReportImageUrls().then(function () {
                        globals.user.updateUser({ reportGraphics: latestReportGraphicsFromLogin });
                    });
                }

                latestVal = getVersionVal(latestVersions, "mapVersion");
                curStoredVal = getVersionVal(storedData, "mapVersion");
                if (curStoredVal < latestVal) {
                    log("Map version changed ", null, 'dataManager', config.LOG_MESSAGE_TYPE.INFO);
                    needToSaveVersions = true;
                    localDL.clearStore(STORE.NewOffers);
                    localDL.resetVehiclesOfferCachedFlag();
                }
                
                latestVal = getVersionVal(latestVersions, "vehicleVersion");
                curStoredVal = getVersionVal(storedData, "vehicleVersion");
                if (curStoredVal < latestVal) {
                    log("Vehicle version changed ", null, 'dataManager', config.LOG_MESSAGE_TYPE.INFO);
                    needToSaveVersions = true;
                    
                    request = 'offers/newoffers?application=&vehicleType=&vehicleTypeCode=&axleLayout=&make=&range=&pageNumber=1&pageSize=30';
                    localDL.clearStore(STORE.Vehicles);
                    localDL.clearStore(STORE.NewOffers);
                    localDL.clearProperty(PROPERTY.VehiclePackData);
                    if(globals.selectionListDfd === null) {
                        globals.selectionListDfd = $.Deferred();
                    }
                    doingBackgroundSelectionCaching.value = true;
                    doingBackgroundSelectionRetrieval.value = true;
                    handleVersionsPromises.push(handleDataRequest(null, request, OPERATION.GetSelectionList, true).then(function (temp) {
                        selectionListCallback(temp);
                        doingBackgroundSelectionRetrieval.value = false;
                        if(globals.selectionListDfd !== null) {
                            globals.selectionListDfd.resolve({ selection: JSON.parse(JSON.stringify(temp))});
                            globals.selectionListDfd = null;
                        }
                    }));
                    
                }

                TScMessenger.writeDebugMessage('In handleVersions, checking Common Version');
                latestVal = getVersionVal(latestVersions, "commonVersion");
                curStoredVal = getVersionVal(storedData, "commonVersion");
                if (curStoredVal < latestVal) {
                    TScMessenger.writeDebugMessage('In handleVersions, Common Version changed, clearing local cache entries and retrieving fresh');
                    log("Common version changed ", null, 'dataManager', config.LOG_MESSAGE_TYPE.INFO);
                    needToSaveVersions = true;
                    
                    localDL.clearProperty(PROPERTY.CommonPackDetails); 
                    localDL.clearProperty(PROPERTY.LegislationDetails);
                    localDL.clearStore(STORE.NewOffers);
                    localDL.clearProperty(PROPERTY.LicenceCategories);

                    request = 'commonpackdetails/get?customerDistributionGroupId=' + globals.user.getCdg() + "&databaseAttributeIds=" + config.databaseAttributesForTranslation;
                    handleVersionsPromises.push(handleDataRequest(null, request, OPERATION.GetCommonPackDetails, true));
                    
                    if (globals.user.hasPermission(config.PERMISSIONS.COSTING.Code)) {

                        request = OPERATION.GetLicenceCategories.BaseUrl + '?companyId=' + globals.user.getCompanyId();
                        handleVersionsPromises.push(handleDataRequest(null, request, OPERATION.GetLicenceCategories.Code, true));

                    }
                }

                latestVal = getVersionVal(latestVersions, "onroadVersion");
                curStoredVal = getVersionVal(storedData, "onroadVersion");
                if (curStoredVal < latestVal) {
                    log("OnRoad version changed ", null, 'dataManager', config.LOG_MESSAGE_TYPE.INFO);
                    needToSaveVersions = true;
                    request = 'onroadcosts/get?customerDistributionGroupId=' + globals.user.getCdg();
                    localDL.clearProperty(PROPERTY.OnRoadCosts);
                    localDL.clearStore(STORE.NewOffers);
                    handleVersionsPromises.push(handleDataRequest(null, request, OPERATION.GetOnRoadCosts, true));
                }

                latestVal = getVersionVal(latestVersions, "appConfigVersion");
                curStoredVal = getVersionVal(storedData, "appConfigVersion");
                if (curStoredVal < latestVal) {
                    log("AppConfig version changed ", null, 'dataManager', config.LOG_MESSAGE_TYPE.INFO);
                    needToSaveVersions = true;
                    request = OPERATION.GetAppConfig.BaseUrl;
                    localDL.clearProperty(PROPERTY.AppConfig);
                    handleVersionsPromises.push(handleDataRequest(null, request, OPERATION.GetAppConfig.Code, true));
                }

                latestVal = getVersionVal(latestVersions, "accessoryVersion");
                curStoredVal = getVersionVal(storedData, "accessoryVersion");
                if (curStoredVal < latestVal) {
                    log("Accessory version changed ", null, 'dataManager', config.LOG_MESSAGE_TYPE.INFO);
                    needToSaveVersions = true;
                    
                    localDL.clearStore(STORE.Bodies);
                    localDL.clearStore(STORE.Accessories);
                    localDL.clearStore(STORE.Trailers);
                    localDL.clearStore(STORE.Payloads);
                    localDL.clearProperty(PROPERTY.BodyMasses);
                    doingBackgroundBodiesRetrieval.value = true;
                    doingBackgroundBodyStubsCaching.value = true;
                    
                    request = 'accessories/allbodystubs';
                    slowTasks.push(getNewTask(OPERATION.GetBodyStubs, request));

                    doingBackgroundAccessoriesRetrieval.value = true;
                    doingBackgroundAccessoriesStubsCaching.value = true;
                    
                    request = 'accessories/allaccessorystubs';
                    slowTasks.push(getNewTask(OPERATION.GetAccessoryStubs, request));

                    doingBackgroundTrailersRetrieval.value = true;
                    doingBackgroundTrailerStubsCaching.value = true;
                    request = 'accessories/alltrailerstubs';
                    slowTasks.push(getNewTask(OPERATION.GetTrailerStubs, request));

                    doingBackgroundPayloadsRetrieval.value = true;
                    doingBackgroundPayloadStubsCaching.value = true;
                    request = 'accessories/allpayloadstubs';
                    slowTasks.push(getNewTask(OPERATION.GetPayloadStubs, request));

                    request = OPERATION.GetBodyMasses.BaseUrl;
                    handleVersionsPromises.push(handleDataRequest(null, request, OPERATION.GetBodyMasses.Code, true));
                    
                }
            } else {
                TScMessenger.writeTimerMessage('In handleVersions, no stored data');
                needToSaveVersions = true;
                if(globals.selectionListDfd === null) {
                    globals.selectionListDfd = $.Deferred();
                }
                doingBackgroundSelectionCaching.value = true;
                doingBackgroundSelectionRetrieval.value = true;
                request = 'offers/newoffers?application=&vehicleType=&vehicleTypeCode=&axleLayout=&make=&range=&pageNumber=1&pageSize=30';
                handleVersionsPromises.push(handleDataRequest(null, request, OPERATION.GetSelectionList, true).then(function (temp) {
                    selectionListCallback(temp);
                    doingBackgroundSelectionRetrieval.value = false;
                    if(globals.selectionListDfd !== null) {
                        globals.selectionListDfd.resolve({ selection: JSON.parse(JSON.stringify(temp))});
                        globals.selectionListDfd = null;
                    }
                }));
                
                request = 'commonpackdetails/get?customerDistributionGroupId=' + globals.user.getCdg() + "&databaseAttributeIds=" + config.databaseAttributesForTranslation;
                handleVersionsPromises.push(handleDataRequest(null, request, OPERATION.GetCommonPackDetails, true));
                
                request = 'onroadcosts/get?customerDistributionGroupId=' + globals.user.getCdg();
                handleVersionsPromises.push(handleDataRequest(null, request, OPERATION.GetOnRoadCosts, true));
                
                request = OPERATION.GetAppConfig.BaseUrl;
                handleVersionsPromises.push(handleDataRequest(null, request, OPERATION.GetAppConfig.Code, true));
                
                doingBackgroundBodiesRetrieval.value = true;
                doingBackgroundBodyStubsCaching.value = true;
                
                request = 'accessories/allbodystubs';
                slowTasks.push(getNewTask(OPERATION.GetBodyStubs, request));

                doingBackgroundAccessoriesRetrieval.value = true;
                doingBackgroundAccessoriesStubsCaching.value = true;
                
                request = 'accessories/allaccessorystubs';
                slowTasks.push(getNewTask(OPERATION.GetAccessoryStubs, request));
                
                doingBackgroundTrailersRetrieval.value = true;
                doingBackgroundTrailerStubsCaching.value = true;
                request = 'accessories/alltrailerstubs';
                slowTasks.push(getNewTask(OPERATION.GetTrailerStubs, request));

                doingBackgroundPayloadsRetrieval.value = true;
                doingBackgroundPayloadStubsCaching.value = true;
                request = 'accessories/allpayloadstubs';
                slowTasks.push(getNewTask(OPERATION.GetPayloadStubs, request));

                doingBackgroundSharedOfferStubsRetrieval.value = true;
                doingBackgroundSharedOfferStubsCaching.value = true;
                request = OPERATION.GetSharedOfferStubs.BaseUrl;
                slowTasks.push(getNewTask(OPERATION.GetSharedOfferStubs.Code, request));

                request = OPERATION.GetBodyMasses.BaseUrl;
                handleVersionsPromises.push(handleDataRequest(null, request, OPERATION.GetBodyMasses.Code, true));

                if (globals.user.hasPermission(config.PERMISSIONS.COSTING.Code)) {

                    request = OPERATION.GetLicenceCategories.BaseUrl + '?companyId=' + globals.user.getCompanyId();
                    handleVersionsPromises.push(handleDataRequest(null, request, OPERATION.GetLicenceCategories.Code, true));
                }
            }

            if (needToSaveVersions === true) {
                handleVersionsPromises.push(localDL.addToCache(OPERATION.LatestVersions, localDL.PROPERTY.Versions, latestVersions));

            }

            

            if (handleVersionsPromises.length > 0) {
                $.when.apply($, handleVersionsPromises)
                //performTasks(handleVersionsPromises)
                    .done(function () {
                        if (doingBackgroundOverviewRetrieval.value === true) {
                            doingBackgroundOverviewRetrieval.value = false;
                        }
                        if (isFirstTime === true) {
                            replaceReportImageUrls().then(function () {
                                globals.user.updateUser({ reportGraphics: latestReportGraphicsFromLogin });
                            });
                        }
                        performTasks(slowTasks);
                        standardHandleVersionsTasksComplete.value = true;
                        dfd.resolve(needToSaveVersions);//passing need to save versions back to caller, true means there has been data/config updates
                    })
                    .fail(function (error) {
                        if(globals.selectionListDfd !== null) {
                            // globals.selectionListDfd.reject(error);???
                            globals.selectionListDfd = null;
                        }
                        dfd.reject(error);
                    });
            } else {
                standardHandleVersionsTasksComplete.value = true;
                slowHandleVersionsTasksComplete.value = true;
                dfd.resolve(needToSaveVersions);
            }
            
        });

    return dfd.promise();

    function performTasks(tasks) {
        var taskDfd = $.Deferred();

        //var tasks = [];
        //addTask(dataManager.loadProperties);
        //addTask(dataManager.doDebugLogMaintenanceIfRequired);

        doTask(tasks);

        function doTask(taskArr) {
            if (taskArr.length > 0) {
                var curTask = taskArr.shift();
                curTask.taskFunc().then(function (data) {
                    switch (curTask.op) {
                        case OPERATION.GetSelectionList:
                            selectionListCallback(data);
                            break;
                        case OPERATION.GetBodyStubs:
                            bodiesResult = data;
                            doingBackgroundBodiesRetrieval.value = false;
                            break;
                        case OPERATION.GetAccessoryStubs:
                            accessoriesResult = data;
                            doingBackgroundAccessoriesRetrieval.value = false;
                            break;
                        case OPERATION.GetTrailerStubs:
                            trailersResult = data;
                            doingBackgroundTrailersRetrieval.value = false;
                            break;
                        case OPERATION.GetPayloadStubs:
                            payloadsResult = data;
                            doingBackgroundPayloadsRetrieval.value = false;
                            break;
                        case OPERATION.GetSharedOfferStubs.Code:
                            sharedOfferStubsResult = data;
                            doingBackgroundSharedOfferStubsRetrieval.value = false;
                            sharedOfferSyncHandlerCallback(data);
                            break;
                    }
                    doTask(taskArr);
                });
            } else {
                slowHandleVersionsTasksComplete.value = true;
                taskDfd.resolve();
            }

        }

        //function addTask(task) {
        //    tasks.push(function () { return task(); });
        //}

        return taskDfd.promise();
    }

    function getNewTask(op, request) {
        return { op: op, taskFunc: function () { return handleDataRequest(null, request, op, true); } };
    }
}

function selectionListCallback(data) {
    selectionListResult = data;
}

function setOfflineValidUntilIfNeeded() {
    var dfd = $.Deferred();

    if (navigator.onLine === false) {
        setAppropriateValidUntil().then(function () {
            dfd.resolve();
        });
    } else {
        dfd.resolve();
    }

    return dfd.promise();
}

function setAppropriateValidUntil() {
    var dfd = $.Deferred();
    
    localDL.getFromCache(OPERATION.LicenceLease, localDL.PROPERTY.LicenceLeaseExpiryDate)
                    .then(function (storedData) {
                        if (storedData) {
                            var date = new Date(parseInt(storedData));
                            globals.user.setValidUntil(moment(date).format("M/D/YYYY h:mm:ss a"), "offline");
                        }
                        dfd.resolve();
                    }).fail(function () {
                        dfd.resolve();
                    });
    return dfd.promise();
}

/**
    * Helper function for handleVersions to retrieve a specific version value from the versions object.
    * @method getVersionVal
    * @param {Object} objectGroup The object containing the version values.
    * @param {String} keyToGet The specific version value to get.
    * @return {Number} Returns the requested version value or null if it can't find it. 
    */
function getVersionVal(objectGroup, keyToGet) {
    for (var key in objectGroup) {
        if (objectGroup[key]) {
            if (typeof objectGroup[key] === 'object') {
                if (objectGroup[key]["Key"] === keyToGet) {
                    return objectGroup[key]["Value"];
                }
                
            }
        }
    }
    return null;
}

/**
    * Wrapper function for caching items to localDL cache.
    * @method persist
    * @param {String} operation The operation being carried out.
    * @param {Object} data The data to be cached.
    * @param {String} propOrKey The key to cache the data under.
    */
function persist(operation, data, propOrKey) {

    switch (operation) {
        case OPERATION.ConfigDrawing:
            return localDL.putConfigDrawingInDb(data, propOrKey, localDL.PACK.Vehicle);
        default:
            localDL.addToCache(operation, propOrKey, data);
            break;
    }
    
}

/**
    * Refreshes all graphics for a given pack by first deleting all graphics and then kicing off a job to retrieve and store all the images again.
    * @method forceGraphicsRefresh
    * @param {String} pack The pack whose graphics are to be refreshed.
    */
function forceGraphicsRefresh(pack) {
    var urlsArr = [];
    localDL.getObjectStore(localDL.STORE.Images, "readonly")
        .then(function (objectStore) {
            var cursorRequest = objectStore.openCursor();
            cursorRequest.onsuccess = function (event) {
                if (event.target.result) {
                    if (event.target.result.value["pack"] === pack) {
                        urlsArr.push(event.target.result.value["imageurl"]);
                    }
                    event.target.result['continue']();
                } else {
                    if (urlsArr.length > 0) {
                        loadAllImages(urlsArr, pack);
                    }
                }
            };

            cursorRequest.onerror = function (event) {

            };

        });
}

/**
    * Wrapper method for removing items/records from localDL cache. Please note currently only supports removing the configuration drawing.
    * @method remove
    * @param {String} operation The operation being carried out.
    * @param {String} propOrKey The key or property name of the item to be removed.
    */
function remove(operation, propOrKey) {
    switch (operation) {
        case OPERATION.ConfigDrawing:
            return localDL.deleteRecord(localDL.STORE.Images, propOrKey);
        case OPERATION.ClearPartialNewOfferDetails:
            return localDL.deleteRecord(localDL.STORE.NewOffers, propOrKey);
        case OPERATION.RemoveLocalBody:
            return localDL.deleteRecord(localDL.STORE.Bodies, propOrKey);
        case OPERATION.RemoveLocalAccessory:
            return localDL.deleteRecord(localDL.STORE.Accessories, propOrKey);
        case OPERATION.RemoveLocalTrailer:
            return localDL.deleteRecord(localDL.STORE.Trailers, propOrKey);
        case OPERATION.RemoveLocalPayload:
            return localDL.deleteRecord(localDL.STORE.Payloads, propOrKey);
        default:
            break;
    }
}

function isOutsideAuthentication(op) {
    if (op === OPERATION.SignUp || op === OPERATION.RequestTrialExtension || 
        op === OPERATION.PostLoginDebug || op === OPERATION.CheckIsOnline.Code || 
        op === OPERATION.PostScreenSizeDebug || op === OPERATION.ValidateAccessToken) {
        return true;
    }
    return false;
}

/**
    * Method that manages retrieving data. 
    * @method handleDataRequest
    * @param {Function} local The localDL function call to be used to retrieve the data locally.
    * @param {String} request The request url to use to retrieve the data online.
    * @param {String} operation The operation bing carried out.
    * @param {Boolean} forceOnline A boolean flag indicating whether or not the operation should skip the local check and do online check only.
    * @param {Object} optionalData The optional payload data to be sent up in a POST.
    * @return {Object} Returns data requested from either local cache or online operation, or error if both operations fail. 
    */
function handleDataRequest(local, request, operation, forceOnline, optionalData) {
    var dfd = $.Deferred();

    if ((typeof operation === 'string' && operation === config.OPERATION.SendIntercomEventData.Code && optionalData && optionalData.eventName && optionalData.eventName === config.INTERCOM_EVENT.LOGGED_OUT) || isOutsideAuthentication(operation) === true || security.securityTokenIsValid().IsValid === true) {
        //if (curOpTracker !== undefined) {
        //    curOpTracker.notifyRetrievalStarted(operation);
        //}
        if (forceOnline && forceOnline === true) {
            //call remote, if it fails then resolve error message
            
            tryRemote();
        } else {
            //call local/see if local available, if not call remote and if that fails resolve error message
            local()
                .then(handleSuccess)
                .fail(tryRemote);
        }
    } else {
        dfd.reject({errorMessage: 'unauthorised'});
    }
    
    function handleSuccess(data) {
        if ((data === undefined) || (data === null)) {
            tryRemote();
        } else {
            switch (operation) {
                case OPERATION.GetNewOfferDetails:
                    if (data.Offer.Brochure) {
                        dfd.resolve(data, true);
                    } else {
                        dfd.resolve(data);
                    }
                    
                    break;
                case OPERATION.GetNewOfferSpecificationDetails:
                    if (data.Specification === undefined) {
                        tryRemote();
                    } else {
                        dfd.resolve(data, true);
                    }
                    break;
                case OPERATION.GetExistingOfferDetails:
                case OPERATION.GetSharedOfferDetails.Code:
                    if (data.Offer.Brochure) {
                        dfd.resolve(data, true);
                    } else {
                        dfd.resolve(data);
                    }
                    break;
                    
                case OPERATION.GetExistingOfferSpecificationDetails:
                    if (data.Specification === undefined) {
                        tryRemote();
                    } else {
                        dfd.resolve(data, true);
                    }
                    break;
                case OPERATION.GetSelectionList:
                case OPERATION.GetSharedOfferStubs.Code:
                    if (data.Result.ReturnCode === -1) {
                        tryRemote();
                    } else {
                        dfd.resolve(data, true);
                    }
                    break;
                //case OPERATION.GetBodyStubs:
                //    if (data.Result.ReturnCode === -1 && globals.isOnline.value === true) {
                //        tryRemote();
                //    } else {
                //        dfd.resolve(data);
                //    }
                //    break;
                //case OPERATION.GetBody:
                //    if (data.cached === undefined || data.cached === 0) {
                //        if (globals.isOnline.value === true) {
                //            tryRemote();
                //        } else {
                //            dfd.reject({ errorMessage: 'Offline' });
                //        }
                        
                //    } else {
                //        dfd.resolve(data);
                //    }
                //    break;
                case OPERATION.GetBodyStubs:
                case OPERATION.GetAccessoryStubs:
                case OPERATION.GetTrailerStubs:
                case OPERATION.GetPayloadStubs:
                    if (data.Result.ReturnCode === -1 && globals.isOnline.value === true) {
                        tryRemote();
                    } else {
                        dfd.resolve(data);
                    }
                    break;
                case OPERATION.GetBody:
                case OPERATION.GetAccessory:
                case OPERATION.GetTrailer:
                case OPERATION.GetPayload:
                    if (data.cached === undefined || data.cached === 0) {
                        if (globals.isOnline.value === true) {
                            tryRemote();
                        } else {
                            dfd.reject({ errorMessage: 'Offline' });
                        }

                    } else {
                        dfd.resolve(data);
                    }
                    break;
                default:
                    dfd.resolve(data);
                    break;
            }
            
        }
        
    }

    function tryRemote() {

        function updateCachedStatusOfLocalNewOfferObject(vehicleId) {
                        
            localDL.getFromCache(OPERATION.GetNewOfferDetails, vehicleId)
            .then(function (offerData) {
                if (offerData !== null) {
                    offerData.Complete = 2;
            //{Type: '', Id: 0, Value: 0}

                var tempOp;
                if (globals.user.hasPermission(config.PERMISSIONS.SPECIFICATION.Code)) {
                    tempOp = OPERATION.GetNewOfferSpecificationDetails;
                } else {
                    tempOp = OPERATION.GetNewOfferDetails;
                }
                localDL.addToCache(tempOp, vehicleId, offerData);
                }
                
            });
        }
        function updateCachedStatusOfLocalExistingOfferObject(offerId) {
            localDL.getFromCache(operation, offerId)
            .then(function (offerData) {
                if (offerData !== null) {
                    offerData.Complete = 2;


                    var tempOp;
                    if (globals.user.hasPermission(config.PERMISSIONS.SPECIFICATION.Code)) {
                        tempOp = OPERATION.GetExistingOfferSpecificationDetails;
                    } else {
                        tempOp = operation;
                    }

                    localDL.addToCache(tempOp, offerId, offerData);
                }
            });
        }

        networkRequestsUnderway++;
        switch (operation) {
            case OPERATION.GetNewOfferDetails:
                allNewOfferImagesRetrieved.value = false;
                allNewSpecImagesRetrieved.value = false;
                newOfferDataCached.value = false;
                newSpecDataCached.value = false;

                newOfferDataAndImagesCachedSubscriptionRef = watch(allNewOfferAndSpecImagesAndDataCached, function (newValue) {
                    if (newValue === true) {
                        newOfferDataAndImagesCachedSubscriptionRef();
                        var vehicleId = selectedVehicleId;
                        recentlyCached.value = { Type: 'NEW', Id: selectedVehicleId, Value: 2 };
                        allNewOfferImagesRetrieved.value = false;
                        allNewSpecImagesRetrieved.value = false;
                        newOfferDataCached.value = false;
                        newSpecDataCached.value = false;
                        if (doingBackgroundSelectionCaching.value === false) {
                            updateCachedStatusOfLocalNewOfferObject(vehicleId);
                        } else {
                            if (selectionListResult !== null) {
                                try {
                                    //selection.Offers
                                    selectionListResult.Offers.forEach(function (offer) {
                                        if (offer.Id === vehicleId) {
                                            offer.OfferCached = 2;
                                        }
                                    });
                                }catch(selectionListUpdateEx) {
                                    TScMessenger.writeDebugMessage('Error updating cached on selection list in DM');
                                }
                            }
                            var doingBackgroundSelectionCachingSubscriptionRef = watch(doingBackgroundSelectionCaching, function (newValue) {
                                if (newValue === false) {
                                    doingBackgroundSelectionCachingSubscriptionRef();
                                    updateCachedStatusOfLocalNewOfferObject(vehicleId);
                                }
                            });
                        }

                        
                        

                    }
                });
                break;
            case OPERATION.GetExistingOfferDetails:
            case OPERATION.GetSharedOfferDetails.Code:
                allExistingOfferImagesRetrieved.value = false;
                allExistingSpecImagesRetrieved.value = false;
                existingOfferDataCached.value = false;
                existingSpecDataCached.value = false;
                allOriginalAccessoriesRetrievedObv.value = false;
                existingOfferDataAndImagesCachedSubscriptionRef = watch(allExistingOfferAndSpecImagesAndDataCached, function (newValue) {
                    if (newValue === true) {
                        existingOfferDataAndImagesCachedSubscriptionRef();
                        var offerId = selectedOfferId;
                        recentlyCached.value = { Type: 'SAVED', Id: selectedOfferId, Value: 2 };
                        allExistingOfferImagesRetrieved.value = false;
                        allExistingSpecImagesRetrieved.value = false;
                        existingOfferDataCached.value = false;
                        existingSpecDataCached.value = false;
                        allOriginalAccessoriesRetrievedObv.value = false;
                        if (doingBackgroundOfferCaching.value === false) {
                            //if (operation === OPERATION.GetExistingOfferDetails) {
                                updateCachedStatusOfLocalExistingOfferObject(offerId);
                            //}
                        } else {
                            if (operation === OPERATION.GetExistingOfferDetails) {
                                if (customersResult !== null) {
                                    try {
                                        //selection.Offers
                                        customersResult.forEach(function (customer) {
                                            customer.Offers.forEach(function (offerStub) {
                                                if (offerStub.Id === offerId) {
                                                    offerStub.OfferCached = 2;
                                                }
                                            })

                                        });
                                    } catch (customersUpdateEx) {
                                        TScMessenger.writeDebugMessage('Error updating cached on customers list in DM');
                                    }
                                }
                            } else {
                                if (sharedOfferStubsResult !== null) {
                                    try {
                                        sharedOfferStubsResult.Offers.forEach(function (offerStub) {
                                            if (offerStub.Id === offerId) {
                                                offerStub.OfferCached = 2;
                                            }
                                        })
                                    }catch(sharedOfferStubsUpdateEx) {
                                        TScMessenger.writeDebugMessage('Error updating cached on shared offers list in DM');
                                    }
                                }
                            }
                            var doingBackgroundOfferCachingSubscriptionRef = watch(doingBackgroundOfferCaching, function (newValue) {
                                if (newValue === false) {
                                    doingBackgroundOfferCachingSubscriptionRef();
                                    //if (operation === OPERATION.GetExistingOfferDetails) {
                                        updateCachedStatusOfLocalExistingOfferObject(offerId);
                                    //}
                                }
                            });
                        }

                        
                        

                    }
                });
                break;
        }
        remoteDL.handleRequest(security, request, operation, optionalData)
            .then(handleRemoteSuccess)
            .fail(handleRemoteFailure);

        
    }

    function handleRemoteSuccess(data) {
        networkRequestsUnderway--;
        try {
            var dontResolve = false;
            var forceCacheComplateWhenNoBrochure = false;
            //if (curOpTracker !== undefined) {
            //    curOpTracker.notifyRetrievalFinished(operation);
            //}
            var tempItems, tempItem;
            var i, j;
            switch (operation) {
                case OPERATION.GetNewOfferDetails:
                    if (data.Result.ReturnCode > 0) {
                        if (data.Offer && data.Offer.Brochure) {
                            
                            var newOfferUrlsToRetrieve = [];
                            for (i = 0; i < data.Offer.Brochure.VehicleBrochureItemGroups.length; i++) {
                                tempItems = data.Offer.Brochure.VehicleBrochureItemGroups[i].VehicleBrochureItems;
                                for (j = 0; j < tempItems.length; j++) {
                                    tempItem = tempItems[j];
                                    if (tempItem["HTML"] !== undefined) {
                                        newOfferUrlsToRetrieve.push(getImageSrc(tempItem["HTML"]));
                                    }
                                }
                            }
                            for (i = 0; i < data.Offer.Brochure.VehicleBrochureItemGroups.length; i++) {
                                newOfferUrlsToRetrieve.push(data.Offer.Brochure.VehicleBrochureItemGroups[i].IconPictureURL);
                            }
                            newOfferUrlsToRetrieve.push(data.Offer.VehiclePlusBodyImageURL);
                            loadAllImages(newOfferUrlsToRetrieve, localDL.PACK.Vehicle, function (allImagesDownLoaded) {
                                allNewOfferImagesRetrieved.value = allImagesDownLoaded;
                            });
                        } else {
                            forceCacheComplateWhenNoBrochure = true;
                        }

                        localDL.addToCache(operation, data.Offer.VehicleId, data)
                            .then(function () {
                                newOfferDataCached.value = true;
                                if (forceCacheComplateWhenNoBrochure === true) {
                                    allNewOfferImagesRetrieved.value = true;
                                }
                            });
                        if (data.Offer.DataAvailability.ActiveSpecification !== true || (globals.user.hasPermission(config.PERMISSIONS.SPECIFICATION.Code) === false && !globals.user.allowSpecificationModule())) {
                            newSpecDataCached.value = true;
                            allNewSpecImagesRetrieved.value = true;
                        }
                    }
                    break;
                case OPERATION.GetNewOfferSpecificationDetails:
                    var newSpecUrlsToRetrieve = [];
                    pushVehicleImageUrlsOntoArray(data.Specification.BaseVehicle, newSpecUrlsToRetrieve);
                    for (i = 0; i < data.Specification.CompetitorVehicles.length; i++) {
                        pushVehicleImageUrlsOntoArray(data.Specification.CompetitorVehicles[i], newSpecUrlsToRetrieve);
                    }

                    loadAllImages(newSpecUrlsToRetrieve, localDL.PACK.Vehicle, function (allImagesDownLoaded) {
                        allNewSpecImagesRetrieved.value = allImagesDownLoaded;
                    });
                    localDL.addToCache(operation, data.Advantages.VehicleId, data)
                        .then(function () {
                            newSpecDataCached.value = true;
                        });
                    break;
                case OPERATION.GetExistingOfferDetails:
                case OPERATION.GetSharedOfferDetails.Code:
                    if (data.Result.ReturnCode > 0) {
                        selectedOfferId = data.Changes.Id;
                        if (data.Offer && data.Offer.Brochure) {

                            var existingOfferUrlsToRetrieve = [];
                            

                            for (i = 0; i < data.Offer.Brochure.VehicleBrochureItemGroups.length; i++) {
                                tempItems = data.Offer.Brochure.VehicleBrochureItemGroups[i].VehicleBrochureItems;
                                for (j = 0; j < tempItems.length; j++) {
                                    tempItem = tempItems[j];
                                    if (tempItem["HTML"] !== undefined) {
                                        var tempUrl = getImageSrc(tempItem["HTML"]);
                                        existingOfferUrlsToRetrieve.push(tempUrl);
                                    }
                                }
                            }
                            for (i = 0; i < data.Offer.Brochure.VehicleBrochureItemGroups.length; i++) {
                                var tmpBrochureIconUrl = data.Offer.Brochure.VehicleBrochureItemGroups[i].IconPictureURL;
                                existingOfferUrlsToRetrieve.push(tmpBrochureIconUrl);
                            }
                            existingOfferUrlsToRetrieve.push(data.Offer.VehiclePlusBodyImageURL);
                            loadAllImages(existingOfferUrlsToRetrieve, localDL.PACK.Vehicle, function (allImagesDownLoaded) {
                                allExistingOfferImagesRetrieved.value = allImagesDownLoaded;
                            });
                        } else {
                            forceCacheComplateWhenNoBrochure = true;
                        }

                        localDL.addToCache(OPERATION.GetExistingOfferDetails, data.Changes.Id, data)
                            .then(function () {
                                existingOfferDataCached.value = true;
                                if (forceCacheComplateWhenNoBrochure === true) {
                                    allExistingOfferImagesRetrieved.value = true;
                                }
                            });
                        if (data.Offer.DataAvailability.ActiveSpecification !== true || (globals.user.hasPermission(config.PERMISSIONS.SPECIFICATION.Code) === false && !globals.user.allowSpecificationModule())) {
                            existingSpecDataCached.value = true;
                            allExistingSpecImagesRetrieved.value = true;
                        }
                    }
                    break;
                case OPERATION.GetExistingOfferSpecificationDetails:
                    var existingSpecUrlsToRetrieve = [];
                    pushVehicleImageUrlsOntoArray(data.Specification.BaseVehicle, existingSpecUrlsToRetrieve);
                    for (i = 0; i < data.Specification.CompetitorVehicles.length; i++) {
                        pushVehicleImageUrlsOntoArray(data.Specification.CompetitorVehicles[i], existingSpecUrlsToRetrieve);
                    }

                    loadAllImages(existingSpecUrlsToRetrieve, localDL.PACK.Vehicle, function (allImagesDownLoaded) {
                        allExistingSpecImagesRetrieved.value = allImagesDownLoaded;
                    });
                    break;
                case OPERATION.Sync:
                    for (i = 0; i < data.UpdatedCustomers.length; i++) {
                        for (j = 0; j < data.UpdatedCustomers[i].Offers.length; j++) {
                            data.UpdatedCustomers[i].Offers[j].OfferCached = 0;
                        }
                    }
                    if (data.UpdatedCustomers.length > 0) {
                        localDL.clearCustomersCachedOffers(data.UpdatedCustomers.slice());
                    }
                    dfd.resolve(data);
                    $.when(localDL.queueBatchJob(OPERATION.SaveCustomer.Code, data.UpdatedCustomers), localDL.queueBatchJob(OPERATION.SalesPerson, data.Users), localDL.queueBatchJob(OPERATION.DeleteCustomer.Code, data.DeletedCustomers))
                        .done(function () {
                            doingBackgroundOfferCaching.value === false;
                        });
                    return dfd.promise();
                case OPERATION.GetSelectionList:
                    //var vehiclePromises = [];
                    if (data.VehiclePack !== undefined && data.VehiclePack !== null) {
                        localDL.addToCache(OPERATION.GetVehiclePackData, PROPERTY.VehiclePackData, data.VehiclePack);
                    }
                
                    requestedImages = [];
                    var vehicleImagesToRetrieve = [];
                    for (var vehicleCounter = 0; vehicleCounter < data.Offers.length; vehicleCounter++) {
                        var vehicle = data.Offers[vehicleCounter];
                        vehicle.OfferCached = shallowRef(0);
                        //#note, this code is commented out as we are no longer retrieving selection list images and so it is unnecessary
                        //pushSelectionVehicleImageUrlsOntoArray(vehicle, vehicleImagesToRetrieve);
                    }
                    globals.user.setNumberOfOffersFromServer(data.Offers.length);
                    dfd.resolve(data);
                    doingBackgroundSelectionCaching.value = true;
                    $.when($, localDL.queueBatchJob(operation, data.Offers))
                        .done(function () {
                            doingBackgroundSelectionCaching.value = false;
                        });
                    
                    //curOpTracker.registerImageRetrievalStarted(OPERATION.GetSelectionList);????
                    //#note, this code is commented out as we are no longer retrieving selection list images and so it is unnecessary
                    //loadAllImages(vehicleImagesToRetrieve, localDL.PACK.Vehicle, function (allImagesDownLoaded) {
                    //    //curOpTracker.registerAllImagesRetrieved(OPERATION.GetSelectionList);
                    //    //alert("All selection vehicle images downloaded");
                    //    //var breakPoint = 0;
                    //});
                    return dfd.promise();
                case OPERATION.GetCommonPackDetails:
                    localDL.addToCache(OPERATION.GetCommonPackDetails, PROPERTY.CommonPackDetails, data);
                    break;
                case OPERATION.GetOnRoadCosts:
                
                    //curOpTracker.registerNumRecordsToCache(data.onRoadCosts.length, OPERATION.GetOnRoadCosts);
                    localDL.addToCache(OPERATION.GetOnRoadCosts, PROPERTY.OnRoadCosts, data);
                    break;
                case OPERATION.GetAppConfig.Code:
                    localDL.addToCache(OPERATION.GetAppConfig.Code, PROPERTY.AppConfig, data);
                    break;
                case OPERATION.GetLegislationDetails:
                    localDL.addToCache(OPERATION.GetLegislationDetails, PROPERTY.LegislationDetails, data);
                    break;
                case OPERATION.GetUserSettings:
                    localDL.addToCache(OPERATION.GetUserSettings, PROPERTY.UserSettings, data);
                    break;
                case OPERATION.GetBodyStubs:
                    if (data.Bodies !== null && data.Bodies.length > 0) {
                        data.Bodies.forEach(function (bodyStub) {
                            bodyStub.cached = 0;
                        });
                        globals.user.setNumberOfBodiesFromServer(data.Bodies.length);
                        dfd.resolve(data);
                        $.when($, localDL.queueBatchJob(operation, data.Bodies))
                        .done(function () {
                            doingBackgroundBodyStubsCaching.value = false;
                        });
                    } else {
                        dfd.resolve(data);
                        doingBackgroundBodyStubsCaching.value = false;
                    }

                    return dfd.promise();
                case OPERATION.GetBody:
                    if (data.Result.ReturnCode === 1 && preventCaching === false) {
                        localDL.addToCache(OPERATION.GetBody, data.Body.Id + '_' + data.Body.AccessoryType + '_' + data.Body.Source, data.Body);
                    }
                    break;
                case OPERATION.GetAccessoryStubs:
                    if (data.Accessories !== undefined && data.Accessories !== null && data.Accessories.length !== undefined && data.Accessories.length > 0) {
                        data.Accessories.forEach(function (accessoryStub) {
                            accessoryStub.cached = 0;
                        });
                        globals.user.setNumberOfAccessoriesFromServer(data.Accessories.length);
                        dfd.resolve(data);
                        $.when($, localDL.queueBatchJob(operation, data.Accessories))
                            .done(function () {
                                doingBackgroundAccessoriesStubsCaching.value = false;
                            });
                    } else {
                        dfd.resolve(data);
                        doingBackgroundAccessoriesStubsCaching.value = false;
                    }
                    return dfd.promise();
                case OPERATION.GetAccessory:
                    if (data.Result.ReturnCode === 1) {
                        data.Accessory = JSON.parse(data.Accessory);
                        if (preventCaching === false) {
                            localDL.addToCache(OPERATION.GetAccessory, data.Accessory.Id + '_' + data.Accessory.AccessoryType + '_' + data.Accessory.Source, data.Accessory);
                        }
                    }
                    break;
                case OPERATION.GetTrailerStubs:
                    if (data.Trailers !== null && data.Trailers.length > 0) {
                        data.Trailers.forEach(function (trailerStub) {
                            trailerStub.cached = 0;
                        });
                        globals.user.setNumberOfTrailersFromServer(data.Trailers.length);
                        dfd.resolve(data);
                        $.when($, localDL.queueBatchJob(operation, data.Trailers))
                        .done(function () {
                            doingBackgroundTrailerStubsCaching.value = false;
                        });
                    } else {
                        dfd.resolve(data);
                        doingBackgroundTrailerStubsCaching.value = false;
                    }

                    return dfd.promise();
                case OPERATION.GetTrailer:
                    if (data.Result.ReturnCode === 1 && preventCaching === false) {
                        localDL.addToCache(OPERATION.GetTrailer, data.Trailer.Id + '_' + config.ACCESSORY_TYPES.TRAILER + '_' + data.Trailer.Source, data.Trailer);
                    }
                    break;
                case OPERATION.GetPayloadStubs:
                    if (data.Payloads !== null && data.Payloads.length > 0) {
                        data.Payloads.forEach(function (payloadStub) {
                            payloadStub.cached = 0;
                        });
                        globals.user.setNumberOfPayloadsFromServer(data.Payloads.length);
                        dfd.resolve(data);
                        $.when($, localDL.queueBatchJob(operation, data.Payloads))
                        .done(function () {
                            doingBackgroundPayloadStubsCaching.value = false;
                        });
                    } else {
                        dfd.resolve(data);
                        doingBackgroundPayloadStubsCaching.value = false;
                    }

                    return dfd.promise();
                case OPERATION.GetPayload:
                    if (data.Result.ReturnCode === 1 && preventCaching === false) {
                        data.Payload.AccessoryType = config.ACCESSORY_TYPES.PAYLOAD;
                        localDL.addToCache(OPERATION.GetPayload, data.Payload.Id + '_' + config.ACCESSORY_TYPES.PAYLOAD + '_' + data.Payload.Source, data.Payload);
                    }
                    break;
                case OPERATION.GetSharedOfferStubs.Code:
                    if (data.Offers !== null && data.Offers.length > 0) {
                        //localDL.clearStore(STORE.SharedOfferStubs)
                        //    .then(function () {
                        //        processingAndCacheSharedOfferStubs();
                        //    })
                        //    .fail(function () {
                        //        processingAndCacheSharedOfferStubs();
                        //    });
                        //function processingAndCacheSharedOfferStubs() {
                            data.Offers.forEach(function (sharedOfferStub) {
                                sharedOfferStub.cached = 0;
                            });
                            dfd.resolve(data);
                            //$.when($, localDL.queueBatchJob(operation, data.Offers))
                            //.done(function () {
                                doingBackgroundSharedOfferStubsCaching.value = false;
                            //});
                        //}
                    } else {
                        dfd.resolve(data);
                        doingBackgroundSharedOfferStubsCaching.value = false;
                    }

                    return dfd.promise();
                case OPERATION.GetBodyMasses.Code:
                    localDL.addToCache(OPERATION.GetBodyMasses.Code, PROPERTY.BodyMasses, data);
                    break;
                case OPERATION.GetLicenceCategories.Code:
                    localDL.addToCache(OPERATION.GetLicenceCategories.Code, PROPERTY.LicenceCategories, data);
                    break;
                case OPERATION.GetDataRequest:
                case OPERATION.PostDataRequest:
                    break;
            }
            if (dontResolve === false) {
                dfd.resolve(data);
            } 
        } catch (handleremoteSuccessEx) {
            TScMessenger.writeErrorMessage("Failure during " + operation + " in dataManager.handleRemoteSuccess: JS Exception: " + handleremoteSuccessEx.message + " : " + handleremoteSuccessEx.name + " , Cloud Services Transaction Message: " + getCSTransactionResultMessage(data));
            dfd.reject({ errorMessage: "Failure during " + operation + " in dataManager.handleRemoteSuccess: JS Exception: " + 
                        handleremoteSuccessEx.message + " : " + handleremoteSuccessEx.name + " , Cloud Services Transaction Message: " + getCSTransactionResultMessage(data)});
        }
        function getCSTransactionResultMessage(csDataResponse) {
            try {
                var message = '';
                if (csDataResponse.Message) {
                    message = csDataResponse.Message;
                } else if (csDataResponse.Result && csDataResponse.Result.Message) {
                    message = csDataResponse.Result.Message;
                }

                if (message === '') {
                    return 'Empty string returned from server'
                } else {
                    return message;
                }
            }catch(ex) {
                return 'Could not extract Cloud Services Transaction Result Message';
            }
        }
    }

    function convertAndStoreVehicleImages(vehicle, promiseArray) {
        var pack = localDL.PACK.Vehicle;
        doUrlLoadPreCheckAndLoadIfNecessary(vehicle.Image, pack, promiseArray);
        doUrlLoadPreCheckAndLoadIfNecessary(vehicle.MakeLogoImage, pack, promiseArray);
    }

    /**
        * Helper method that pushes a given vehicles image urls onto a given array.
        * @method pushVehicleImageUrlsOntoArray
        * @param {Object} vehicle The vehicle containing the image urls that need to be pushed onto the array.
        * @param {Array} arr The array to push the image urls onto.
        */
    function pushVehicleImageUrlsOntoArray(vehicle, arr) {
        arr.push(vehicle.ManufacturerLogo);
        arr.push(vehicle.ReportManufacturerLogo);
        arr.push(vehicle.ReportVehicleChassisImage);
        arr.push(vehicle.VehicleChassisImage);
    }

    /**
    * Helper method that pushes a given vehicles image urls onto a given array.
    * @method pushVehicleImageUrlsOntoArray
    * @param {Object} vehicle The vehicle containing the image urls that need to be pushed onto the array.
    * @param {Array} arr The array to push the image urls onto.
    */
    function pushSelectionVehicleImageUrlsOntoArray(vehicle, arr) {
        arr.push(vehicle.Image);
        arr.push(vehicle.MakeLogoImage);
    }

    function handleRemoteFailure(error) {
        networkRequestsUnderway--;
        if (error.errorMessage === "Offline") {
            dfd.reject(error);
        } else {
            dfd.reject(error)
        }
    }

    return dfd.promise();
}

/**
    * Checks to see if a given image has already been downloaded and only kicks off a job to retrieve and store if it has not. If an array 
    * has been provided the job will be pushed onto that instead of being kicked off directly.
    * @method doUrlLoadPreCheckAndLoadIfNecessary
    * @param {String} url The image url to be checked and possibly downloaded/stored.
    * @param {String} pack The pack the image url is associated with.
    * @param {Array} optionalPromiseArray The array to push the image urls onto.
    */
function doUrlLoadPreCheckAndLoadIfNecessary(url, pack, optionalPromiseArray) {
    var thePack = pack || 'not_set';
    if ($.inArray(url, alreadyLoadedImages) === -1 && $.inArray(url, requestedImages) === -1 && url !== '' && url !== undefined) {
        requestedImages.push(url);
        //alreadyLoadedImages.push(url);
        if (optionalPromiseArray) {
            optionalPromiseArray.push(convertAndAddImageToDB(url, thePack));
        } else {
            convertAndAddImageToDB(url, thePack);
        }
        
    }
}

/**
    * Handles downloading and storing of a batch job of images giving the caller the opportunity to provide a callback function to be notified 
    * when the operation is complete and if it was successful. 
    * @method loadAllImages
    * @param {Array} imgUrlArr An array of one or more images to be downloaded/stored.
    * @param {String} pack The pack the image url(s) is/are associated with.
    * @param {Function} optionalCallback An optional callback function provided if the caller wants to be informed when the load operation 
    * has completed and whether it has been successful or not allowing the caller to take action at that point.
    */
function loadAllImages(imgUrlArr, pack, optionalCallback) {

    
    var numSucceeded = 0;
    var numFailed = 0;
    requestedImages = [];
    doImageLoad(imgUrlArr, numSucceeded, numFailed, optionalCallback);

    function doImageLoad(arr, numGood, numBad, callback) {

        if (arr.length > 0) {
            var millisecondsToWait = 100;
            setTimeout(function () {
                var curImgUrl = arr.shift();
                if ($.inArray(curImgUrl, alreadyLoadedImages) === -1 && $.inArray(curImgUrl, requestedImages) === -1 && curImgUrl !== "" && curImgUrl !== null) {
                    requestedImages.push(curImgUrl);
                    convertAndAddImageToDB(curImgUrl, pack)
                    .then(function () {
                        numGood += 1;
                        doImageLoad(arr, numGood, numBad, callback);
                    })
                    .fail(function () {
                        numBad += 1;
                        doImageLoad(arr, numGood, numBad, callback);
                    });
                } else {
                    doImageLoad(arr, numGood, numBad, callback);
                }
            }, millisecondsToWait);
            
        } else {
            if (callback) {
                if (numBad > 0) {
                    callback(false);
                } else {
                    callback(true);
                }
                
            }
        }
    }
}

/**
    * Method decides whether to simply pass back current value of globals.isOnline or force an immediate re-check based on the priority passed in by the caller.
    * @method checkOnlineState
    * @param {Number} priority A flag to tell the check how important it is that the information returned (i.e. whether the app is online or not) is up to date.
    */
function checkOnlineState(priority) {
    var dfd = $.Deferred();

    var validMillis, currentTime = Date.now();

    switch (priority) {
        case PRIORITY.LOW:
            doResolve(globals.isOnline.value);
            break;
        case PRIORITY.MEDIUM:
            validMillis = PRIORITY.MEDIUM;
            doMillisCheck(validMillis, currentTime);
            break;
        case PRIORITY.HIGH:
            validMillis = PRIORITY.HIGH;
            doMillisCheck(validMillis, currentTime);
            break;
        default:
            break;
    }

    function doMillisCheck(millis, curTime) {
        if (curTime > (isOnlineLastCheckTimeStamp + millis)) {
            if (networkRequestsUnderway === 0) {
                monitorOnlineState(true)
                .then(doResolve)
                .fail(doResolve);
            } else {
                doResolve(globals.isOnline.value);
            }
        } else {
            doResolve(globals.isOnline.value);
        }
    }

    function doResolve(onlineState) {
        if(onlineState === true) {
            dfd.resolve(onlineState);
        }else {
            dfd.reject(onlineState);
        }
    }

    return dfd.promise();
}

/**
    * Do online check to see if cloud services is available
    * @method checkIsOnline
    */
function checkIsOnline() {
    var dfd = $.Deferred();

    remoteDL.checkIsOnline(OPERATION.CheckIsOnline, security, true)
        .then(function (result) {
            dfd.resolve(true);
        })
        .fail(function () {
            dfd.resolve(false);
        });

    return dfd.promise();
}

/**
    * Method periodically checks if the app is online and updates the value of globals.isOnline. 
    * @method monitorOnlineState
    * @param {Boolean} force A flag to tell the method to check immediately.
    */
function monitorOnlineState(force) {
    //message, data, source, showToast, toastOptions
    //log("In MonitorOnlineState, dataManager, In MonitorOnlineState, dataManager, In MonitorOnlineState, dataManager, In MonitorOnlineState, dataManager, In MonitorOnlineState, dataManager, In MonitorOnlineState, dataManager, In MonitorOnlineState, dataManager, In MonitorOnlineState, dataManager", null, system.getModuleId(dataManager), config.LOG_MESSAGE_TYPE.INFO);

    //tempCounter++;
    //if (tempCounter === 10) {
    //    try {
    //        throw 1;
    //    } catch (exception) {
            
    //        log("In MonitorOnlineState, dataManager, testing error logging: " + tempCounter + ":...: " + exception.message || "no error message", exception.stack || "NOTHING", system.getModuleId(dataManager), false, null, config.LOG_MESSAGE_TYPE.ERROR);
    //        tempCounter = 0;
    //    }
        
    //}

    if (onlineCheckDfd === undefined) {
        onlineCheckDfd = $.Deferred();
    } else if (onlineCheckUnderway === false) {
        onlineCheckDfd = $.Deferred();
    }
    

    if (force) {
        if (onlineCheckUnderway === false) {
            doIsOnlineCheck();
        }
        
    } else {
        if (networkRequestsUnderway === 0) {
            if (onlineCheckUnderway === false) {
                if ((isOnlineLastCheckTimeStamp + 500) <= Date.now()) {
                    doIsOnlineCheck();
                } else {
                    onlineCheckDfd.resolve(globals.isOnline.value);
                }
            }
        } else {
            onlineCheckDfd.resolve(globals.isOnline.value);
        }
            
    }

    function doIsOnlineCheck() {
        onlineCheckUnderway = true;
        
        inner();
        
        function inner() {
            
            if (securityTokenIsValid() === true) {
                remoteDL.checkIsOnlineHeadVersion()
                    .then(function () {
                        handleOnlineCheckResult(true);
                    })
                    .fail(function () {
                        handleOnlineCheckResult(false);
                    });
            }
        }
        
    }
    
    function handleOnlineCheckResult(res) {
        onlineCheckUnderway = false;
        isOnlineLastCheckTimeStamp = Date.now();
        globals.isOnline.value = res;

        onlineCheckDfd.resolve(res);
    }

    return onlineCheckDfd.promise();
}

function checkIsOnlineHeadVersion() {
    return remoteDL.checkIsOnlineHeadVersion();
}

function postDebugLogs() {

    var op;
    var payload;

    if(globals.user.getAuthenticationMethod() !== config.AUTHENTICATION_METHOD.TRUCKSCIENCE_API) {
        checkForAppUpdate();
    }
    //syncSharedOffers();
    //syncTeamItems();

    if ((immediateDebugLogPostRequired === true || timeToPostDebugLogs() === true) && config.loggingLevel !== config.LOGGING_LEVEL.NONE && globals.isOnline.value === true) {
            
            
        localDL.getLogDumpForPost()
            .then(function (debugInfo) {
                op = OPERATION.PostDebugInfo;
                payload = debugInfo;
                if (securityTokenIsValid() === true) {
                    remoteDL.checkIsOnline(op, security, true, payload)
                        .then(function (result) {
                            handlePostDebugResult(op, result);
                        })
                        .fail(function () {
                            updateLastDebugLogUploadTimeStamp();
                        });
                } 
            })
            .fail(function () {
                updateLastDebugLogUploadTimeStamp();
            });
    }

    function handlePostDebugResult(op, result) {
        updateLastDebugLogUploadTimeStamp();
        if (result.ReturnCode === 1) {
            if (immediateDebugLogPostRequired === true) {
                immediateDebugLogPostRequired = false;
            }
            
            localDL.clearStore(STORE.DebugLog);
        }
    }

    function updateLastDebugLogUploadTimeStamp() {
        lastDebugLogUploadTimeStamp = Date.now();
        localDL.addToCache(OPERATION.LastDebugLogUpload, PROPERTY.LastDebugLogUpload, lastDebugLogUploadTimeStamp);
    }
}

function postUsage() {

    var op;
    var payload;

    if (timeToPostUsageLogs() === true && globals.isOnline.value === true) {
        
        localDL.getUsageInfoForPost()
            .then(function (usageInfo) {
                op = OPERATION.PostUsageInfo;
                payload = usageInfo;
                if (securityTokenIsValid() === true) {
                    TScMessenger.writeDebugMessage('Posting to internals/usage...');
                    remoteDL.checkIsOnline(op, security, true, payload)
                        .then(function (result) {
                            TScMessenger.writeDebugMessage('Successful call to internals/usage...');
                            handlePostUsageResult(op, result)
                        })
                        .fail(function () {
                            updateLastUsageLogUploadTimeStamp();
                        });
                } 
            })
            .fail(function (error) {
                updateLastUsageLogUploadTimeStamp();
            });
    }

    function handlePostUsageResult(op, result) {
        updateLastUsageLogUploadTimeStamp();
        if (result.ReturnCode === 1) {
            
            localDL.clearStore(STORE.UsageLog);
        }
    }

    function updateLastUsageLogUploadTimeStamp() {
        lastUsageLogUploadTimeStamp = Date.now();
        localDL.addToCache(OPERATION.LastUsageLogUpload, PROPERTY.LastUsageLogUpload, lastUsageLogUploadTimeStamp);
    }
}

function timeToPostDebugLogs() {
    if (Date.now() > (lastDebugLogUploadTimeStamp + (config.debugLogCommunicationPeriod * 1000))) {
        return true;
    } else {
        return false;
    }
}

function doDebugLogMaintenanceIfRequired() {
    var dfd = $.Deferred();

    if ((Date.now() - (config.debugLogRetentionPeriod * 1000)) > lastDebugLogUploadTimeStamp) {
        var deleteOlderThanTimeStamp = Date.now() - (config.debugLogRetentionPeriod * 1000);
        localDL.clearOldDebugLogs(deleteOlderThanTimeStamp)
            .then(function () {
                dfd.resolve();
            });
    } else {
        dfd.resolve();
    }

    return dfd.promise();
}

function timeToPostUsageLogs() {
    //config.usageCommunicationPeriod, don't forget to multiply by 1000 to get millis
    if (Date.now() > (lastUsageLogUploadTimeStamp + (config.usageCommunicationPeriod * 1000))) {
        return true;
    } 
    return false;
}

function log(message, error, source, messageType, optionalIntercomMetaDataObject) {
    
    var messageAppend, stackTrace;

    var extractedErrorInfo = extractErrorAndStackInfo(error);
    messageAppend = extractedErrorInfo.messageAppend;
    stackTrace = extractedErrorInfo.stackTrace;
    
    var timeStamp = Date.now();
    var debugMessage = getDebugMessage();
    if (config.loggingLevel === config.LOGGING_LEVEL.ERRORS_ONLY) {
        if (messageType === config.LOG_MESSAGE_TYPE.ERROR) {
            //localDL.addToCache(OPERATION.LogDebugMessage, timeStamp, getDebugMessage());
            addMessageToCache();
        }
    } else if (config.loggingLevel === config.LOGGING_LEVEL.DETAILED) {
        //localDL.addToCache(OPERATION.LogDebugMessage, timeStamp, getDebugMessage());
        addMessageToCache();
    }
    if (messageType === config.LOG_MESSAGE_TYPE.ERROR && config.loggingLevel !== config.LOGGING_LEVEL.NONE) {
        immediateDebugLogPostRequired = true;
    }
    if (messageType === config.LOG_MESSAGE_TYPE.ERROR) {

        var intercomMetaDataObject = getMetadataObject(debugMessage);

        if (optionalIntercomMetaDataObject) {
            intercomMetaDataObject = extend(intercomMetaDataObject, optionalIntercomMetaDataObject);
        }

        sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.ERROR_RAISED, intercomMetaDataObject));
        

        
    }
    function getMetadataObject(message) {
        var obj = {};
        obj[config.INTERCOM_METADATA_ITEM.ORIGIN] = message.Origin,
        obj[config.INTERCOM_METADATA_ITEM.DESCRIPTION] = message.Description
        return obj;
    }
    function extend(obj, src) {
        for (var key in src) {
            if (src[key]) obj[key] = src[key];
        }
        return obj;
    }
    function addMessageToCache() {
        
        localDL.addToCache(OPERATION.LogDebugMessage, timeStamp, debugMessage);
        

        


    }

    function getDebugMessage() {
        var debugMessage = {};
        debugMessage.StackTrace = stackTrace;
        debugMessage.Description = message + messageAppend;
        debugMessage.TimeStamp = timeStamp;
        debugMessage.Origin = source;
        debugMessage.MessageType = messageType;

        return debugMessage;
    }
    //logger.log(message, data, source, showToast, toastOptions, messageType);
}

function logAppUsage(usageType) {

    var logUsage = false;

    switch (usageType) {
        case config.USAGE_TYPE.ONLINE: 
        case config.USAGE_TYPE.OFFLINE:
            logUsage = true;
            break;
        case config.USAGE_TYPE.PERFORMANCE_OPENED:
        case config.USAGE_TYPE.COSTING_OPENED:
        case config.USAGE_TYPE.SPECIFICATION_OPENED:
        case config.USAGE_TYPE.SUMMARY_OPENED:
        case config.USAGE_TYPE.COMPANY_OVERVIEW_OPENED:
        case config.USAGE_TYPE.OFFERS_OPENED:
        case config.USAGE_TYPE.BROCHURE_OPENED:
        case config.USAGE_TYPE.TRAINING_OPENED:
        case config.USAGE_TYPE.CONFIGURATION_OPENED:
            resetUseLoggedTrackingBooleans();
            logUsage = true;
            break;
        case config.USAGE_TYPE.PERFORMANCE_USED:
            if (performanceUseLogged === false) {
                logUsage = true;
                performanceUseLogged = true;
            }
            break;
        case config.USAGE_TYPE.COSTING_USED:
            if (costingUseLogged === false) {
                logUsage = true;
                costingUseLogged = true;
            }
            break;
        case config.USAGE_TYPE.SPECIFICATION_USED:
            if (specificationUseLogged === false) {
                logUsage = true;
                specificationUseLogged = true;
            }
            break;
        case config.USAGE_TYPE.SUMMARY_USED:
            if (summaryUseLogged === false) {
                logUsage = true;
                summaryUseLogged = true;
            }
            break;
        case config.USAGE_TYPE.COMPANY_OVERVIEW_USED:
            if (companyOverviewUseLogged === false) {
                logUsage = true;
                companyOverviewUseLogged = true;
            }
            break;
        case config.USAGE_TYPE.OFFERS_USED:
            if (offersUseLogged === false) {
                logUsage = true;
                offersUseLogged = true;
            }
            break;
        case config.USAGE_TYPE.BROCHURE_USED:
            if (brochureUseLogged === false) {
                logUsage = true;
                brochureUseLogged = true;
            }
            break;
        case config.USAGE_TYPE.TRAINING_USED:
            if (trainingUseLogged === false) {
                logUsage = true;
                trainingUseLogged = true;
            }
            break;
        case config.USAGE_TYPE.CONFIGURATION_USED:
            if (configurationUseLogged === false) {
                logUsage = true;
                configurationUseLogged = true;
            }
            break;
    }
    if (logUsage === true) {
        localDL.addToCache(OPERATION.LogAppUsage, Date.now(), { UsageType: usageType });
    }
    

    ////test data, should not be left here!!!
    //2628000000
    //localDL.addToCache(OPERATION.LogAppUsage, Date.now() - 2628000000, { UsageType: usageType });
    //localDL.addToCache(OPERATION.LogAppUsage, Date.now() - 2628000000+1, { UsageType: usageType });
    
    //localDL.addToCache(OPERATION.LogAppUsage, Date.now()+1, { UsageType: usageType });
    //localDL.addToCache(OPERATION.LogAppUsage, Date.now()+2, { UsageType: usageType });

    //localDL.addToCache(OPERATION.LogAppUsage, Date.now() + 2628000000, { UsageType: usageType });
    //localDL.addToCache(OPERATION.LogAppUsage, Date.now() + 2628000000 + 1, { UsageType: usageType });

}

function resetUseLoggedTrackingBooleans() {
    performanceUseLogged = false,
    costingUseLogged = false,
    specificationUseLogged = false,
    summaryUseLogged = false,
    companyOverviewUseLogged = false,
    offersUseLogged = false,
    brochureUseLogged = false,
    trainingUseLogged = false;
    configurationUseLogged = false;
}

function checkForAppUpdate() {

    localDL.getFromCache(OPERATION.LastUpdateCheck, PROPERTY.LastUpdateCheck)
        .then(function (lastUpdateCheckTimestamp) {
            var now = Date.now();
            if (lastUpdateCheckTimestamp === undefined || lastUpdateCheckTimestamp === null) {
                lastUpdateCheckTimestamp = 0;
            }
            if (Number(lastUpdateCheckTimestamp) + 300000 < now) {
                
                if (globals.isOnline.value) {
                    try {
                        if (navigator.serviceWorker) {
                            //serviceWorkerRegistration.update()
                            TScMessenger.writeDebugMessage('checking for updates');
                            // globals.getRegisteredServiceWorkerContainer().update();
                            navigator.serviceWorker.getRegistration(globals.getServiceWorkerScope()).then((registration) => {
                                if (registration) {
                                    // if(registration.active) {
                                    //     registration.active.postMessage({ type: 'CHECKING_FOR_UPDATES'});
                                    // }
                                    // if(registration.waiting) {
                                    //     registration.waiting.postMessage({ type: 'CHECKING_FOR_UPDATES'});
                                    // }
                                    // if(registration.installing) {
                                    //     registration.installing.postMessage({ type: 'CHECKING_FOR_UPDATES'});
                                    // }
                                    // registration.addEventListener("updatefound", () => {
                                    //     if(registration.waiting) {
                                    //         registration.waiting.postMessage({ type: 'CHECKING_FOR_UPDATES'});
                                    //     }
                                    //     if(registration.installing) {
                                    //         registration.installing.postMessage({ type: 'CHECKING_FOR_UPDATES'});
                                    //     }
                                    //     console.log('update found detected, checking for updates...');
                                    // });
                                    globals.checkingForUpdates = true;
                                    registration.update().then(function (registrationFromUpdate){
                                        if(!registrationFromUpdate.waiting && !registrationFromUpdate.installing && registrationFromUpdate.active) {
                                            // registration.active.postMessage({ type: 'FINISHED_CHECKING_FOR_UPDATES'});
                                            globals.checkingForUpdates = false;
                                        }/*else {
                                            if(registrationFromUpdate.waiting) {
                                                registrationFromUpdate.waiting.postMessage({ type: 'CHECKING_FOR_UPDATES'});
                                            }
                                            if(registrationFromUpdate.installing) {
                                                registrationFromUpdate.installing.postMessage({ type: 'CHECKING_FOR_UPDATES'});
                                            }
                                        }*/
                                        TScMessenger.writeDebugMessage('update check waiting: ' + registrationFromUpdate.waiting );
                                        TScMessenger.writeDebugMessage('update check active: ' + registrationFromUpdate.active );
                                        TScMessenger.writeDebugMessage('update check installing: ' + registrationFromUpdate.installing );
                                    });
                                }
                              });
                        } else if(window.applicationCache){
                            window.applicationCache.update();
                        }
                        
                    } catch (invalidStateErrorEx) {
                        TScMessenger.writeDebugMessage('invalidStateErrorEx in DM');
                    }
                    
                }
                
                localDL.addToCache(OPERATION.LastUpdateCheck, PROPERTY.LastUpdateCheck, now);
            }    
        });
}

function syncSharedOffers() {

    localDL.getFromCache(OPERATION.GetLastSharedOfferSyncTimestamp, PROPERTY.SharedOfferSyncTimestamp)
        .then(function (lastSharedOfferSyncTimestamp) {
            var now = Date.now();
            if (lastSharedOfferSyncTimestamp === undefined || lastSharedOfferSyncTimestamp === null) {
                localDL.addToCache(OPERATION.GetLastSharedOfferSyncTimestamp, PROPERTY.SharedOfferSyncTimestamp, now);
            } else {
                //if (Number(lastSharedOfferSyncTimestamp) + 120000 < now) {

                    if (globals.isOnline.value) {
                        try {
                            doingBackgroundSharedOfferStubsRetrieval.value = true;
                            doingBackgroundSharedOfferStubsCaching.value = true;

                            handleDataRequest(null, OPERATION.GetSharedOfferStubs.BaseUrl, OPERATION.GetSharedOfferStubs.Code, true)
                                .then(function (result) {
                                    if (result.Result.ReturnCode === 1) {
                                        sharedOfferStubsResult = result;
                                        doingBackgroundSharedOfferStubsRetrieval.value = false;

                                        sharedOfferSyncHandlerCallback(result);
                                    }
                                }).fail(function (error) {

                                });

                        } catch (sharedOfferSyncEx) {
                            TScMessenger.writeDebugMessage('sharedOfferSyncEx in DM');
                        }

                    }

                    localDL.addToCache(OPERATION.GetLastSharedOfferSyncTimestamp, PROPERTY.SharedOfferSyncTimestamp, now);
                //}
            }
        });


    
}

function syncTeamItems() {

    localDL.getFromCache(OPERATION.GetLastTeamItemsSyncTimestamp, PROPERTY.TeamItemsSyncTimestamp)
        .then(function (lastTeamItemsSyncTimestamp) {
            var now = Date.now();
            if (lastTeamItemsSyncTimestamp === undefined || lastTeamItemsSyncTimestamp === null) {
                localDL.addToCache(OPERATION.GetLastTeamItemsSyncTimestamp, PROPERTY.TeamItemsSyncTimestamp, now);
            } else {
                //if (Number(lastTeamItemsSyncTimestamp) + 120000 < now) {

                    if (globals.isOnline.value) {
                        try {
                            //new Date(Number(lastTeamItemsSyncTimestamp)).toUTCString()
                            handleDataRequest(null, OPERATION.GetTeamSyncItems.BaseUrl + '?lastSyncTimeStamp=' + new Date(Number(lastTeamItemsSyncTimestamp)).toUTCString(), OPERATION.GetTeamSyncItems.Code, true)
                                .then(function (result) {
                                    if (result.Result.ReturnCode === 1) {
                                        if (result.NewOrUpdatedTeamItems.Bodies.length > 0) {

                                            result.NewOrUpdatedTeamItems.Bodies.forEach(function (bodyStub) {
                                                bodyStub.cached = 0;
                                            });

                                            localDL.queueBatchJob(OPERATION.GetBodyStubs, result.NewOrUpdatedTeamItems.Bodies)
                                        }
                                        if (result.NewOrUpdatedTeamItems.Cranes.length > 0) {

                                            result.NewOrUpdatedTeamItems.Cranes.forEach(function (craneStub) {
                                                craneStub.cached = 0;
                                            });

                                            localDL.queueBatchJob(OPERATION.GetAccessoryStubs, result.NewOrUpdatedTeamItems.Cranes)
                                        }
                                        if (result.NewOrUpdatedTeamItems.Others.length > 0) {

                                            result.NewOrUpdatedTeamItems.Others.forEach(function (otherStub) {
                                                otherStub.cached = 0;
                                            });

                                            localDL.queueBatchJob(OPERATION.GetAccessoryStubs, result.NewOrUpdatedTeamItems.Others)
                                        }
                                        if (result.NewOrUpdatedTeamItems.Fridges.length > 0) {

                                            result.NewOrUpdatedTeamItems.Fridges.forEach(function (fridgeStub) {
                                                fridgeStub.cached = 0;
                                            });

                                            localDL.queueBatchJob(OPERATION.GetAccessoryStubs, result.NewOrUpdatedTeamItems.Fridges)
                                        }
                                        if (result.NewOrUpdatedTeamItems.Taillifts.length > 0) {

                                            result.NewOrUpdatedTeamItems.Taillifts.forEach(function (tailliftStub) {
                                                tailliftStub.cached = 0;
                                            });

                                            localDL.queueBatchJob(OPERATION.GetAccessoryStubs, result.NewOrUpdatedTeamItems.Taillifts)
                                        }
                                        if (result.NewOrUpdatedTeamItems.FifthWheels.length > 0) {

                                            result.NewOrUpdatedTeamItems.FifthWheels.forEach(function (fifthWheelStub) {
                                                fifthWheelStub.cached = 0;
                                            });

                                            localDL.queueBatchJob(OPERATION.GetAccessoryStubs, result.NewOrUpdatedTeamItems.FifthWheels)
                                        }
                                        if (result.NewOrUpdatedTeamItems.Hitches.length > 0) {

                                            result.NewOrUpdatedTeamItems.Hitches.forEach(function (hitchStub) {
                                                hitchStub.cached = 0;
                                            });

                                            localDL.queueBatchJob(OPERATION.GetAccessoryStubs, result.NewOrUpdatedTeamItems.Hitches)
                                        }
                                        if (result.NewOrUpdatedTeamItems.Hooklifts.length > 0) {

                                            result.NewOrUpdatedTeamItems.Hooklifts.forEach(function (hookliftStub) {
                                                hookliftStub.cached = 0;
                                            });

                                            localDL.queueBatchJob(OPERATION.GetAccessoryStubs, result.NewOrUpdatedTeamItems.Hooklifts)
                                        }
                                        if (result.NewOrUpdatedTeamItems.Trailers.length > 0) {

                                            result.NewOrUpdatedTeamItems.Trailers.forEach(function (trailerStub) {
                                                trailerStub.cached = 0;
                                            });

                                            localDL.queueBatchJob(OPERATION.GetTrailerStubs, result.NewOrUpdatedTeamItems.Trailers)
                                        }
                                        if (result.NewOrUpdatedTeamItems.Payloads.length > 0) {

                                            result.NewOrUpdatedTeamItems.Payloads.forEach(function (payloadStub) {
                                                payloadStub.cached = 0;
                                            });

                                            localDL.queueBatchJob(OPERATION.GetPayloadStubs, result.NewOrUpdatedTeamItems.Payloads)
                                        }

                                        teamSyncHandlerCallback(result);
                                    }
                                }).fail(function (error) {

                                });

                        } catch (teamItemsSyncEx) {
                            TScMessenger.writeDebugMessage('teamItemsSyncEx in DM');
                        }

                    }

                    localDL.addToCache(OPERATION.GetLastTeamItemsSyncTimestamp, PROPERTY.TeamItemsSyncTimestamp, now);
                //}
            }
        });



}

/**
    * Wraps security.login and then carries out some operations on the returned data on the way past.
    * @method login
    * @param {String} username The username of the user to be authorised.
    * @param {String} password The password of the user to be authorised.
    * @param {String} machineCode The machine code of the device the user is being authorised from.
    * @param {Number} cdg The customer distribution group of the user attempting to authorise.
    * @param {Boolean} async A flag to indicate that the login call should be carried out asynchronously.
    * @return {Object} Returns data from successful login or error if login failed. 
    */
function login(username, password, machineCode, cdg, async, isFromSignUp, authenticationMethod, bypassStandardSetup) {
    // var dfd = $.Deferred();
    if(globals.dmLoginDfd) {
        return globals.dmLoginDfd.promise();
    } else {
        globals.dmLoginDfd = $.Deferred();
    }
    security.login(username, password, machineCode, cdg, async, isFromSignUp, authenticationMethod)
        .then(function (loginData) {
            globals.loginDfd = null;
            if (bypassStandardSetup === undefined || bypassStandardSetup === false) {
                var isBackgroundReAuth = false;
                if (dataOpListenerCallback !== undefined && dataOpListenerCallback !== undefined) {
                    dataOpListenerCallback(config.LOG_IN_REAUTH_STEPS.DOWNLOADING_APPLICATION_DATA.StepNum, isBackgroundReAuth);
                }
                globals.setAlreadyAuthenticated('YES');
                doDataActionsAfterLoginOrReAuth(loginData, isBackgroundReAuth, globals.dmLoginDfd);
            } else {
                globals.dmLoginDfd.resolve(loginData);
            }
        }).fail(function (error) {
            globals.loginDfd = null;
            globals.dmLoginDfd.reject(error);
        });

    return globals.dmLoginDfd.promise();
}

function logout(justLoggedOut, refreshedData, logoutFrom3rdPartyIdentityProvider) {
    var dfd = $.Deferred();
    TScMessenger.writeDebugMessage('In DM logout...');
    
    localDL.clearBatchJobQueue()
        .then(function () {
            $.when(localDL.clearAllCachedData(), security.logout(logoutFrom3rdPartyIdentityProvider))
                .done(performOtherLogoutTasks)
                .fail(function () { 
                    TScMessenger.writeDebugMessage('In DM logout, calling performOtherLogoutTasks from fail path...'); 
                    performOtherLogoutTasks();
                });
        });
    
    

    function performOtherLogoutTasks() {
        globals.setStorageKey(null);
        localStorage.setItem('localStorageState', 'PLAINTEXT');
        
        TScMessenger.writeDebugMessage('In DM logout, performOtherLogoutTasks...');
        globals.timersAlreadySetup = false;
        globals.appConfigApplied = false;
        globals.clearTimers();
        globals.onlineOfflineEventListenersAlreadySetup.value = false;
        globals.user.clearVehicleSelectionSearchHistory();
        globals.setAlreadyAuthenticated('NO');
        if(typeof globals.user.getAppRefreshUnderway() !== 'string') {
            TScMessenger.writeDebugMessage('globals.dataModelReady.value = true in typeof globals.user.getAppRefreshUnderway() !== string>>DM.logout...');
            globals.dataModelReady.value = true;
        }
        if (refreshedData !== true) {
            globals.user.removeAppRefreshUnderway();
            TScMessenger.writeDebugMessage('globals.dataModelReady.value = true in DM.logout>>refreshedData===true...');
            globals.dataModelReady.value = true;
            sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.LOGGED_OUT));
        }
        globals.justLoggedOut = justLoggedOut !== undefined ? justLoggedOut : true;
        if (globals.justLoggedOut === true) {
            localStorage.removeItem('authMethod');
            globals.showModuleView();
        } else {
            globals.fadeOutAll();
        }
        dfd.resolve();
    }

    return dfd.promise();
}

function clearCachedData() {
    localDL.clearAllCachedData();
}

/**
    * Wraps security.securityTokenIsValid and then carries out some operations on the returned data(if there is any) on the way past.
    * @method securityTokenIsValid
    * @return {Boolean} Returns true or false depending on whether the current security token is considered valid or if invalid, whether it could be renewd or not. 
    */
function securityTokenIsValid(dontForceLicenceTransferMessage, callbackForLicenceTransfer) {
    let securityTokenValidityFlag = securityTokenIsValidAsTextFlag(dontForceLicenceTransferMessage, callbackForLicenceTransfer);

    return securityTokenValidityFlag === config.AUTHENTICATION_STATE.AUTHENTICATED ? true : false;
}

function securityTokenIsValidAsTextFlag(dontForceLicenceTransferMessage, callbackForLicenceTransfer) {
    TScMessenger.writeDebugMessage('In securityTokenIsValid');
    
    if (dontForceLicenceTransferMessage === undefined || dontForceLicenceTransferMessage === false) {
        globals.forceLicenceTransferMessage = true;
    }
    var result = security.securityTokenIsValid();
    globals.setAlreadyAuthenticated(result.IsValid ? 'YES' : 'NO');
    if (result.IsValid && result.LoginData) {
        let optionalDfd = undefined;
        TScMessenger.writeDebugMessage('In securityTokenIsValid, has login data');
        TScMessenger.writeDebugMessage('In securityTokenIsValid, last hash URL: ' + globals.user.getLastUrlHash());
        var isBackgroundReAuth = true;
        // var isRefreshingDataFlag = globals.user.getLastUrlHash() === config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE ||
        //                                 globals.user.getLastUrlHash() === config.APP_DATA_REFRESH_SCENARIO.USER_REFRESH ||
        //                             globals.user.getLastUrlHash() === config.APP_DATA_REFRESH_SCENARIO.ERROR_UPGRADING_USER_ALREADY_SUBSCRIBED ||
        //                             globals.user.getLastUrlHash() === config.APP_DATA_REFRESH_SCENARIO.ERROR_UPGRADING_USER_AFTER_SUCCESSFUL_SUBSCRIBE ||
        //                             globals.user.getLastUrlHash() === config.APP_DATA_REFRESH_SCENARIO.FORCED_REFRESH_MISSING_DATA ||
        //                             globals.user.getLastUrlHash() === config.APP_DATA_REFRESH_SCENARIO.CHANGING_LANGUAGE ||
        //                             globals.user.getLastUrlHash() === config.APP_DATA_REFRESH_SCENARIO.FORCED_REFRESH_AFTER_BUY;
        var isRefreshingDataFlag = globals.user.getAppRefreshUnderway();

        if (isRefreshingDataFlag) {
            TScMessenger.writeDebugMessage('In securityTokenIsValid, doing app refresh: ' + globals.user.getLastUrlHash());
            isBackgroundReAuth = false;
            globals.refreshDfd = $.Deferred();
            optionalDfd = globals.refreshDfd;
            if(globals.refreshDfdCallback) {
                globals.refreshDfd.promise().then(globals.refreshDfdCallback).fail(globals.refreshDfdFailCallback);
            }
        }
        if (dataOpListenerCallback !== undefined && dataOpListenerCallback !== undefined) {
            dataOpListenerCallback(config.LOG_IN_REAUTH_STEPS.DOWNLOADING_APPLICATION_DATA.StepNum, isBackgroundReAuth);
        }
        doDataActionsAfterLoginOrReAuth(result.LoginData, isBackgroundReAuth, optionalDfd, callbackForLicenceTransfer);
    } else {
        TScMessenger.writeDebugMessage('In securityTokenIsValid, no login data');
        if (result.IsValid === true) {
            setLocalDLIdentity();
            // globals.dataModelReady.value = true;
        }
    }
    let returnFlag = '';
    if(result.IsValid) {
        returnFlag = config.AUTHENTICATION_STATE.AUTHENTICATED;
    } else {
        if(result.LoginData && result.LoginData.result && result.LoginData.result.ReturnCode < 0) {
            if(result.LoginData && result.LoginData.result && result.LoginData.result.ReturnCode === -99) {
                returnFlag = config.AUTHENTICATION_STATE.UNEXPECTED_SERVER_ERROR;
            } else if(result.LoginData && result.LoginData.result && result.LoginData.result.ReturnCode === -98) {
                returnFlag = config.AUTHENTICATION_STATE.BAD_REQUEST_MISSING_USERNAME;
            } else if(result.LoginData && result.LoginData.result && result.LoginData.result.ReturnCode === -97) {
                returnFlag = config.AUTHENTICATION_STATE.BAD_REQUEST_MISSING_AUTHENTICATION_METHOD;
            } else if(result.LoginData && result.LoginData.result && result.LoginData.result.ReturnCode === -96) {
                returnFlag = config.AUTHENTICATION_STATE.BAD_REQUEST_MISSING_PASSWORD;
            } else if(result.LoginData && result.LoginData.result && result.LoginData.result.ReturnCode === -95) {
                returnFlag = config.AUTHENTICATION_STATE.BAD_REQUEST_MISSING_SECURITY_TOKEN_API_USER;
            } else if(result.LoginData && result.LoginData.result && result.LoginData.result.ReturnCode === -94) {
                returnFlag = config.AUTHENTICATION_STATE.AUTHENTICATION_FAILED_API_USER_INVALID_SECURITY_TOKEN;
            } else if(result.LoginData && result.LoginData.result && result.LoginData.result.ReturnCode === -93) {
                returnFlag = config.AUTHENTICATION_STATE.BAD_REQUEST_INVALID_APPLICATION_TYPE;
            } else if(result.LoginData && result.LoginData.result && result.LoginData.result.ReturnCode === -92) {
                returnFlag = config.AUTHENTICATION_STATE.BAD_REQUEST_MISSING_MACHINE_CODE;
            } else {
                returnFlag = config.AUTHENTICATION_STATE.AUTHENTICATION_FAILED;
            }
        }else if(result.LoginData && result.LoginData.error) {
            returnFlag = config.AUTHENTICATION_STATE.AUTHENTICATION_FAILED;
        } else {
            returnFlag = config.AUTHENTICATION_STATE.NEED_TO_AUTHENTICATE;
        }
    }
    return returnFlag;
}

function adjustLoggedInTrialUserAfterSuccessfulSubscribe(planType) {
    var dfd = $.Deferred();
    
    if (tempLoginDataRef !== null) {
        tempLoginDataRef.result.ReturnCode = 1;
        tempLoginDataRef.result.Message = "";
        tempLoginDataRef.trialDays = 0;
        switch (planType) {
            //case config.BUY_NOW_TYPE.MONTHLY_RECURRING.Code:
            //case config.BUY_NOW_TYPE.ANNUAL_RECURRING.Code:
            case config.BILLING_CYCLE_OPTIONS.MONTHLY:
            case config.BILLING_CYCLE_OPTIONS.ANNUALLY:
                tempLoginDataRef.fullDays = 60;
                break;
            default:
                tempLoginDataRef.fullDays = 14;
                break;
        }
        

        var validUntilOffline;
        
        var validUntilOfflineMillis = Date.now() + (tempLoginDataRef.fullDays * config.millisInDay);
        var date = new Date(validUntilOfflineMillis);
        validUntilOffline = moment(date).format("M/D/YYYY h:mm:ss a");
        

        globals.user.updateUser({
            validUntilOffline: validUntilOffline,
            userType: config.USER_TYPES.FULL_USER_OK,
            trialCalculationsRemaining: 0,
            firstLogin: false,
            trialDaysRemaining: tempLoginDataRef.trialDays
        });
        var isBackgroundReAuth = true;
        doDataActionsAfterLoginOrReAuth(tempLoginDataRef, isBackgroundReAuth, dfd);
        
    } else {
        dfd.reject();
    }

    return dfd.promise();
}

function doDataActionsAfterLoginOrReAuth(loginData, isBackgroundReAuth, optionalDfd, callbackForLicenceTransfer) {
    if (loginData.result.ReturnCode === -4 || loginData.result.ReturnCode === 2 || loginData.result.ReturnCode === -5) {
        tempLoginDataRef = loginData;
    }
    if (loginData.result.ReturnCode > 0) {
        setLocalDLIdentity();

        var fullDays = loginData.fullDays;
        var trialDays = loginData.trialDays;
        latestReportGraphicsFromLogin = loginData.reportGraphics;

        if (trialDays && trialDays > 0) {
            persist(OPERATION.LicenceLease, Date.now() + (trialDays * config.millisInDay), PROPERTY.LicenceLeaseExpiryDate);
        } else if (fullDays) {
            persist(OPERATION.LicenceLease, Date.now() + (fullDays * config.millisInDay), PROPERTY.LicenceLeaseExpiryDate);
        }

        if (loginData.result.ReturnCode === 3) {
            isBackgroundReAuth = false;
        }

        // if (isBackgroundReAuth === false || globals.user.getLastUrlHash() === config.APP_DATA_REFRESH_SCENARIO.APP_UPDATE || globals.user.getLastUrlHash() === config.APP_DATA_REFRESH_SCENARIO.USER_REFRESH ||
        //             globals.user.getLastUrlHash() === config.APP_DATA_REFRESH_SCENARIO.ERROR_UPGRADING_USER_ALREADY_SUBSCRIBED ||
        //             globals.user.getLastUrlHash() === config.APP_DATA_REFRESH_SCENARIO.ERROR_UPGRADING_USER_AFTER_SUCCESSFUL_SUBSCRIBE ||
        //             globals.user.getLastUrlHash() === config.APP_DATA_REFRESH_SCENARIO.FORCED_REFRESH_MISSING_DATA ||
        //             globals.user.getLastUrlHash() === config.APP_DATA_REFRESH_SCENARIO.CHANGING_LANGUAGE ||
        //             globals.user.getLastUrlHash() === config.APP_DATA_REFRESH_SCENARIO.FORCED_REFRESH_AFTER_BUY) {
        if (isBackgroundReAuth === false || globals.user.getAppRefreshUnderway()) {

            if (isBackgroundReAuth === true) {
                globals.refreshDfd = $.Deferred();
                optionalDfd = globals.refreshDfd;
                if(globals.refreshDfdCallback) {
                    globals.refreshDfd.promise().then(globals.refreshDfdCallback).fail(globals.refreshDfdFailCallback);
                }
            }

            TScMessenger.writeDebugMessage('In doDataActionsAfterLoginOrReAuth, isBackgroundReAuth =' + isBackgroundReAuth);
            standardHandleVersionsTasksComplete.value = false;
            slowHandleVersionsTasksComplete.value = false;
            //preloadOffersRetryCount = 0;
            //preloadOffers(0);
        }

        localDL.addToCache(OPERATION.GetLastTeamItemsSyncTimestamp, PROPERTY.TeamItemsSyncTimestamp, Date.now());
        localDL.addToCache(OPERATION.GetLastSharedOfferSyncTimestamp, PROPERTY.SharedOfferSyncTimestamp, Date.now());

        TScMessenger.writeDebugMessage('In doDataActionsAfterLoginOrReAuth, loadingProperties');
        // if (optionalDfd) {
        //     optionalDfd.resolve(loginData);
        // }
        loadProperties().then(function () {
            TScMessenger.writeDebugMessage('In doDataActionsAfterLoginOrReAuth, calling handleVersions');
            handleVersions(loginData.versions)
            .then(function (needToUpdate) {
                TScMessenger.writeDebugMessage('In doDataActionsAfterLoginOrReAuth, after handleVersions, needToUpdate=' + needToUpdate);
                if (dataOpListenerCallback !== undefined && dataOpListenerCallback !== undefined) {
                    dataOpListenerCallback(config.LOG_IN_REAUTH_STEPS.DOWNLOADING_SETTINGS.StepNum, isBackgroundReAuth);
                }
                localDL.getFromCache(OPERATION.GetVehiclePackData, PROPERTY.VehiclePackData)
                    .then(function (vehiclePackData) {
                        if (vehiclePackData !== undefined && vehiclePackData !== null) {
                            config.loadingSummaryDisclaimer = vehiclePackData.LoadingSummaryDisclaimer;
                            config.specificationSummaryDisclaimer = vehiclePackData.SpecificationSummaryDisclaimer;
                            config.costingSummaryDisclaimer = vehiclePackData.CostingSummaryDisclaimer;
                            config.routingSummaryDisclaimer = vehiclePackData.RoutingSummaryDisclaimer;
                        }
                    });
                if (needToUpdate === true) {
                    // getOnRoadCosts(globals.user.getCdg())
                    //         .then(function (onRoadCosts) {
                    //             globals.user.updateUser(onRoadCosts);
                                globals.user.clearSelectionArrays();
                                TScMessenger.writeDebugMessage('In doDataActionsAfterLoginOrReAuth, getting Common pack details');
                                getCommonPackDetails(globals.user.getCdg())
                                    .then(function (commonPackDetails) {
                                        TScMessenger.writeDebugMessage('In doDataActionsAfterLoginOrReAuth, updating User with Common pack details');
                                        globals.user.updateUser(commonPackDetails);
                                        //remember user settings are not cached
                                        
                                        getUserSettings()
                                            .then(function (userSettings) {
                                                globals.userSettingsDfd = null;
                                                TScMessenger.writeDebugMessage('In doDataActionsAfterLoginOrReAuth, updating User settings');
                                                
                                                
                                                if (globals.appLogosDownloaded === false) {
                                                    TScMessenger.writeDebugMessage('In doDataActionsAfterLoginOrReAuth, globals.appLogosDownloaded === false');
                                                    if (dataOpListenerCallback !== undefined && dataOpListenerCallback !== undefined) {
                                                        dataOpListenerCallback(config.LOG_IN_REAUTH_STEPS.DOWNLOADING_IMAGES.StepNum, isBackgroundReAuth);
                                                    }
                                                    loadLocallyOrRetrieveAndStoreAppLogos()
                                                        .then(function () {
                                                            TScMessenger.writeDebugMessage('In doDataActionsAfterLoginOrReAuth, after loadLocallyOrRetrieveAndStoreAppLogos');
                                                            if (dataOpListenerCallback !== undefined && dataOpListenerCallback !== undefined) {
                                                                dataOpListenerCallback(config.LOG_IN_REAUTH_STEPS.CONFIGURING_APPLICATION.StepNum, isBackgroundReAuth);
                                                            }
                                                            if (isBackgroundReAuth === true) {
                                                                globals.successfulBackgroundReAuth.value = true;
                                                            }
                                                            if (optionalDfd) {
                                                                TScMessenger.writeDebugMessage('In doDataActionsAfterLoginOrReAuth, resolving refreshDfd');
                                                                optionalDfd.resolve(loginData);
                                                            }else {
                                                                TScMessenger.writeDebugMessage('In doDataActionsAfterLoginOrReAuth, setting globals.dataModelReady.value = true');
                                                                globals.dataModelReady.value = true;
                                                            }
                                                            if (loginData.result.ReturnCode === 3 && callbackForLicenceTransfer) {
                                                                callbackForLicenceTransfer();
                                                            }
                                                            // bus.emit('updateSelectionData');
                                                        }).fail(handleFailure);
                                                } else {
                                                    TScMessenger.writeDebugMessage('In doDataActionsAfterLoginOrReAuth, globals.appLogosDownloaded === true');
                                                    if (dataOpListenerCallback !== undefined && dataOpListenerCallback !== undefined) {
                                                        dataOpListenerCallback(config.LOG_IN_REAUTH_STEPS.CONFIGURING_APPLICATION.StepNum, isBackgroundReAuth);
                                                    }
                                                    if (isBackgroundReAuth === true) {
                                                        globals.successfulBackgroundReAuth.value = true;
                                                    }
                                                    if (optionalDfd) {
                                                        TScMessenger.writeDebugMessage('In doDataActionsAfterLoginOrReAuth, resolving refreshDfd');
                                                        optionalDfd.resolve(loginData);
                                                    }else {
                                                        TScMessenger.writeDebugMessage('In doDataActionsAfterLoginOrReAuth, setting globals.dataModelReady.value = true');
                                                        globals.dataModelReady.value = true;
                                                    }
                                                    if (loginData.result.ReturnCode === 3 && callbackForLicenceTransfer) {
                                                        callbackForLicenceTransfer();
                                                    }
                                                }
                                            }).fail(handleFailure);
                                    }).fail(handleFailure);
                                //});
                            // }).fail(handleFailure);


                } else {
                    if (dataOpListenerCallback !== undefined && dataOpListenerCallback !== undefined) {
                        dataOpListenerCallback(config.LOG_IN_REAUTH_STEPS.CONFIGURING_APPLICATION.StepNum, isBackgroundReAuth);
                    }
                    if (isBackgroundReAuth === true) {
                        globals.successfulBackgroundReAuth.value = true;
                    }
                    if (optionalDfd) {
                        optionalDfd.resolve(loginData);
                    }else {
                        TScMessenger.writeDebugMessage('globals.dataModelReady.value = true in doActionsAfterLoginOrReAuth>> noNeedToUpdate path...');
                        globals.dataModelReady.value = true;
                    }
                    if (loginData.result.ReturnCode === 3 && callbackForLicenceTransfer) {
                        callbackForLicenceTransfer();
                    }
                }

            })
            .fail(handleFailure);
        }).fail(handleFailure);
        
        

    } else {
        if (isBackgroundReAuth === true) {
            globals.successfulBackgroundReAuth.value = false;
        }
        if (optionalDfd) {
            optionalDfd.resolve(loginData);
        }
    }
    function handleFailure(error) {
        TScMessenger.writeDebugMessage('In doDataActionsAfterLoginOrReAuth, handleFailure');
        globals.userSettingsDfd = null;
        if (optionalDfd) {
            optionalDfd.reject(error);
        }
    }
}

function invalidateSecurityToken() {
    security.setSecurityToken('', '', '');
}

function signUp(firstName, lastName, emailAddress, password, country, authenticationMethod) {
    var dfd = $.Deferred();

    var passwordToUse = authenticationMethod === config.AUTHENTICATION_METHOD.TRUCKSCIENCE ? encodeURIComponent(password) : password;
    if(authenticationMethod === config.AUTHENTICATION_METHOD.MICROSOFT) {
        passwordToUse = passwordToUse.substring(0, 300);
    }
    var request = 'website/signup?firstName=' + firstName +
        '&lastName=' + lastName +
        '&emailAddress=' + emailAddress +
        '&password=' + passwordToUse +
        '&applicationType=' + config.applicationIdentityType +
        '&country=' + country +
        '&token=' + config.externalAPIKey +
        '&authenticationMethod=' + authenticationMethod;

    handleDataRequest(null, request, OPERATION.SignUp, true)
        .then(function (data) {
            //any custom pre-processing of data should be handled here                    

            dfd.resolve(data);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function validateAccessToken() {
    var dfd = $.Deferred();

    

    var request = OPERATION.ValidateAccessToken.BaseUrl;

    handleDataRequest(null, request, OPERATION.ValidateAccessToken, true)
        .then(function (data) {
            //any custom pre-processing of data should be handled here                    

            dfd.resolve(data);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function requestTrialExtension(company, phoneNumber) {
    
    var dfd = $.Deferred();

    var request = 'website/ExtendTrial?firstName=' + globals.user.getFirstName() +
        '&lastName=' + globals.user.getLastName() +
        '&emailAddress=' + globals.user.getEmailAddress() +
        '&company=' + company +
        '&phone=' + phoneNumber +
        '&country=' + globals.user.getCountryName() +
        '&token=' + config.externalAPIKey;

    handleDataRequest(null, request, OPERATION.RequestTrialExtension, true)
        .then(function (data) {
            //any custom pre-processing of data should be handled here                    

            dfd.resolve(data);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

/**
    * 
    * @param {string} company
    * @param {string} phoneNumber
    * @returns {Promise}
    */
function requestSalesTool(company, phoneNumber) {

    var dfd = $.Deferred();

    var request = 'website/requestsalestool?firstName=' + globals.user.getFirstName() +
        '&lastName=' + globals.user.getLastName() +
        '&emailAddress=' + globals.user.getEmailAddress() +
        '&company=' + company +
        '&phone=' + phoneNumber +
        '&country=' + globals.user.getCountryName() +
        '&token=' + config.externalAPIKey;

    handleDataRequest(null, request, OPERATION.RequestSalesTool, true)
        .then(function (data) {
            //any custom pre-processing of data should be handled here
            dfd.resolve(data);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

/**
    * 
    * @param {string} company
    * @param {string} phoneNumber
    * @returns {Promise}
    */
function requestUpgrade(company, phoneNumber) {

    var dfd = $.Deferred();

    var request = 'website/requestupgrade?firstName=' + globals.user.getFirstName() +
        '&lastName=' + globals.user.getLastName() +
        '&emailAddress=' + globals.user.getEmailAddress() +
        '&company=' + company +
        '&phone=' + phoneNumber +
        '&country=' + globals.user.getCountryName() +
        '&token=' + config.externalAPIKey;

    handleDataRequest(null, request, OPERATION.RequestUpgrade, true)
        .then(function (data) {
            //any custom pre-processing of data should be handled here
            dfd.resolve(data);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function validateNTEAUser(nteaMembershipNumber, username, password) {
    var dfd = $.Deferred();

    var data = {
        MembershipNumber: nteaMembershipNumber,
        UserName: username,
        Password: password
    };
    var request = 'integrations/validatenteauser';

    //var request = 'integrations/validatenteauser?username=' + username + '&password=' + password;

    handleDataRequest(null, request, OPERATION.ValidateNTEAUser, true, data)
        .then(function (data) {
            //any custom pre-processing of data should be handled here                    

            dfd.resolve(data);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function registerBuyNowClick() {

    var dfd = $.Deferred();

    var request = 'zoho/RegisterBuyNowClick?firstName=' + globals.user.getFirstName() +
        '&lastName=' + globals.user.getLastName() +
        '&emailAddress=' + globals.user.getEmailAddress() +
        '&applicationType=' + config.applicationIdentityType +
        '&token=' + config.externalAPIKey;

    handleDataRequest(null, request, OPERATION.RegisterBuyNowClick, true)
        .then(function (data) {
            //any custom pre-processing of data should be handled here                    

            dfd.resolve(data);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function buyNow(url) {
    var dfd = $.Deferred();
    var urlParts = url.split('?');
    //urlParts[1] = encodeURIComponent(urlParts[1]);
    url = urlParts[0];
    //url = urlParts[0] + '?' + urlParts[1];

    /*
    "async": true,
"crossDomain": true,
"url": "https://truckscience-test.chargebee.com/hosted_pages/plans/axle-weight-calculator-monthly-usd-v1",
"method": "GET",
"headers": {
"cache-control": "no-cache",
}

header("Access-Control-Allow-Origin: *");
header("Access-Control-Allow-Headers: *");
    */

    var options = {
        url: url,
        method: "GET",
        async: true,
        crossDomain: true,
        //headers: {
        //    "Access-Control-Allow-Origin": "*",
        //    "Access-Control-Allow-Headers": "*"
        //}
        headers: {
            "authorization": "Basic dGVzdF9aaEdCWWE3R25mNjdCY2xvM2RjdTI2NFdWWUNZM1g4QTA6"
        }
    };
    $.ajax(options)
        .then(function (response) {
            dfd.resolve(response);
        })
        .fail(function (error) {
            dfd.reject(error);
        });
    

    return dfd.promise();
}

function getPortalURL(chargebeeCustomerId) {
    var dfd = $.Deferred();

    var request = 'chargebee/portalurl?chargebeeCustomerId=' + chargebeeCustomerId;

    handleDataRequest(null, request, OPERATION.GetPortalURL, true)
        .then(function (data) {
            //any custom pre-processing of data should be handled here                    

            dfd.resolve(data);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function getUpdatePaymentMethodURL() {
    ///chargebee/checkouturl
    var dfd = $.Deferred();

    var request = 'chargebee/updatepaymentmethodurl';

    handleDataRequest(null, request, OPERATION.GetUpdatePaymentMethodURL, true)
        .then(function (data) {
            //any custom pre-processing of data should be handled here                    

            dfd.resolve(data);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function sendLoginDebugInfo(origin, message, transactionMessage, error, user) {
    var dfd = $.Deferred();

    var messageAppend, stackTrace;

    var extractedErrorInfo = extractErrorAndStackInfo(error);
    messageAppend = extractedErrorInfo.messageAppend;
    stackTrace = extractedErrorInfo.stackTrace;
    
    var currentdate = new Date();
    var messageLoggedDate =  currentdate.getDate() + "/"
                    + (currentdate.getMonth() + 1) + "/"
                    + currentdate.getFullYear() + " @ "
                    + currentdate.getHours() + ":"
                    + currentdate.getMinutes() + ":"
                    + currentdate.getSeconds();


    //var messageLoggedDate = Date.now();
        

    var data = {
        DebugMessage: { MessageLoggedDate: messageLoggedDate, Origin: origin, Description: message + messageAppend, StackTrace: stackTrace, User: user || '', CloudServicesTransactionMessage: transactionMessage },
        Token: config.externalAPIKey
    }
    var request = 'debug/debug';

    handleDataRequest(null, request, OPERATION.PostLoginDebug, true, data)
        .then(function (data) {
            //any custom pre-processing of data should be handled here                    

            dfd.resolve(data);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function sendScreenSizeInfo(screenDetails, messageType) {
    var dfd = $.Deferred();

    //var messageAppend, stackTrace;

    //var extractedErrorInfo = extractErrorAndStackInfo(error);
    //messageAppend = extractedErrorInfo.messageAppend;
    //stackTrace = extractedErrorInfo.stackTrace;

    //var currentdate = new Date();
    //var messageLoggedDate = currentdate.getDate() + "/"
    //    + (currentdate.getMonth() + 1) + "/"
    //    + currentdate.getFullYear() + " @ "
    //    + currentdate.getHours() + ":"
    //    + currentdate.getMinutes() + ":"
    //    + currentdate.getSeconds();

    var screenDetailsObject = {
        DevicePixelRatio: screenDetails.dimensions.devicePixelRatio,
        OriginalScreenHeight: screenDetails.dimensions.originalScreenHeight,
        OriginalScreenWidth: screenDetails.dimensions.originalScreenWidth,
        ActualScreenHeight: screenDetails.dimensions.actualScreenHeight,
        ActualScreenWidth: screenDetails.dimensions.actualScreenWidth,
        OperatingSystem: screenDetails.operatingSystem,
        Browser: screenDetails.browser
    };

    var data = {
        ScreenDetails: screenDetailsObject,
        MessageType: messageType,
        Token: config.externalAPIKey
    }
    var request = 'debug/screensizeemail';

    handleDataRequest(null, request, OPERATION.PostScreenSizeDebug, true, data)
        .then(function (data) {
            //any custom pre-processing of data should be handled here                    

            dfd.resolve(data);
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });

    return dfd.promise();
}

function extractErrorAndStackInfo(error) {
    var extractedErrorInfo = {
        stackTrace: 'NA',
        messageAppend: ''
    }

    if (error !== undefined && error !== null) {
        try {
            if (error.message !== undefined) {
                extractedErrorInfo.messageAppend = ", Error Message: " + error.message;
            } else if (error.errorMessage !== undefined) {
                extractedErrorInfo.messageAppend = ", Error Message: " + error.errorMessage;
            } else {
                extractedErrorInfo.messageAppend = ", Error Message: " + error;
            }
            if (error.failedOp !== undefined) {
                extractedErrorInfo.messageAppend = extractedErrorInfo.messageAppend + ', Failed Operation: ' + error.failedOp;
            }
            if (error.opTimeout !== undefined) {
                extractedErrorInfo.messageAppend = extractedErrorInfo.messageAppend + ', Operation Timeout: ' + error.opTimeout;
            }
            extractedErrorInfo.stackTrace = error.stack || "NOTHING";


        } catch (exception) {
            extractedErrorInfo.messageAppend = exception.message || "no error message";
            extractedErrorInfo.stackTrace = exception.stack || "NOTHING";
        }
    }
    return extractedErrorInfo;
}

function dismissAppOptionItem(appOptionItemCode) {
    var dfd = $.Deferred();
    

    var timeStamp = Date.now();
    
    
    if (globals.isOnline.value === true) {
        saveOptionItemStatusOnline(appOptionItemCode);
    } else {
        doOfflineSave(appOptionItemCode);
    }

    function saveOptionItemStatusOnline(appOptionItemCode) {
        var request = OPERATION.PostAppOptionItem.BaseUrl + '?appOptionItemCode=' + appOptionItemCode + '&statusCode=' + config.APP_OPTION_ITEM_STATUS.DISMISSED;

        handleDataRequest(null, request, OPERATION.PostAppOptionItem.Code, true)
            .then(function (data) {
                //any custom pre-processing of data should be handled here                    
                dfd.resolve(data);
            })
            .fail(function (errorMessage) {
                doOfflineSave(appOptionItemCode);
                dfd.reject(errorMessage);
            });
        
    }

    function doOfflineSave(appOptionItemCode) {
        
        // app options are stored in the user in local storage and also have no interaction with other objects so nothing to do here except 
        // queue the request for syncing later and then resolve as if request had been successful

        var params = { appOptionItemCode: appOptionItemCode, statusCode: config.APP_OPTION_ITEM_STATUS.DISMISSED };
        queueSyncJob(OPERATION.PostAppOptionItem, timeStamp, params);
        
        dfd.resolve({ ReturnCode: 1, Message: "" });
    }

    return dfd.promise();
}

function registerDataOperationListener(listenerCallback, operation) {
    dataOpListenerCallback = listenerCallback;
}
//#region do not delete, this code is related to providing more information/feedback during login
//function registerDataOperationListener(listenerCallback, operation) {
//    dataOpListenerCallback = listenerCallback;
//    var isStarted = false;
//    numRecordsToCache = 0;
//    numRecordsCached = 0;
//    localDL.registerCacheListener(recordCachedCallback);
//    //var OpTracker = function () {
//    //    var numRecordsToCache = 0;
//    //    var numRecordsCached = 0;
//    //    //var ops = [
//    //    //    {
//    //    //        OpName: OPERATION.GetSelectionList,
//    //    //        Retrieval: {
//    //    //            Started: 'no',
//    //    //            Finished: 'no'
//    //    //        },
//    //    //        Caching: {
//    //    //            Total: -1,
//    //    //            Done: 0
//    //    //        },
//    //    //        Images: {
//    //    //            Started: 'no',
//    //    //            Finished: 'no'
//    //    //        }   
//    //    //    }, 
//    //    //    {
//    //    //        OpName: OPERATION.GetOnRoadCosts,
//    //    //        Retrieval: {
//    //    //            Started: 'no',
//    //    //            Finished: 'no'
//    //    //        },
//    //    //        Caching: {
//    //    //            Total: -1,
//    //    //            Done: 0
//    //    //        },
//    //    //        Images: null
//    //    //    },
//    //    //    {
//    //    //        OpName: OPERATION.GetCommonPackDetails,
//    //    //        Retrieval: {
//    //    //            Started: 'no',
//    //    //            Finished: 'no'
//    //    //        },
//    //    //        Caching: {
//    //    //            Total: -1,
//    //    //            Done: 0
//    //    //        },
//    //    //        Images: null
//    //    //    },
//    //    //    {
//    //    //        OpName: OPERATION.GetOverviewContent,
//    //    //        Retrieval: {
//    //    //            Started: 'no',
//    //    //            Finished: 'no'
//    //    //        },
//    //    //        Caching: {
//    //    //            Total: -1,
//    //    //            Done: 0
//    //    //        },
//    //    //        Images: null
//    //    //    },
//    //    //    {
//    //    //        OpName: OPERATION.GetAppConfig,
//    //    //        Retrieval: {
//    //    //            Started: 'no',
//    //    //            Finished: 'no'
//    //    //        },
//    //    //        Caching: {
//    //    //            Total: -1,
//    //    //            Done: 0
//    //    //        },
//    //    //        Images: null
//    //    //    }
//    //    //];
//    //    function recordCachedCallback(op) {
//    //        //ops.forEach(function (opMonitor) {
//    //        //    if (opMonitor.OpName === op) {
//    //                //if (opMonitor.Caching.Total !== -1 && opMonitor.Caching.Total !== 0) {
//    //                //    opMonitor.Caching.Done = opMonitor.Caching.Done + 1;
//    //                //    dataOpListenerCallback('UPDATE', calculatePercentage());
//    //                //}
//    //        //        return;
//    //        //    }
//    //        //});
//    //        if (op === OPERATION.GetSelectionList) {
//    //            if (numRecordsCached === 0) {
//    //                dataOpListenerCallback('STARTED', 0);
//    //            }
//    //            numRecordsCached++;
//    //            doStatusCheck();
//    //        }
            
//    //    }
//    //    localDL.registerCacheListener(recordCachedCallback);
//    //    function calculatePercentage() {
                
//    //        if (numRecordsCached === numRecordsToCache) {
//    //            return 100;
//    //        }
//    //        return Math.floor((numRecordsCached / numRecordsToCache) * 100);
//    //    }
//    //    //function calculatePercentage() {
//    //    //    var percentage = 0;
//    //    //    ops.forEach(function (opMonitor) {
//    //    //        if (opMonitor.Retrieval.Started === 'yes') {
//    //    //            percentage += 5;
//    //    //        }
//    //    //        if (opMonitor.Retrieval.Finished === 'yes') {
//    //    //            percentage += 10;
//    //    //        }
//    //    //        if (opMonitor.Images != null && opMonitor.Images.Started === 'yes') {
//    //    //            percentage += 5;
//    //    //        }
//    //    //        if (opMonitor.Images != null && opMonitor.Images.Finished === 'yes') {
//    //    //            percentage += 15;
//    //    //        }
//    //    //        if (opMonitor.Caching.Total !== -1 && opMonitor.Caching.Total !== 0) {
//    //    //            if (opMonitor.Caching.Done % 100 === 0) {
//    //    //                percentage += opMonitor.Caching.Done / 100;
//    //    //            }
//    //    //            //percentage += Math.ceil(((opMonitor.Caching.Done / opMonitor.Caching.Total) * 100) * 8);
//    //    //        }
//    //    //    });
//    //    //    return percentage;
//    //    //}
        
//    //    //this.notifyRetrievalStarted = function(op) {
//    //    //    ops.forEach(function (opMonitor) { 
//    //    //        if (opMonitor.OpName === op) {
//    //    //            opMonitor.Retrieval.Started = 'yes';
//    //    //            if (isStarted === false) {
//    //    //                isStarted = true;
//    //    //                dataOpListenerCallback('STARTED', 10);
//    //    //            }
//    //    //            doStatusCheck();
//    //    //            return;
//    //    //        }
//    //    //    });
//    //    //}

//    //    //this.notifyRetrievalFinished = function (op) {
//    //    //    ops.forEach(function (opMonitor) {
//    //    //        if (opMonitor.OpName === op) {
//    //    //            opMonitor.Retrieval.Finished = 'yes';
//    //    //            doStatusCheck();
//    //    //            return;
//    //    //        }
//    //    //    });
//    //    //}
        
//    //    //this.registerImageRetrievalStarted = function(op) {
//    //    //    ops.forEach(function (opMonitor) {
//    //    //        if (opMonitor.OpName === op) {
//    //    //            opMonitor.Images.Started = 'yes';
//    //    //            doStatusCheck();
//    //    //            return;
//    //    //        }
//    //    //    });
//    //    //}

//    //    //this.registerAllImagesRetrieved = function(op) {
//    //    //    ops.forEach(function (opMonitor) {
//    //    //        if (opMonitor.OpName === op) {
//    //    //            opMonitor.Images.Finished = 'yes';
//    //    //            doStatusCheck();
//    //    //            return;
//    //    //        }
//    //    //    });
//    //    //}

//    //    this.registerNumRecordsToCache = function (numRecsTocache, op) {
//    //        //ops.forEach(function (opMonitor) {
//    //        //    if (opMonitor.OpName === op) {
//    //        //        opMonitor.Caching.Total = numRecordsTocache;
//    //        //        return;
//    //        //    }
//    //        //});
//    //        numRecordsToCache = numRecsTocache;
//    //    }
        
//    //    //function doStatusCheck() {
//    //    //    var curPercent = calculatePercentage();
//    //    //    if (curPercent < 100) {
//    //    //        dataOpListenerCallback('UPDATE', curPercent);
//    //    //    } else {
//    //    //        dataOpListenerCallback('FINISHED', curPercent);
//    //    //    }
//    //    //}
//    //    function doStatusCheck() {
//    //        var curPercent = calculatePercentage();
//    //        if (curPercent < 100) {
//    //            dataOpListenerCallback('UPDATE', curPercent);
//    //        } else {
//    //            dataOpListenerCallback('FINISHED', curPercent);
//    //        }
//    //    }
//    //    //return OpTracker;
//    //};
//    //curOpTracker = new OpTracker();
//}

//function recordCachedCallback(op) {
//    //ops.forEach(function (opMonitor) {
//    //    if (opMonitor.OpName === op) {
//    //if (opMonitor.Caching.Total !== -1 && opMonitor.Caching.Total !== 0) {
//    //    opMonitor.Caching.Done = opMonitor.Caching.Done + 1;
//    //    dataOpListenerCallback('UPDATE', calculatePercentage());
//    //}
//    //        return;
//    //    }
//    //});
//    if (op === OPERATION.GetSelectionList) {
//        if (numRecordsCached === 0) {
//            dataOpListenerCallback('STARTED', 0);
//        }
//        numRecordsCached++;
//        doStatusCheck();
//    }

//}

//function calculatePercentage() {

//    if (numRecordsCached === numRecordsToCache) {
//        return 100;
//    }
//    return Math.floor((numRecordsCached / numRecordsToCache) * 100);
//}

//function doStatusCheck() {
//    var curPercent = calculatePercentage();
//    if (curPercent < 100) {
//        dataOpListenerCallback('UPDATE', curPercent);
//    } else {
//        dataOpListenerCallback('FINISHED', curPercent);
//    }
//}
//#endregion do not delete, this code is related to providing more information/feedback during login

function setLocalDLIdentity() {
    if(globals.user.getAuthenticationMethod() === config.AUTHENTICATION_METHOD.TRUCKSCIENCE_API) {
        localDL.setIdentity(globals.user.getCdg(), globals.user.getUserName());
    }else {
        localDL.setIdentity(globals.user.getCdg(), globals.user.getEmailAddress());
    }
    
}

function runDataIntegrityTests() {
    var dfd = $.Deferred();

    var availableLegislations = globals.user.getAvailableLegislations();
    if (availableLegislations === undefined || availableLegislations === null || availableLegislations.length === 0) {
        dfd.reject(config.DATA_INTEGRITY_CHECK_FAILURES.LEGISLATIONS_NOT_AVAILABLE);
    } else {
        dfd.resolve();
    }

    return dfd.promise();
}

function replaceReportImageUrls(passedInRepGraphics) {
    var dfd = $.Deferred();
    var reportReplacePromises = [];
    var reportImagesToRetrieve = [];
    var graphicsToUse = passedInRepGraphics || latestReportGraphicsFromLogin;

    reportImagesToRetrieve.push(graphicsToUse.reportHeaderURL);
    reportImagesToRetrieve.push(graphicsToUse.reportFooterURL);
    reportImagesToRetrieve.push(graphicsToUse.landscapeReportHeaderLeftURL);

    loadAllImages(reportImagesToRetrieve, localDL.PACK.Graphic, function (allImagesDownLoaded) {
        if (allImagesDownLoaded && allImagesDownLoaded === true) {
            reportReplacePromises.push(replaceImageUrl(graphicsToUse, "reportHeaderURL", localDL.PACK.Graphic));
            reportReplacePromises.push(replaceImageUrl(graphicsToUse, "reportFooterURL", localDL.PACK.Graphic));
            reportReplacePromises.push(replaceImageUrl(graphicsToUse, "landscapeReportHeaderLeftURL", localDL.PACK.Graphic));
            $.when.apply($, reportReplacePromises)
                .done(function () {
                    dfd.resolve();
                });
        }
    });
    
    //reportReplacePromises.push(replaceImageUrl(graphicsToUse, "reportHeaderURL", localDL.PACK.Graphic));
    //reportReplacePromises.push(replaceImageUrl(graphicsToUse, "reportFooterURL", localDL.PACK.Graphic));
    
    //$.when.apply($, reportReplacePromises)
    //    .done(function () {
    //        dfd.resolve();
    //    });

    return dfd.promise();
}

function sortSelectionList(data) {
    //var sortedOnGVW = data.Offers.sort(function (a, b) {
    //    return a.GVW - b.GVW;
    //});
    //var sortedOnGVWThenPayloed = sortedOnGVW.sort(function (a, b) {
    //    if (a.GVW === b.GVW) {
    //        return a.Payload - b.Payload
    //    } else {
    //        return 0;
    //    }
    //});
    //return sortedOnGVWThenPayloed;
    doSort(data.Offers);

    function doSort(array) {

        var swapped = false;

        for (var i = 0; i < array.length; i++) {
            if (i <= array.length - 2) {
                var a = array[i];
                var b = array[i + 1];
                if ((a.GVW - b.GVW) > 0) {
                    array[i] = b;
                    array[i + 1] = a;
                    swapped = true;
                } else if (a.GVW === b.GVW) {
                    if ((a.Payload - b.Payload) > 0) {
                        array[i] = b;
                        array[i + 1] = a;
                        swapped = true;
                    }

                }
            }
        }
        if (swapped === true) {
            doSort(array);
        }
    }
    
}
//#endregion

function downloadVehicleCsv(vehicleId) {
    var dfd = $.Deferred();
        
    var request = 'downloads/get?operationType=CSV-VEHICLES&objectIdentifier=' + vehicleId;
    handleDataRequest(null, request, OPERATION.DownloadVehicleCsv, true)
        .then(function (result) {
            dfd.resolve({
                data: result,
                ResultCode: 1
            });
        })
        .fail(function (errorMessage) {
            dfd.reject(errorMessage);
        });
    

    return dfd.promise();
}

function getSignalRConnectionInfo() {

    var dfd = $.Deferred();

    var options = {
        // url: 'https://truckscience-test-v2.azurewebsites.net/api/negotiate', //url: process.env.VUE_APP_AzureFunctionsServer + '/api/negotiate',
        url: process.env.VUE_APP_AzureFunctionsServer + '/api/negotiate',
        beforeSend: setQueryHeader,
        type: operationTypeGet
    }

    // make ajax call
    $.ajax(options)
        .then(requestSucceeded)
        .fail(requestFailed);

    // handle the ajax callback
    function requestSucceeded(data, textStatus, jqXHR) {

        
        dfd.resolve({ data: data });
    }

    function requestFailed(xhr, ajaxOptions, thrownError) {
        dfd.reject({ errorMessage: xhr.statusText });
    }

    return dfd.promise();

    //return axios.get(config.azureFunctionsServer + '/api/negotiate', { headers: { 'requested-user': globals.user.getUserName() } })
    //    .then(function (response) {
    //        return response.data;
    //    }).catch(console.error);

    function setQueryHeader(xhr) {
        xhr.setRequestHeader('requested-user', globals.user.getUserName());
    }
}
